<template>
    <div class="w-full flex justify-center" :class="margin">
        <p class="mr-5 text-teal-base" v-if="showLabel">{{label}}</p> 
        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="#00848E" stroke-width="4"></circle>
            <path class="opacity-75" fill="#00848E" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
    </div>

</template>

<script>
    export default {
        name: "Loading", 
        props:{
            margin:{
                type:String,
                default: 'my-12'
            },
            showLabel:{
                type: Boolean,
                default:false,
            },
            label:{
                type: String,
                default:"Loading"
            }

        }
    }
</script>

<style scoped>

</style>