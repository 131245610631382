<template>
  <div class="lightbox" v-if="showLightBox" @keydown.esc="closeLightBox">
    <nav class="nav">
      <div class="nav-wrapper">
        <div class="flex btn-wrapper">
          <button @click="closeLightBox" class="close-btn">
            <i class="mr-2 fal fa-times"></i>Close
          </button>
          <ButtonIconText
            @click.native="showPhotos"
            v-if="showPhotosButton"
            text="Show all photos"
          />
        </div>
      </div>
    </nav>

    <div v-if="showAllPhotos" class="img-list-container">
      <div class="img-list">
        <figure
          @click="focusPhoto(), setPhotoData(photo.img, photo.alt, index)"
          v-for="(photo, index) in photos"
          :key="index"
          class="cursor-pointer lightbox-img-wrapper"
        >
          <img class="lightbox-img" :src="photo.img" :alt="photo.alt" />
        </figure>
      </div>
    </div>

    <div v-if="showSinglePhoto" class="single-photo">
      <figure>
        <img
          :src="photoPath"
          :alt="photoAlt"
          class="object-contain w-full h-full"
        />
      </figure>
      <div class="relative w-full btn-container bg-black-darker h-44">
        <div class="wrapper">
          <button
            @click="goToPreviousPhoto"
            class="w-12 h-12 bg-white rounded-full text-black-base"
          >
            <i class="far fa-chevron-left"></i>
          </button>

          <div class="font-sans text-lg font-semibold text-white counter">
            {{ currentPhotoNumber }}/{{ numberOfPhotos }}
          </div>
          <button
            @click="goToNextPhoto"
            class="w-12 h-12 transform bg-white rounded-full text-black-base"
          >
            <i class="far fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonIconText from "@/components/Buttons/ButtonIconText";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "GalleryLightbox",
  components: {
    ButtonIconText,
  },
  data() {
    return {
      showPhotosButton: false,
      showAllPhotos: true,
      showSinglePhoto: false,
      photoPath: "",
      photoAlt: "",
      currentPhotoNumber: undefined,
      currentIndex: undefined,
    };
  },

  methods: {
    ...mapActions({
      setShowLightBox: "lightBox/setLightBoxStatus",
      setLightBoxPhotos: "lightBox/setLightBoxPhotos",
    }),

    closeLightBox() {
      this.$emit("closeLightbox");
      this.setShowLightBox(false);
      this.setLightBoxPhotos([]);
      this.resetAttributes();
    },

    goToNextPhoto() {
      for (let index = 0; index < this.photos.length; index++) {
        if (this.currentIndex == index) {
          if (this.currentIndex != this.photos.length - 1) {
            this.currentIndex = index + 1;
            this.photoPath = this.photos[this.currentIndex].img;
            this.photoAlt = this.photos[this.currentIndex].alt;
            this.currentPhotoNumber++;
            index = this.photos.length;
          } else {
            this.currentIndex = 0;
            this.photoPath = this.photos[this.currentIndex].img;
            this.photoAlt = this.photos[this.currentIndex].alt;
            this.currentPhotoNumber = 1;
            index = this.photos.length;
          }
        }
      }
    },
    goToPreviousPhoto() {
      for (let index = 0; index < this.photos.length; index++) {
        if (this.currentIndex == index) {
          if (this.currentIndex > 0) {
            this.currentIndex = index - 1;
            this.photoPath = this.photos[this.currentIndex].img;
            this.photoAlt = this.photos[this.currentIndex].alt;
            this.currentPhotoNumber--;
            index = this.photos.length;
          } else {
            this.currentIndex = this.photos.length - 1;
            this.photoPath = this.photos[this.currentIndex].img;
            this.photoAlt = this.photos[this.currentIndex].alt;
            this.currentPhotoNumber = this.photos.length;
            index = this.photos.length;
          }
        }
      }
    },
    resetAttributes() {
      this.showPhotosButton = false;
      this.showAllPhotos = true;
      this.showSinglePhoto = false;
    },
    togglePhotosButton() {
      this.showPhotosButton = !this.showPhotosButton;
    },
    toggleAllPhotos() {
      this.showAllPhotos = !this.showAllPhotos;
    },
    toggleSinglePhoto() {
      this.showSinglePhoto = !this.showSinglePhoto;
    },
    showPhotos() {
      this.togglePhotosButton();
      this.toggleAllPhotos();
      this.toggleSinglePhoto();
    },
    focusPhoto() {
      this.togglePhotosButton();
      this.toggleAllPhotos();
      this.toggleSinglePhoto();
    },
    setPhotoData(imgPath, alt, index) {
      this.photoPath = imgPath;
      this.photoAlt = alt;
      this.currentPhotoNumber = index + 1;
      this.currentIndex = index;
    },
    useKeys(event) {
      let keyCode = event.keyCode;
      if (keyCode == "39") this.goToNextPhoto();
      if (keyCode == "37") this.goToPreviousPhoto();
      if (keyCode == "27") {
        if (this.showSinglePhoto == true) {
          this.toggleSinglePhoto();
          this.toggleAllPhotos();
        } else {
          this.$emit("closeGallery");
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      showLightBox: "lightBox/showLightBox",
      photos: "lightBox/photos",
    }),
    numberOfPhotos() {
      return this.photos.length;
    },
  },
  created() {
    document.onkeydown = this.useKeys;
  },
};
</script>

<style scoped>
.lightbox {
  @apply fixed top-0 left-0 z-50 w-screen h-screen bg-black-darker;
}

.nav {
  @apply fixed top-0 left-0 z-50 w-full px-4 pt-6 md:px-12 bg-black-darker;
}

.nav-wrapper {
  @apply relative flex items-center justify-between w-full max-w-screen-xl transform -translate-x-1/2 left-1/2;
}

.close-btn {
  @apply flex items-center px-3 py-2 mr-4 text-sm text-white duration-300 ease-in-out rounded hover:bg-black-base;
}

.lightbox-img-wrapper {
  position: relative;
  overflow: hidden;
  padding-bottom: calc(1080 / 1920 * 100%);
}

.lightbox-img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.wrapper {
  @apply absolute flex items-center justify-between transform -translate-x-1/2 -translate-y-1/2 w-52 top-1/2 left-1/2;
}

.img-list-container {
  @apply w-full h-full px-4 pb-32 mt-20 overflow-hidden overflow-y-scroll md:px-12;
}

.img-list {
  @apply relative max-w-screen-xl transform -translate-x-1/2 left-1/2;
  @apply grid  gap-4  sm:grid-cols-2 md:grid-cols-3;
}

.single-photo {
  @apply relative w-full h-full max-w-screen-xl transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2;
  max-height: calc(100vh - 3.75rem);
}

.single-photo figure {
  @apply relative w-full h-full pt-12 transform -translate-x-1/2 left-1/2;
  height: calc(100% - 8rem);
}
</style>
