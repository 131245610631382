<template>
  <div id="search-results" class="search-results">
    <ResultItem
      v-for="(item, index) in resultItems"
      :key="index"
      :itemName="item.name"
      :itemDetails="item.address"
      :showItemDetails="item.showDetails"
      :showItemIcon="item.showIcon"
      :faIconClass="item.faIconClass"
      :showHotelStars="false"
      :numberOfStars="item.numberOfStars"
      @click.native="emitMethod(item)"
      v-show="resultItems.length > 0"
    />
    <EmptyStateFramed
      v-if="resultItems.length < 1"
      :usePrimaryAction="true"
      :showIcon="false"
      :title="emptyStateTitle"
      :text="emptyStateText"
      :primaryAction="primaryAction"
      class="border-none"
      @primaryAction="$emit('primaryAction')"
    />
  </div>
</template>
<script>
import EmptyStateFramed from "@/components/EmptyStates/EmptyStateFramed";
import ResultItem from "@/components/Inputs/SelectSearch/ResultItem";

export default {
  name: "ResultsDropdown",
  components: {
    EmptyStateFramed,
    ResultItem,
  },
  props: {
    emptyStateTitle: {
      type: String,
      default: "Title",
    },
    emptyStateText: {
      type: String,
      default: "Text",
    },
    primaryAction: {
      type: String,
      default: "Button",
    },
    resultItems: {
      type: Array,
      default: () => [
        {
          name: "Blue Waters Hotel",
          details: "Golden Mile, Durban, South Africa",
          showDetails: true,
          showIcon: true,
          faIconClass: "fas fa-hotel",
          showHotelStars: true,
          numberOfStars: "4",
        },
        {
          name: "The Cabanas Hotel at Sun City Resort",
          details: "Sun City, Durban, South Africa",
          showDetails: true,
          showIcon: true,
          faIconClass: "fas fa-hotel",
          showHotelStars: true,
          numberOfStars: "3",
        },
        {
          name: "The Capital Pearls Hotel",
          details: "Umhlanga, Durban, South Africa",
          showDetails: true,
          showIcon: true,
          faIconClass: "fas fa-hotel",
          showHotelStars: true,
          numberOfStars: "4",
        },
        {
          name: "Radison Blu Hotel Waterfront",
          details: "Mouille Point, Cape Town, South Africa",
          showDetails: true,
          showIcon: true,
          faIconClass: "fas fa-hotel",
          showHotelStars: true,
          numberOfStars: "5",
        },
        {
          name: "Blue Waters Hotel",
          details: "Golden Mile, Durban, South Africa",
          showDetails: true,
          showIcon: true,
          faIconClass: "fas fa-hotel",
          showHotelStars: true,
          numberOfStars: "4",
        },
        {
          name: "The Cabanas Hotel at Sun City Resort",
          details: "Sun City, Durban, South Africa",
          showDetails: true,
          showIcon: true,
          faIconClass: "fas fa-hotel",
          showHotelStars: true,
          numberOfStars: "3",
        },
        {
          name: "The Capital Pearls Hotel",
          details: "Umhlanga, Durban, South Africa",
          showDetails: true,
          showIcon: true,
          faIconClass: "fas fa-hotel",
          showHotelStars: true,
          numberOfStars: "4",
        },
        {
          name: "Radison Blu Hotel Waterfront",
          details: "Mouille Point, Cape Town, South Africa",
          showDetails: true,
          showIcon: true,
          faIconClass: "fas fa-hotel",
          showHotelStars: true,
          numberOfStars: "5",
        },
      ],
    },
  },
  methods: {
    emitMethod(item) {
      this.$emit("clickedItem", item);
    },
  },
};
</script>

<style scoped>
.top-100 {
  top: 100%;
}
.max-h-select {
  max-height: 295px;
}

.search-results {
  @apply shadow-md border border-grey-base absolute bg-white top-100 z-40 w-full left-0 rounded max-h-select overflow-y-auto;
}
</style>