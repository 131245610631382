<template>
  <section id="pricing" class="pb-52 relative">
    <h1 class="form-page-title">Pricing</h1>
    <div class="form-page-container">
      <div class="form-page-section">
        <SelectDropdown
            :optionsList="currencies"
            description="Choose a currency"
            :showLabel="true"
            label="Currency"
            :selected="tripDetails.tripCurrency"
            v-model="tripDetails.tripCurrency"
        />
        <div class="input-wrapper">
          <InputText
              type="number"
              label="Price per adult"
              placeholder="e.g. 1800"
              class="w-full sm:w-calc-1/2"
              name="price-per-adult"
              v-model="tripDetails.tripPricePerAdult"
          />
          <InputText
              type="number"
              label="Number of adults"
              placeholder="e.g. 2"
              class="w-full mt-6 sm:mt-0 sm:w-calc-1/2"
              name="number-of-adults"
              v-model="tripDetails.tripAdults"
          />
        </div>
        <div class="input-wrapper">
          <InputText
              type="number"
              label="Price per child"
              placeholder="e.g. 1800"
              class="w-full sm:w-calc-1/2"
              name="price-per-child"
              v-model="tripDetails.tripPricePerChild"
          />
          <InputText
              type="number"
              label="Number of children"
              placeholder="e.g. 2"
              class="w-full mt-6 sm:mt-0 sm:w-calc-1/2"
              name="number-of-children"
              v-model="tripDetails.tripChildren"
          />
        </div>
        <div class="input-wrapper">
          <InputText
              class="w-full sm:w-calc-1/2"
              type="number"
              label="Single room price"
              placeholder="e.g. 1800"
              name="price-singleroom"
              v-model="tripDetails.tripSingleRoomPrice"
          />
          <InputText
              class="w-full mt-6 sm:mt-0 sm:w-calc-1/2 truncate"
              type="number"
              label="Number of single rooms"
              placeholder="e.g. 3"
              name="number-of-singlerooms"
              v-model="tripDetails.tripSingleRooms"
          />
        </div>
      </div>
      <div class="form-page-section pricing-table">
        <TotalPriceSummary :tripDetails="tripDetails"/>
      </div>
    </div>
    <div class="w-full border-b border-grey-base my-12"></div>
    <div id="inclusions">
      <h4 class="sub-title">Inclusions</h4>
      <ButtonInputText
          v-for="(inclusion, i) in inclusions"
          :key="i"
          :index="i"
          @removeItem="removeInclusion(i)"
          :text="inclusion.text"
          :showField="inclusion.show"
          placeholder="e.g. Transfer to the airport"
          :name="'inclusion-' + i"
          @input="addItemToInclusion"
          class="mb-3"
      />
      <ButtonPrimary
          @click.native="addInclusion"
          text="Add a new field"
          class="px-3 ml-auto block mt-8"
      />
    </div>
    <div class="w-full border-b border-grey-base my-12"></div>
    <div id="exclusions">
      <h4 class="sub-title">Exclusions</h4>
      <ButtonInputText
          v-for="(exclusion, i) in exclusions"
          :key="i"
          :index="i"
          @removeItem="removeExclusion(i)"
          :showField="exclusion.show"
          :text="exclusion.text"
          placeholder="e.g. Transfer to the airport"
          :name="'exclusion-' + i"
          class="mb-3"
          @input="addItemToExclusion"
      />
      <ButtonPrimary
          @click.native="addExclusion"
          text="Add a new field"
          class="px-3 ml-auto block mt-8"
      />
    </div>
  </section>
</template>
<script>
import ButtonInputText from "@/components/Buttons/ButtonInputText";
import InputText from "@/components/Inputs/InputText";
import ButtonPrimary from "@/components/Buttons/ButtonPrimary";
import SelectDropdown from "@/components/Inputs/SelectDropdown";
import {mapActions, mapGetters} from "vuex";
import TotalPriceSummary from "@/components/Offer/TotalPriceSummary";

export default {
  name: "Pricing",
  components: {
    TotalPriceSummary,
    InputText,
    ButtonInputText,
    ButtonPrimary,
    SelectDropdown,
  },
  props: {
    pricingDetails: {
      type: Object,
    },
  },
  data() {
    return {
      inclusions: [],
      exclusions: [],
      exclusionsWithIDs: [],
      inclusionsWithIDs: [],
      currency: "",
      region: "en-EN",
      numberOfAdults: 0,
      numberOfChildren: 0,
      numberOfSingleRooms: 0,
      adultsPrice: 0,
      childrenPrice: 0,
      singleRoomPrice: 0,
      currencies: [
        {
          value: "CHF",
          name: "CHF",
        },
        {
          value: "EUR",
          name: "EUR",
        },
        {
          value: "GBP",
          name: "GBP",
        },
        {
          value: "USD",
          name: "USD",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      addAccommodation: "offer/addAccomodation",
    }),
    addExclusion() {
      this.exclusions.push({text: "", show: true});
    },
    addInclusion() {
      this.inclusions.push({text: "", show: true});
    },
    addItemToInclusion(value, index) {
      let getIndex = this.inclusionsWithIDs.findIndex((x) => x.id === index);
      if (getIndex > -1) {
        this.inclusionsWithIDs.splice(getIndex, 1);
      }
      this.inclusionsWithIDs.splice(index, 0, {id: index, inclusion: value});
      let array = [];
      for (let i = 0; i < this.inclusionsWithIDs.length; i++) {
        array.push(this.inclusionsWithIDs[i].inclusion);
      }
      this.tripDetails.tripInclusions = array.toString();
    },
    addItemToExclusion(value, index) {
      let getIndex = this.exclusionsWithIDs.findIndex((x) => x.id === index);
      if (getIndex > -1) {
        this.exclusionsWithIDs.splice(getIndex, 1);
      }
      this.exclusionsWithIDs.splice(index, 0, {id: index, exclusion: value});
      let array = [];
      for (let i = 0; i < this.exclusionsWithIDs.length; i++) {
        array.push(this.exclusionsWithIDs[i].exclusion);
      }
      this.tripDetails.tripExclusions = array.toString();
    },
    removeInclusion(index) {
      let getIndex = this.inclusionsWithIDs.findIndex((x) => x.id === index);
      this.inclusionsWithIDs.splice(getIndex, 1);
      this.inclusions[index].show = false;
      let array = [];
      for (let i = 0; i < this.inclusionsWithIDs.length; i++) {
        array.push(this.inclusionsWithIDs[i].inclusion);
      }
      this.tripDetails.tripInclusions = array.toString();
      if (this.inclusionsWithIDs.length < 1) {
        this.inclusions.push({text: "", show: true});
      }
    },
    removeExclusion(index) {
      let getIndex = this.exclusionsWithIDs.findIndex((x) => x.id === index);
      this.exclusionsWithIDs.splice(getIndex, 1);
      this.exclusions[index].show = false;
      let array = [];
      for (let i = 0; i < this.exclusionsWithIDs.length; i++) {
        array.push(this.exclusionsWithIDs[i].exclusion);
      }
      this.tripDetails.tripExclusions = array.toString();
      if (this.exclusionsWithIDs.length < 1) {
        this.exclusions.push({text: "", show: true});
      }
    },
    getCurrency(currency) {
      this.tripDetails.tripCurrency = currency;
    },
    setAdultsPrice(value) {
      this.adultsPrice = value;
    },
    setChildrenPrice(value) {
      this.childrenPrice = value;
    },
    setNumberOfChildren(value) {
      this.numberOfChildren = value;
    },
    setNumberOfAdults(value) {
      this.numberOfAdults = value;
    },
    setSingleRoomNumber(value) {
      this.numberOfSingleRooms = value;
    },
    setPrice(value) {
      this.tripDetails.tripBudget = value;
    },
    setSingleRoomPrice(value) {
      this.tripDetails.tripSingleRoomPrice = value;
    },
    formatNumber(value, currency, region) {
      value = new Intl.NumberFormat(region, {
        style: "currency",
        currency: currency || "USD",
      }).format(value);
      return value;
    },
  },
  computed: {
    ...mapGetters({
      tripDetails: "offer/tripDetails",
    }),
    pricePerChild() {
      let total = this.tripDetails.tripPricePerChild;
      return this.formatNumber(
          total,
          this.tripDetails.tripCurrency,
          this.region
      );
    },
    childrenPriceCalc() {
      let isPlural =
          this.tripDetails.tripChildren > 1 ||
          this.tripDetails.tripChildren === 0;
      let price = this.pricePerChild || 0;
      let numOfAChildren = this.tripDetails.tripChildren || 0;
      return `${price} x ${numOfAChildren} ${isPlural ? "children" : "child"}`;
    },
    singleRoomsCalc() {
      let isPlural =
          this.tripDetails.tripSingleRooms > 1 ||
          this.tripDetails.tripSingleRooms === 0;
      let price = this.singleRoomPriceFormatted || 0;
      let numOfRooms = this.tripDetails.tripSingleRooms || 0;
      return `${price} x ${numOfRooms} single ${isPlural ? "rooms" : "room"}`;
    },
    childrenTotalPrice() {
      let total =
          this.tripDetails.tripPricePerChild * this.tripDetails.tripChildren;
      return this.formatNumber(
          total,
          this.tripDetails.tripCurrency,
          this.region
      );
    },
    numberOfTravelers() {
      return this.tripDetails.tripChildren + this.tripDetails.tripAdults;
    },
    priceFormatted() {
      let total = this.tripDetails.tripBudget;
      return this.formatNumber(
          total,
          this.tripDetails.tripCurrency,
          this.region
      );
    },
    singleRoomPriceFormatted() {
      let total = this.tripDetails.tripSingleRoomPrice;
      return this.formatNumber(
          total,
          this.tripDetails.tripCurrency,
          this.region
      );
    },
    pricePerAdult() {
      let total = this.tripDetails.tripPricePerAdult;
      return this.formatNumber(
          total,
          this.tripDetails.tripCurrency,
          this.region
      );
    },
    adultsPriceCalc() {
      let isPlural =
          this.tripDetails.tripAdults > 1 || this.tripDetails.tripAdults === 0;
      let price = this.pricePerAdult || 0;
      let numOfAdults = this.tripDetails.tripAdults || 0;
      return `${price} x ${numOfAdults} ${isPlural ? "adults" : "adult"}`;
    },
    adultsTotalPrice() {
      let total =
          this.tripDetails.tripPricePerAdult * this.tripDetails.tripAdults;
      return this.formatNumber(
          total,
          this.tripDetails.tripCurrency,
          this.region
      );
    },
    travelersTotal() {
      let total = this.tripDetails.tripBudget * this.numberOfTravelers;
      return this.formatNumber(
          total,
          this.tripDetails.tripCurrency,
          this.region
      );
    },
    possibleSingleRooms() {
      let number = this.numberOfTravelers + 1;
      let possibleRooms = [];

      for (let i = 0; i < number; i++) {
        possibleRooms.push({value: i, name: i});
      }
      return possibleRooms;
    },
    singleRoomsTotal() {
      let total =
          this.tripDetails.tripSingleRoomPrice *
          this.tripDetails.tripSingleRooms;
      return this.formatNumber(
          total,
          this.tripDetails.tripCurrency,
          this.region
      );
    },
    totalPrice() {
      let adultsTotalPrice =
          this.tripDetails.tripPricePerAdult * this.tripDetails.tripAdults;
      let childrenTotalPrice =
          this.tripDetails.tripPricePerChild * this.tripDetails.tripChildren;
      let totalTravelersPrice = childrenTotalPrice + adultsTotalPrice;
      let roomPrice =
          this.tripDetails.tripSingleRoomPrice *
          this.tripDetails.tripSingleRooms;
      let total = totalTravelersPrice + roomPrice;
      return this.formatNumber(
          total,
          this.tripDetails.tripCurrency,
          this.region
      );
    },
  },
  watch: {
    totalPrice() {
      let adultsTotalPrice =
          this.tripDetails.tripPricePerAdult * this.tripDetails.tripAdults;
      let childrenTotalPrice =
          this.tripDetails.tripPricePerChild * this.tripDetails.tripChildren;
      let totalTravelersPrice = childrenTotalPrice + adultsTotalPrice;
      let roomPrice =
          this.tripDetails.tripSingleRoomPrice *
          this.tripDetails.tripSingleRooms;

      this.tripDetails.tripTotalPrice = totalTravelersPrice + roomPrice;
    },
  },

  mounted() {
    let exclusionsArray,
        inclusionsArray = [];
    if (this.tripDetails.tripInclusions !== "") {
      this.exclusions = [];
      exclusionsArray = this.tripDetails.tripExclusions.split(",");

      exclusionsArray.forEach((element, index) => {
        this.exclusions.push({text: element, show: true});
        this.exclusionsWithIDs.push({id: index, exclusion: element});
      });
    } else {
      this.exclusions = [{text: "", show: true}];
    }

    if (this.tripDetails.tripInclusions !== "") {
      inclusionsArray = this.tripDetails.tripInclusions.split(",");
      inclusionsArray.forEach((element, index) => {
        this.inclusions.push({text: element, show: true});
        this.inclusionsWithIDs.push({id: index, inclusion: element});
      });
    } else {
      this.inclusions = [{text: "", show: true}];
    }
  },
};
</script>
<style scoped>
.form-page-title {
  @apply font-sans font-bold text-black-base text-xl mt-12 sm:text-3xl mb-4 sm:mb-8;
}

.form-page-container {
  @apply flex flex-wrap sm:flex-nowrap justify-between items-start w-full relative;
}

.form-page-section {
  @apply w-full sm:w-calc-1/2;
}

.pricing-table {
  @apply bg-white border border-grey-base rounded-md shadow-sm p-6 mt-12 sm:mt-0;
}


.input-wrapper {
  @apply flex justify-between mt-6 flex-wrap sm:flex-nowrap w-full;
}

.sub-title {
  @apply font-sans font-bold text-black-base text-base sm:text-xl mb-3 sm:mb-6;
}
</style>