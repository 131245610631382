export default {
    namespaced: true,
    state: {
        dialog: {
            show: false,
            title: "Delete Photo",
            text: "Are you sure you want to delete this photo?",
            iconClass: "bx bxs-error-circle bx-sm",
            iconColor: "text-red-base"
        },
        showOverlay: false,
    },
    getters: {
        dialog(state) {
            return state.dialog;
        },
        showOverlay(state) {
            return state.showOverlay;
        }
    },
    mutations: {
        SET_DIALOG_SHOW(state, value) {
            state.dialog.show = value;
        },
        SET_DIALOG_TITLE(state, value) {
            state.dialog.title = value;
        },
        SET_DIALOG_TEXT(state, value) {
            state.dialog.text = value;
        },
        SET_DIALOG_ICON(state, value) {
            state.dialog.iconClass = value;
        },
        SET_DIALOG_ICON_COLOR(state, value) {
            state.dialog.iconColor = value;
        },
        SET_OVERLAY(state, value) {
            state.showOverlay = value;
        }
    },
    actions: {
        displayOverlay({commit}) {
            commit("SET_OVERLAY", true);
        },
        setDialogTitle({commit}, value) {
            commit("SET_DIALOG_TITLE", value);
        },
        setDialogText({commit}, value) {
            commit("SET_DIALOG_TEXT", value);
        },
        setDialogIcon({commit}, value) {
            commit("SET_DIALOG_ICON", value);
        },
        setDialogIconColor({commit}, value) {
            commit("SET_DIALOG_ICON_COLOR", value);
        },
        hideOverlay({commit}) {
            commit("SET_OVERLAY", false);
        },
        displayDialog({commit}) {
            commit("SET_DIALOG_SHOW", true);
        },
        hideDialog({commit}) {
            commit("SET_DIALOG_SHOW", false);
        }
    }
}