<template>
  <section id="accommodation">
    <h1 class="form-page-title">Accommodations</h1>


    <div>
    <AccommodationBlock
        v-for="(night, index) in nights"
        :blockId="index"
        :key="index"
        :night="night"
        :blockOpacity="night.blockOpacity"
        :showBlock="night.showBlock"
        :hotels="accommodations"
        :startDate="night.startDate"
        :currentDate="night.startDate"
        :checkInDate="night.checkInDate"
        :currentNight="index + 1"
        :amountOfNights="nights.length"
        :class="night.class"
        :numberOfNights="night.nightsAvailable"
        :showSummary="night.showSummary"
        :selectedNumberOfNights="night.overnightStays"
        :showInputFields="night.showInputFields"
        :showPlaceholder="night.showPlaceholder"
        :disablePlaceholderBtn="night.disablePlaceholderBtn"
        @delete="deleteBlock(index)"
        @edit="editBlock(index)"
        @showHotelSheet="showHotelSheet"
        @getSelectValue="getNightValue"
        @openBlock="openBlock(index)"
        @saveBlock="saveBlock"
        @resetNights="resetNights(index)"
        @cancelAction="cancelBlock(index)"
    />
      <AccommodationConfigurator
          :alreadyHasAccommodations="alreadyHasAccommodations"
          :destination="tripDetails.tripDestination"
          :dates="tripDetails.tripDates"
          :selectedAccommodation="tripDetails.tripAccommodationDetails"
          :newAccommodation="newAccommodation"
          @resetAccommodation="resetAccommodationDetails"
          @removeAccommodation="removeAccommodation"
          @addAccommodation="addAccommodation"
      />
    </div>
  </section>
</template>
<script>
import AccommodationConfigurator from "@/components/Offer/Accommodations/AccommodationConfigurator";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Accommodations",
  components: {
    AccommodationConfigurator,
  },
  data() {
    return {
      startDate: "06/11/2021",
      endDate: "06/19/2021",
      hotels: [],
      showSummary: false,
      showInput: false,
      currentSelectValue: undefined,
      currentBlockIndex: 0,
      nights: [],
    };
  },
  props: {
    alreadyHasAccommodations: {
      type: Boolean,
      default: false,
    },
    newAccommodation: {
      type: String,
      default: "",
    },
  },
  methods: {
    ...mapActions({
      addAccommodation: "offer/addAccommodation",
      resetAccommodationDetails: "offer/resetAccommodationDetails",
      removeAccommodation: "offer/removeAccommodation",
      getAccommodations: "accommodations/getAccommodations"
    }),
  },
  computed: {
    ...mapGetters({
      tripDetails: "offer/tripDetails",
      accommodations:"accommodations/accommodations"
    }),
  },
};
</script>
<style scoped>
#accommodation {
  @apply pb-44;
}

.form-page-title {
  @apply font-sans font-bold text-black-base text-xl mt-12 sm:text-3xl mb-4 sm:mb-8;
}
</style>