<template>
  <router-link
    :to="{ name: linkName }"
    replace
    class="w-full"
    :class="['item', statusStyle, widthStyle]"
  >
    <span class="item-number">{{ index + 1 }}</span>
    <span class="item-text">{{ text }}</span>
  </router-link>
</template>
<script>
export default {
  name: "MultiStepItem",
  props: {
    text: {
      type: String,
      default: "Text",
    },
    linkName: {
      type: String,
    },
    index: {
      type: Number,
      default: 1,
    },
    isActive: {
      type: Boolean,
      default: true,
    },
    statusStyle: {
      type: String,
      default: "border border-gray-dark text-black-lightest hover:bg-grey-base",
    },
    widthStyle: {
      type: String,
      default: "col-span-1",
    },
  },
};
</script>
<style scoped>
.item {
  @apply text-xs text-center;
  @apply duration-300 transition ease-in-out;
  @apply p-2 focus:outline-none rounded-md;
}

.item-number {
  @apply block;
}

.item-text {
  @apply truncate hidden sm:block w-full;
}
</style>