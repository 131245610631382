<template>
  <section id="customer-details">
    <h1 class="form-page-title">Customer details</h1>
    <div class="radio-button-container flex mb-6">
      <label class="mr-6">
        <input type="radio" value="Mr." v-model="tripDetails.tripSalutation" />
        <span class="label ml-2">Mr</span>
      </label>

      <label class="mr-6">
        <input
          type="radio"
          value="Mrs."
          v-model="tripDetails.tripSalutation"
        />
        <span class="label ml-2">Mrs</span>
      </label>

      <label>
        <input
          type="radio"
          value="Miss"
          v-model="tripDetails.tripSalutation"
        />
        <span class="label ml-2">Miss</span>
      </label>
    </div>
    <div class="input-field-container">
      <InputText
        label="First name"
        placeholder="e.g. Johanna"
        class="input-name"
        name="first-name"
        v-model="tripDetails.tripFirstname"
      />
      <InputText
        label="Last name"
        placeholder="e.g. Johanna"
        class="input-name"
        name="first-name"
        v-model="tripDetails.tripLastname"
      />
    </div>
    <InputNumberSpinner
      name="adults"
      label="Adults"
      class="mb-6"
      v-model="tripDetails.tripAdults"
    />
    <InputNumberSpinner
      name="children"
      label="Children"
      class="mb-6"
      v-model="tripDetails.tripChildren"
    />
  </section>
</template>
<script>
import InputNumberSpinner from "@/components/Inputs/InputNumberSpinner";
import InputText from "@/components/Inputs/InputText";
import { mapGetters } from "vuex";

export default {
  name: "CustomerDetails",
  components: {
    InputNumberSpinner,
    InputText,
  },
  data() {
    return {
      formCustomerDetails: {},
    };
  },
  computed: {
    ...mapGetters({
      tripDetails: "offer/tripDetails",
    }),
  },
};
</script>
<style scoped>
#customer-details {
  @apply pb-44;
  width: 100%;
}
.label {
  @apply text-sm sm:text-base font-medium;
}
.input-field-container {
  @apply flex flex-wrap justify-between;
}

.input-name {
  @apply mb-6 w-full sm:w-calc-1/2;
}

.form-page-title {
  @apply font-sans font-bold text-black-base text-xl mt-12 sm:text-3xl mb-4 sm:mb-8;
}
</style>