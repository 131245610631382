import api from "../../api";

export default {
    namespaced: true,
    state: {
        accommodations: [],
        showAccommodationSheet: false,
        isSubmittingData: false,
        submissionStatusMessage: '',
        submissionStatus: '',
        newAccommodation: {}
    },
    getters: {
        showAccommodationSheet(state) {
            return state.showAccommodationSheet
        },
        accommodations(state) {
            return state.accommodations
        },
        newAccommodation(state) {
            return state.newAccommodation
        },

        isSubmittingData(state) {
            return state.isSubmittingData;
        },
        submissionStatus(state) {
            return state.submissionStatus;
        },
        submissionStatusMessage(state) {
            return state.submissionStatusMessage;
        }

    },
    mutations: {
        SET_SHOW_ACCOMMODATION_SHEET(state, value) {
            state.showAccommodationSheet = value;
        },
        SET_ACCOMMODATIONS(state, value) {
            state.accommodations = value;
        },
        SET_NEW_ACCOMMODATION(state, value) {
            state.newAccommodation = value;
        },
        SET_IS_SUBMITTING_DATA(state, value) {
            state.isSubmittingData = value;
        },
        SET_SUBMISSION_STATUS(state, value) {
            state.submissionStatus = value;
        },
        SET_SUBMISSION_STATUS_MESSAGE(state, value) {
            state.submissionStatusMessage = value;
        }
    },
    actions: {
        setShowAccommodationSheet({commit}, showSheet) {
            commit('SET_SHOW_ACCOMMODATION_SHEET', showSheet)
        },
        getAccommodations({commit}, destination) {
            api.get(`/get-accommodations/${destination.toLowerCase()}`)
                .then(({data}) => {
                    commit('SET_ACCOMMODATIONS', data.accommodations)
                });
        },
        addNewAccommodation({commit}, formData) {
            commit('SET_IS_SUBMITTING_DATA', true);
            api.post("add-new-accommodation", formData, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
                .then(({data}) => {
                    commit('SET_IS_SUBMITTING_DATA', false);
                    commit('SET_NEW_ACCOMMODATION', data.accommodation);
                    commit('SET_SUBMISSION_STATUS', 'success');
                    commit('SET_SUBMISSION_STATUS_MESSAGE', 'The accommodation was added successfully');

                })
                .catch((e) => {
                    commit('SET_IS_SUBMITTING_DATA', false);
                    commit('SET_SUBMISSION_STATUS', 'failed');
                    commit('SET_SUBMISSION_STATUS_MESSAGE', e.response.data.message);

                });
        }
    }
}