<template>
  <button :class="['btn-style', resStyle]" type="bu, responsive]tton">
    <i :class="[iconClass, 'mr-2']"></i>
    <span class="btn-text">{{ text }}</span>
  </button>
</template>
<script>
export default {
  name: "ButtonIconTextFrameless",
  props: {
    iconClass: {
      type: String,
      default: "bx bx-show-alt",
    },
    text: {
      type: String,
      default: "Buttont text",
    },
    responsive: {
      type: String,
      default: "horizontal",
    },
  },
  computed: {
    resStyle() {
      if (this.responsive == "horizontal") return "responsive-horizontal";
      return "responsive-vertical";
    },
  },
};
</script>
<style scoped>
.btn-style {
  @apply focus:outline-none rounded-md hover:bg-grey-light;
  @apply transition duration-300 py-1 xs:py-2 px-1 xs:px-2.5;
  @apply flex items-center;
}
.responsive-horizontal {
  @apply justify-between;
}
.responsive-vertical {
  @apply flex-wrap justify-center sm:justify-between;
}
.btn-text {
  @apply text-xs xs:text-sm font-sans text-teal-text w-full mt-1 sm:mt-0 sm:w-auto;
}
</style>