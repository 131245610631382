<template>
  <button
    :type="type"
    class="p-3 text-sm font-medium focus:outline-none focus:scale-95 focus:ring-offset-2 focus:ring-2 focus:ring-teal-base focus:border-teal-base text-teal-base rounded font-sans hover:bg-teal-lighter duration-300 transition-all ease-out text-center uppercase"
  >
    {{ text }}
  </button>
</template>
<script>
export default {
  name: "ButtonFrameless",
  props: {
    text: {
      type: String,
      default: "Button text",
    },
    type: {
      type: String,
      default: "button",
    },
  },
};
</script>