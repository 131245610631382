import Vue from "vue";
import Vuex from "vuex";
import offer from './modules/offer.store';
import auth from './modules/auth.store';
import experiences from './modules/experiences.store';
import accommodations from './modules/accommodations.store';
import lightBox from './modules/lightbox.store';
import partners from './modules/partners.store';
import requests from './modules/requests.store';
import dialog from "./modules/dialog.store";
import tracker from "./modules/tracker";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);


//persist auth state 
const tokenState = createPersistedState({
    paths: ['auth', 'tracker']
})


export default new Vuex.Store({
    modules: {
        offer,
        auth,
        lightBox,
        experiences,
        accommodations,
        partners,
        requests,
        dialog,
        tracker,
    },
    plugins: [tokenState]
})





