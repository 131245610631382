<template>
  <div class="flex flex-wrap my-6">
    <label class="flex items-center w-full">
      <input
        type="checkbox"
        @change="onChange"
        class="w-5 h-5 border rounded form-checkbox border-black-lightest"
        :class="[showErrMsg ? 'border-red-base' : 'border-black-lightest']"
        :name="name"
        :id="id"
        v-model="checkedValue"
      />
      <span
        class="ml-3 text-sm text-black-base"
        :class="[showErrMsg ? 'text-red-base' : 'border-black-lightest']"
        >{{ label }}{{ labelLink }}</span
      >
    </label>
    <span
      v-show="false"
      class="mt-2 font-sans text-sm error-msg text-vermill-600"
      >{{ errorMsg }}</span
    >
  </div>
</template>
<script>
export default {
  name: "Checkbox",
  props: {
    label: {
      type: String,
      default: "Label",
    },
    labelLink: {
      type: String,
    },
    value: {
      type: [String, Boolean, Number],
    },
    errorMsg: {
      type: String,
      default: "Error message",
    },
    name: {
      type: String,
    },
    id: {
      type: String,
      default: "id",
    },
    showErrMsg: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checkedValue: this.value,
    };
  },
  methods: {
    onChange() {
      this.$emit("input", this.checkedValue);
    },
  },
  watch: {
    value(newVal) {
      this.checkedValue = newVal;
    },
  },
};
</script>
