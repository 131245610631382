import {defaultStartStopDates} from "@/shared/dateHandler";
import api from "@/api"

export default {
    namespaced: true,
    state: {
        loadingMessage: "Updating offer",
        savingResponseMessage: "Success",
        savingResponseStatus: "Success",
        isSubmittingOffer: false,
        shouldRedirectAfterOfferSave: false,
        showUnsavedChangesWarning: true,
        isLoadingData: false,
        showOfferSummary: false,
        offerDetails: {
            customerDetails: {},
            tripDetails: {
                tripGroupType: "",
                tripAdults: 0,
                tripChildren: 0,
                tripChildrenAge: "",
                tripBudget: 0,
                tripCurrency: "usd",
                tripDestination: "algeria",
                tripReason: "",
                tripDates: defaultStartStopDates(),
                tripAccompany: "",
                tripExperiences: "",
                tripDetails: "",
                tripAccommodation: [],
                tripSingleRooms: 0,
                tripMealPlan: "",
                tripSalutation: "",
                tripFirstname: "",
                tripLastname: "",
                tripEmail: "",
                tripPhone: "",
                tripConsultation: 0,
                userId: 0,
                tripRequestStatus: "",
                tripSingleRoomPrice: "",
                tripEarningFeePercentage: 0,
                tripInclusions: [],
                tripExclusions: [],
                tripAccommodationDetails: [],
                tripItineraryDetails: [],
                tripFullRefundDays: "",
                tripPartialRefundPercentage: "",
                tripPartialRefundDays: "",
                tripNoRefundDays: "",
                tripPricePerAdult: "",
                tripTotalPrice: 0,
                tripPricePerChild: "",
                tripEarningFees: 0,
            },
        },
        offerPricing: {
            currency: "USD",
            pricePerAdult: 0,
            numberOfAdults: 1,
            pricePerChild: 0,
            numberOfChildren: 0,
            roomPrice: 0,
            numberOfRooms: 0,
            earningFees: 0
        },
        requestedChanges: [],
        hasAlreadySavedOffer: false,

    },

    getters: {
        offerPricing(state) {
            return state.offerPricing;
        },
        offerDetails(state) {
            return state.offerDetails;
        },
        tripDetails(state) {
            return state.offerDetails.tripDetails;
        },
        requestedChanges(state) {
            return state.requestedChanges;
        },
        showOfferSummary(state) {
            return state.showOfferSummary;
        },
        savingResponseMessage(state) {
            return state.savingResponseMessage;
        },
        savingResponseStatus(state) {
            return state.savingResponseStatus;
        },
        isSubmittingOffer(state) {
            return state.isSubmittingOffer;
        },
        isLoadingData(state) {
            return state.isLoadingData;
        },
        loadingMessage(state) {
            return state.loadingMessage;
        },
        shouldRedirectAfterOfferSave(state) {
            return state.shouldRedirectAfterOfferSave
        },
        showUnsavedChangesWarning(state) {
            return state.showUnsavedChangesWarning
        }

    },

    mutations: {
        SET_LOADING_MESSAGE(state, value) {
            state.loadingMessage = value;
        },

        SET_SAVING_RESPONSE_MESSAGE(state, value) {
            state.savingResponseMessage = value;
        },

        SET_SAVING_RESPONSE_STATUS(state, value) {
            state.savingResponseStatus = value;
        },

        SET_IS_SUBMITTING_OFFER(state, value) {
            state.isSubmittingOffer = value;
        },

        SET_IS_LOADING_DATA(state, value) {
            state.isLoadingData = value;
        },

        SET_HAS_ALREADY_SAVED_OFFER(state, value) {
            state.hasAlreadySavedOffer = value;
        },

        SET_TRIP_DETAILS(state, value) {
            state.offerDetails.tripDetails = value;
        },

        SET_REQUESTED_CHANGES(state, value) {
            state.requestedChanges = value;
        },

        SET_SHOW_OFFER_SUMMARY(state, value) {
            state.showOfferSummary = value;
        },

        SET_SHOULD_REDIRECT_AFTER_OFFER_SAVE(state, value) {
            state.shouldRedirectAfterOfferSave = value;
        },
        SET_SHOW_UNSAVED_CHANGES_WARNING(state, value) {
            state.showUnsavedChangesWarning = value
        },
        REMOVE_AN_ITINERARY(state, index) {
            state.offerDetails.tripDetails.tripItineraryDetails.splice(index, 1);
        },

        REMOVE_AN_ACCOMMODATION(state, index) {
            state.offerDetails.tripDetails.tripAccommodationDetails.splice(
                index,
                1
            );
        },
        UNSET_ACCOMMODATION_DETAILS(state) {

            state.offerDetails.tripDetails.tripAccommodationDetails = [];
        },

        UNSET_ITINERARY_DETAILS(state) {
            state.offerDetails.tripDetails.tripItineraryDetails = [];
        },
    },

    actions: {

        addAccommodation({state}, accommodationDetail) {
            //push to the accommodations array
            let getIndex = null;
            //attempt getting the index of the item in the array
            let data = state.offerDetails.tripDetails.tripAccommodationDetails;
            getIndex = data.findIndex((x) => {
                return x.id === parseInt(accommodationDetail.id)
            });


            if (getIndex > -1) {
                //if the index is greater than -1 then the item exists
                state.offerDetails.tripDetails.tripAccommodationDetails.splice(getIndex, 1, accommodationDetail);

            } else {
                //else the item does not exist
                state.offerDetails.tripDetails.tripAccommodationDetails.splice(accommodationDetail.id, 0, accommodationDetail);
            }
        },

        addItinerary({state}, itineraryDetail) {
            //attempt getting the index of the item in the array
            let data = state.offerDetails.tripDetails.tripItineraryDetails;
            let getIndex = data.findIndex((x) => {
                return x.id == itineraryDetail.id
            });
            if (getIndex > -1) {
                //if the index is greater than -1 then the item exists
                state.offerDetails.tripDetails.tripItineraryDetails.splice(getIndex, 1, itineraryDetail);

            } else {
                //else the item does not exist
                state.offerDetails.tripDetails.tripItineraryDetails.splice(itineraryDetail.id, 0, itineraryDetail);
            }
        },

        removeItinerary({state, commit}, id) {
            let data = state.offerDetails.tripDetails.tripItineraryDetails;
            let getIndex = data.findIndex((x) => {
                return x.id === id
            });
            commit("REMOVE_AN_ITINERARY", getIndex);

        },

        removeAccommodation({state, commit}, id) {
            let data = state.offerDetails.tripDetails.tripAccommodationDetails;
            let getIndex = data.findIndex((x) => x.id === id);
            commit("REMOVE_AN_ACCOMMODATION", getIndex);
        },

        resetAccommodationDetails({commit}) {
            commit("UNSET_ACCOMMODATION_DETAILS");
        },

        resetItineraryDetails({commit}) {
            commit("UNSET_ITINERARY_DETAILS");
        },

        setTripDetails({commit}, tripDetails) {

            commit("SET_TRIP_DETAILS", tripDetails);
        },

        async getTripRequestDetails({dispatch, commit}, requestId) {

            commit('SET_IS_LOADING_DATA', true);

            api.getAll([
                `/single-trip-request/${requestId}`,
                `/single-offer/${requestId}`,
                `/offer-draft/${requestId}`,
                `/get-trip-requested-changes/${requestId}`,
            ]).then((response) => {
                let singleTripResponseData = response[0].data.tripRequest;
                let singleOfferData = null;
                if (singleTripResponseData.tripRequestStatus === 'draft') {
                    singleOfferData = response[2].data.offerDraft;
                } else {
                    singleOfferData = response[1].data.offer;
                }

                //since single offer is used interchangeably when creating an offer and offer draft we need to save the original offer id
                if (response[1].data.offer) {
                    singleOfferData.original_offer_id = response[1].data.offer.id;
                }

                if (!['open', 'draft'].includes(singleTripResponseData.tripRequestStatus)) {
                    commit('SET_SHOW_OFFER_SUMMARY', true);
                } else {
                    commit('SET_SHOW_OFFER_SUMMARY', false)
                }

                if (response[1].data.offer) {
                    commit('SET_HAS_ALREADY_SAVED_OFFER', true)
                }


                let requestedChangesResponseData = [];

                if (response[3].data.requestedChanges) {
                    requestedChangesResponseData = response[3].data.requestedChanges
                }


                if (singleTripResponseData.tripDestination === "Democratic Republic of Congo") {
                    singleTripResponseData.tripDestination = "Democratic Republic of the Congo"
                }

                let destination = singleTripResponseData.tripDestination;
                let partnerId = singleTripResponseData.partnerId;

                //setting additional fields
                singleTripResponseData.tripAgencies = singleTripResponseData.partnerId;
                singleTripResponseData.tripRequestId = singleTripResponseData.id;
                singleTripResponseData.tripSingleRoomPrice = "";
                singleTripResponseData.tripEarningFeePercentage = 0;
                singleTripResponseData.tripInclusions = "";
                singleTripResponseData.tripExclusions = "";
                singleTripResponseData.tripAccommodationDetails = [];
                singleTripResponseData.tripItineraryDetails = [];
                singleTripResponseData.originalAccommodation = singleTripResponseData.tripAccommodation;
                singleTripResponseData.originalExperiences = singleTripResponseData.tripExperiences;
                singleTripResponseData.tripFullRefundDays = "";
                singleTripResponseData.tripPartialRefundPercentage = "";
                singleTripResponseData.tripPartialRefundDays = "";
                singleTripResponseData.tripNoRefundDays = "";
                singleTripResponseData.tripDays = 0;
                singleTripResponseData.tripDateKnown = true;
                singleTripResponseData.tripTitle = "";
                singleTripResponseData.tripPricePerAdult = "";
                singleTripResponseData.tripPricePerChild = "";
                singleTripResponseData.tripTotalPrice = 0;
                singleTripResponseData.tripEarningFees = 0;
                singleTripResponseData.tripAgencies = partnerId;

                //changing the request budget this will set it globally
                singleTripResponseData.tripBudget = singleTripResponseData.taBudget ? singleTripResponseData.taBudget : singleTripResponseData.tripBudget
                commit("SET_TRIP_DETAILS", {...singleTripResponseData});

                if (singleOfferData) {
                    //if singleOfferData.tripDateKnown is available true else false
                    if (singleOfferData.tripDateKnown) {
                        singleOfferData.tripDateKnown = true;
                    } else {
                        singleOfferData.tripDateKnown = false;
                    }

                    //parse the accommodation
                    singleOfferData.tripAccommodationDetails = JSON.parse(
                        singleOfferData.tripAccommodation
                    );

                    //parse the itenerary
                    singleOfferData.tripItineraryDetails = JSON.parse(
                        singleOfferData.tripItinerary
                    );

                    //inclusions
                    if (singleOfferData.tripInclusions == null) {
                        singleOfferData.tripInclusions = ""
                    }

                    //exclusions
                    if (singleOfferData.tripExclusions == null) {
                        singleOfferData.tripExclusions = ""
                    }
                    singleOfferData.tripEarningFeePercentage = singleOfferData.tripEarningFees;
                    singleOfferData.tripPartialRefundPercentage = singleOfferData.tripPartialRefund;
                    singleOfferData.tripBudget = singleTripResponseData.tripBudget;
                    singleOfferData.tripAccompany = singleTripResponseData.tripAccompany;
                    singleOfferData.tripChildrenAge = singleTripResponseData.tripChildrenAge;
                    singleOfferData.tripConsultation = singleTripResponseData.tripConsultation;
                    singleOfferData.tripDays = singleTripResponseData.tripDays;
                    singleOfferData.tripDetails = singleTripResponseData.tripDetails;
                    singleOfferData.tripGroupType = singleTripResponseData.tripGroupType;
                    singleOfferData.tripLanguage = singleTripResponseData.tripLanguage;
                    singleOfferData.tripMealPlan = singleTripResponseData.tripMealPlan;
                    singleOfferData.tripReason = singleTripResponseData.tripReason;
                    singleOfferData.tripRequestStatus = singleTripResponseData.tripRequestStatus;
                    singleOfferData.userId = singleTripResponseData.userId;
                    singleOfferData.originalAccommodation = singleTripResponseData.originalAccommodation;
                    singleOfferData.originalExperiences = singleTripResponseData.originalExperiences;
                    singleOfferData.tripAgencies = partnerId;
                    singleOfferData.partnerId = partnerId;


                    if (singleOfferData.tripDestination === "Democratic Republic of Congo") {
                        singleOfferData.tripDestination = "Democratic Republic of the Congo"
                    }

                    commit("SET_TRIP_DETAILS", {...singleOfferData});
                    //setting the destination in case it is different
                    destination = singleOfferData.tripDestination;
                }


                commit("SET_REQUESTED_CHANGES", requestedChangesResponseData)
                dispatch("experiences/getExperiences", destination, {root: true})
                commit('SET_IS_LOADING_DATA', false);
                commit('SET_SHOW_UNSAVED_CHANGES_WARNING', true);

            }).catch(() => {
                commit('SET_IS_LOADING_DATA', false);
            })

        },

        async submitOffer({commit, state, rootState, dispatch}, saveType = "publish") {

            await dispatch('tracker/endOfferCreationTracking', null, {root: true});

            commit("SET_SAVING_RESPONSE_STATUS", null);
            commit("SET_IS_SUBMITTING_OFFER", true);
            let url = "";

            function compare(a, b) {
                if (a.id < b.id) {
                    return -1;
                }
                if (a.id > b.id) {
                    return 1;
                }
                return 0;
            }

            if (saveType === "draft") {
                url = "/save-offer-draft";
                commit("SET_LOADING_MESSAGE", "Saving to drafts");
            } else {
                if (state.hasAlreadySavedOffer) {
                    url = "/update-offer"
                } else {
                    url = "/save-new-offer";
                }
                commit("SET_LOADING_MESSAGE", "Updating offer");
            }

            //sort itinerary list
            let sortedItineraryDetails =
                state.offerDetails.tripDetails.tripItineraryDetails;
            sortedItineraryDetails = sortedItineraryDetails.sort(compare);

            //sort accommodation list
            let sortedAccommodationDetails =
                state.offerDetails.tripDetails.tripAccommodationDetails;
            sortedAccommodationDetails = sortedAccommodationDetails.sort(compare);

            let dataToSubmit = {
                creationDuration: rootState.tracker.time.offerCreation.duration,
                tripAccommodation: JSON.stringify(sortedAccommodationDetails),
                tripAdults: state.offerDetails.tripDetails.tripAdults,
                tripAgencies: state.offerDetails.tripDetails.tripAgencies,
                tripChildren: state.offerDetails.tripDetails.tripChildren,
                tripCurrency: state.offerDetails.tripDetails.tripCurrency,
                tripDateKnown: state.offerDetails.tripDetails.tripDateKnown,
                tripDates: state.offerDetails.tripDetails.tripDates,
                tripDestination: state.offerDetails.tripDetails.tripDestination,
                tripEarningFees:
                state.offerDetails.tripDetails.tripEarningFeePercentage,
                tripEmail: state.offerDetails.tripDetails.tripEmail,
                tripExclusions: state.offerDetails.tripDetails.tripExclusions,
                tripFirstname: state.offerDetails.tripDetails.tripFirstname,
                tripFullRefundDays:
                state.offerDetails.tripDetails.tripFullRefundDays,
                tripInclusions: state.offerDetails.tripDetails.tripInclusions,
                tripItinerary: JSON.stringify(sortedItineraryDetails),
                tripLastname: state.offerDetails.tripDetails.tripLastname,
                tripNoRefundDays: state.offerDetails.tripDetails.tripNoRefundDays,
                tripPartialRefund:
                state.offerDetails.tripDetails.tripPartialRefundPercentage,
                tripPartialRefundDays:
                state.offerDetails.tripDetails.tripPartialRefundDays,
                tripPhone: state.offerDetails.tripDetails.tripPhone,
                tripPricePerAdult:
                state.offerDetails.tripDetails.tripPricePerAdult,
                tripPricePerChild:
                state.offerDetails.tripDetails.tripPricePerChild,
                tripRequestId: state.offerDetails.tripDetails.tripRequestId,
                tripSalutation: state.offerDetails.tripDetails.tripSalutation,
                tripSingleRoomPrice:
                state.offerDetails.tripDetails.tripSingleRoomPrice,
                tripSingleRooms: state.offerDetails.tripDetails.tripSingleRooms,
                tripTitle: state.offerDetails.tripDetails.tripTitle,
                tripTotalPrice: state.offerDetails.tripDetails.tripTotalPrice,
            };
            if (state.offerDetails.tripDetails.id) {
                dataToSubmit.offerId = state.offerDetails.tripDetails.id
            }

            //setting the offer id to its original if there is an existing offer when you are not saving as draft
            if (state.hasAlreadySavedOffer && saveType !== "draft") {
                dataToSubmit.offerId = state.offerDetails.tripDetails.originalOfferId
            }
            // this.disableSubmitButton = true;
            api.post(url, dataToSubmit)
                .then(({data}) => {
                    dispatch('tracker/resetOfferCreationTracking', null, {root: true});
                    commit("SET_SAVING_RESPONSE_MESSAGE", data.message);
                    commit("SET_SAVING_RESPONSE_STATUS", data.response);
                    commit("SET_IS_SUBMITTING_OFFER", false);
                    commit('SET_SHOW_UNSAVED_CHANGES_WARNING', false);
                    // SET_SHOULD_REDIRECT_AFTER_OFFER_SAVE to false when we are not saving as draft
                    commit("SET_SHOULD_REDIRECT_AFTER_OFFER_SAVE", saveType !== "draft");
                })
                .catch((error) => {
                    commit("SET_SAVING_RESPONSE_MESSAGE", error.response.data.message);
                    commit("SET_SAVING_RESPONSE_STATUS", 'failed');
                    commit("SET_IS_SUBMITTING_OFFER", false);
                    commit("SET_SHOULD_REDIRECT_AFTER_OFFER_SAVE", false);
                    commit('SET_SHOW_UNSAVED_CHANGES_WARNING', true);
                }).finally(()=>{
                commit("SET_LOADING_MESSAGE", null);
                commit("SET_IS_SUBMITTING_OFFER", false);
            });
        },

        async dateChanged({commit}) {
            commit("UNSET_ACCOMMODATION_DETAILS");
            commit("UNSET_ITINERARY_DETAILS");
        },
    },
};
