<template>
  <button :disabled="disabled" type="button" class="btn-pill">
    <i :class="[iconClass, 'bx-xs mr-2']"></i> {{ text }}
  </button>
</template>
<script>
export default {
  name: "ButtonPill",
  props: {
    text: {
      type: String,
      default: "Text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    iconClass: {
      type: String,
      default: "bx bxs-edit",
    },
  },
};
</script>
<style scoped>
.btn-pill {
  @apply focus:outline-none focus:scale-95 focus:ring-offset-2 focus:ring-2 focus:ring-black-darker;
  @apply text-white bg-black-darker flex items-center justify-center h-9 px-3 rounded-full;
  @apply transition duration-300 ease-in-out text-sm sm:text-base; 
}
</style>