import CustomerDetails from "@/views/Offer/Form/CustomerDetails";
import Offer from "@/views/Offer/Offer";
import TripDetails from "@/views/Offer/Form/TripDetails";
import Accommodations from "@/views/Offer/Form/Accommodations";
import Itinerary from "@/views/Offer/Form/Itinerary";
import Pricing from "@/views/Offer/Form/Pricing";
import Cancellation from "@/views/Offer/Form/Cancellation";

export default [{
    path: "/request/:requestId/offer",
    redirect: {name: 'CustomerDetails'},
    name: "Offer",
    props: true,
    component: Offer,
    meta: {requiresAuth: true},
    children: [
        {
            path: 'customer-details',
            name: 'CustomerDetails',
            component: CustomerDetails,
            meta: {requiresAuth: true, isLastStep: false}
        }, {
            path: 'trip-details',
            name: 'TripDetails',
            component: TripDetails,
            meta: {requiresAuth: true, isLastStep: false}
        }, {
            path: 'accommodations',
            name: 'Accommodations',
            component: Accommodations,
            meta: {requiresAuth: true, isLastStep: false}
        }, {
            path: 'itinerary',
            name: 'Itinerary',
            component: Itinerary,
            meta: {requiresAuth: true, isLastStep: false}
        }, {
            path: 'pricing',
            name: 'Pricing',
            component: Pricing,
            meta: {requiresAuth: true, isLastStep: false}
        }, {
            path: 'cancellation',
            name: 'Cancellation',
            component: Cancellation,
            meta: {requiresAuth: true, isLastStep: true}
        },
    ]
}]
