<template>
  <div>
    <div class="gallery">
      <ButtonIconText
        v-if="showCalltoAction"
        text="Show all photos"
        btnStyle="absolute left-3 bottom-3 z-10"
      />
      <figure class="relative w-full overflow-hidden rounded main-img">
        <img
          class="absolute object-cover w-full h-full"
          :src="mainPhoto.img"
          :alt="mainPhoto.alt || 'Placeholder image'"
        />
      </figure>
      <div class="flex-col justify-between hidden h-full side-img-container">
        <figure class="relative overflow-hidden rounded side-img">
          <img
            class="absolute object-cover w-full h-full"
            :src="sidePhotoTop.img"
            :alt="sidePhotoTop.alt || 'Placeholder image'"
          />
        </figure>
        <figure class="relative overflow-hidden rounded side-img">
          <img
            class="absolute object-cover w-full h-full"
            :src="sidePhotoBottom.img"
            :alt="sidePhotoBottom.alt || 'Placeholder image'"
          />
        </figure>
      </div>
    </div>
  </div>
</template>
<script>
import ButtonIconText from "@/components/Buttons/ButtonIconText";
export default {
  name: "Gallery",
  components: {
    ButtonIconText,
  },
  props: {
    mainPhoto: {
      type: Object,
      default: () => {
        return {
          img: require("@/assets/img/placeholders/placeholder.png"),
          alt: "Placeholder image",
        };
      },
    },
    showCalltoAction: {
      type: Boolean,
      default: true,
    },
    sidePhotoTop: {
      type: Object,
      default: () => {
        return {
          img: require("@/assets/img/placeholders/placeholder.png"),
          alt: "Placeholder image",
        };
      },
    },
    sidePhotoBottom: {
      type: Object,
      default: () => {
        return {
          img: require("@/assets/img/placeholders/placeholder.png"),
          alt: "Placeholder image",
        };
      },
    },
  },
};
</script>
<style scoped>
.gallery {
  height: 382px;
  @apply relative flex justify-between w-full;
}

@media screen and (min-width: 712px) {
  .main-img {
    max-width: 412px;
    width: 100%;
  }

  .side-img-container {
    display: flex;
  }
}

.side-img-container {
  width: 100%;
  max-width: 216px;
}

.side-img {
  width: 100%;
  height: 182px;
}

.main-img,
.side-img {
  cursor: pointer;
}
</style>
