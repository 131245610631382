<template>
  <button type="button" :class="['btn', customStyle]">
    <i :class="[iconClass, 'bx-xs']"></i>
  </button>
</template>
<script>
export default {
  name: "ButtonIcon",
  props: {
    iconClass: {
      type: String,
      default: "bx bx-trash",
    },
    customStyle: {
      type: String,
      default: "bg-white text-black-base border-grey-base hover:bg-grey-light",
    },
  },
};
</script>
<style scoped>
.btn {
  @apply rounded border py-2 px-3 text-sm focus:outline-none focus:scale-95 focus:ring-offset-2 focus:ring-2 focus:ring-black-darker;
  @apply duration-300 ease-in-out;
}
</style>