<template>
  <button :class="['btn-style', btnStyle]">
    <i :class="[iconClass, 'mr-1.5 bx-xs']"></i>
    <span class="text">{{ text }}</span>
  </button>
</template>
<script>
export default {
  name: "ButtonIconText",
  props: {
    iconClass: {
      type: String,
      default: "bx bx-images",
    },
    text: {
      type: String,
      default: "Buttont text",
    },
    btnStyle: {
      type: String,
    },
  },
};
</script>
<style scoped>
.btn-style {
  @apply bg-white rounded border border-grey-base duration-300 ease-in-out hover:bg-grey-lighter text-black-base text-sm;
  @apply py-2 px-3 focus:outline-none focus:scale-95 focus:ring-offset-2 focus:ring-2 focus:ring-black-darker;
  @apply flex items-center justify-center;
}

.text{
  @apply font-semibold;
}
</style>