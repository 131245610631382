export default {
  namespaced: true,
  state: {
    lightBox: {
      showLightBox: false,
      photos: [],
    },
  },

  getters: {
    showLightBox(state) {
      return state.lightBox.showLightBox;
    },
    photos(state) {
      return state.lightBox.photos;
    },
  },

  mutations: {
    SET_LIGHTBOX_STATUS(state, value) {
      state.lightBox.showLightBox = value;
    },

    SET_LIGHTBOX_PHOTOS(state, value) {
      state.lightBox.photos = value;
    },

  },

  actions: {
    setLightBoxStatus({commit}, status) {
      commit("SET_LIGHTBOX_STATUS", status);
    },

    setLightBoxPhotos({commit}, photos) {
      commit("SET_LIGHTBOX_PHOTOS", photos);
    },

  },
};
