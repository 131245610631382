<template>
  <label class="flex flex-wrap" :for="name">
    <span v-if="showLabel" class="input-label">
      {{ label }}
    </span>
    <button
      @click="revealPassword"
      v-if="isPassword"
      class="ml-auto"
      type="button"
    >
      <i :class="['bx', icon, 'icon-style']"></i>
    </button>
    <input
      :id="name"
      :placeholder="placeholder"
      class="input-field"
      :class="[
        showErrMsg ? 'border-red-base' : 'border-grey-dark',
        'input-field',
      ]"
      :value="value"
      :type="inputType"
      @blur="$emit('blur')"
      @input="emitMethods($event)"
      v-bind="$attrs"
    />

    <span
      v-if="showErrMsg"
      class="mt-2 font-sans text-sm font-medium error-msg text-red-base"
    >
      {{ errorMsg }}
    </span>
  </label>
</template>
<script>
export default {
  name: "InputText",
  inheritAttrs: false,
  data() {
    return {
      inputText: "",
      isHidden: true,
      inputType: this.type,
    };
  },
  props: {
    value: { type: [String, Number] },
    label: {
      type: String,
      default: "Label",
    },
    placeholder: {
      type: String,
      default: "Placeholder",
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    help: {
      type: String,
    },
    type: {
      type: String,
      default: "text",
    },
    errorMsg: {
      type: String,
      default: "",
    },
    showErrMsg: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "input",
    },
  },
  methods: {
    emitMethods(event) {
      this.$emit("input", event.target.value);
      this.$emit("secondaryInput");
    },
    revealPassword() {
      if (this.inputType == "password") {
        this.inputType = "text";
        this.isHidden = !this.isHidden;
      } else {
        this.inputType = "password";
        this.isHidden = !this.isHidden;
      }
    },
  },
  computed: {
    icon() {
      if (this.isHidden == true) return "bxs-show";
      return "bxs-hide";
    },
    isPassword() {
      if (this.type.toLowerCase() == "password") return true;
      return false;
    },
  },
};
</script>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.input-label {
  @apply mb-1.5 sm:mb-3 font-sans text-black-base font-medium;
  @apply text-base;
}

.input-field {
  @apply w-full p-2 sm:p-3 border rounded-lg focus:border-teal-base;
  @apply focus:ring-teal-lighter focus:ring-2 text-black-base focus:outline-none placeholder-black-lightest;
  @apply text-base;
}

.icon-style {
  @apply text-xl text-black-lightest;
}
</style>
