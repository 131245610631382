<template>
  <div :id="blockId" :class="['hotel-block', blockOpacity]" v-if="showBlock" class="w-full">
    <BlockPlaceholder
        v-show="showPlaceholder"
        class="mb-3"
        :amountOfNights="amountOfNights"
        :currentNight="currentNight"
        :currentDate="currentDate"
        :startDate="startDate"
        :endDate="endDate"
        @primaryAction="emitMethod"
        :disablePlaceholderBtn="disablePlaceholderBtn"
    />
    <SummaryTile
        v-if="showSummary"
        :accommodationDetails="night.accommodation"
        @delete="emitDelete"
        @edit="$emit('edit')"
        class="mb-6"
    />

    <div class="edit-container" v-show="showInputFields">
      <div class="accommodation-block-content">
        <div class="font-sans rounded-md mt-6 mb-6 inline-flex items-center">
          <i
              class="bx bxs-moon bx-sm block-content-icon text-violet-base mr-3"
          ></i>
          <div>
            <span class="block-title">
              Night {{ currentNight }} of {{ amountOfNights }}
            </span>
            <p class="block-subtitle">
              {{ currentDate }}
            </p>
          </div>
        </div>
        <SelectSearch
            class="w-full mb-6"
            :resultItems="hotels"
            emptyStateTitle="No hotels found"
            emptyStateText="No hotels could be found under the given keyword. Try searching again or add a new hotel to the Travel Afrique database."
            primaryAction="Add new hotel"
            @primaryAction="$emit('showHotelSheet')"
            :name="`accommodation-${blockId}`"
            :searchKeyword="searchKeyword"
            :selected="night.accommodation.hotel"
            v-model="night.accommodation.hotel"
        />
        <label class="mealplan-label">Meal plan</label>
        <div class="checkbox-container">
          <Checkbox
              label="Breakfast"
              name="meal-plan"
              class="checkbox"
              v-model="night.accommodation.mealplan.breakfast"
          />
          <Checkbox
              label="Lunch"
              name="meal-plan"
              class="checkbox"
              v-model="night.accommodation.mealplan.lunch"
          />
          <Checkbox
              label="Dinner"
              class="checkbox"
              name="meal-plan"
              v-model="night.accommodation.mealplan.dinner"
          />
        </div>
        <SelectDropdown
            ref="selectDropdown"
            description="Select number of nights"
            :showLabel="true"
            label="Nights"
            :optionsList="numberOfNights"
            :name="`night-${blockId}`"
            v-model="night.accommodation.numberOfNights"
            :selected="night.accommodation.numberOfNights"
            @getSelectValue="getSelectValue"
        />
      </div>
      <Divider class="w-full mt-12"/>
      <div class="btn-container">
        <ButtonSecondary
            @click.native="$emit('cancelAction')"
            class="btn-cancel"
            text="Cancel"
        />

        <ButtonPrimary
            @click.native="emitSaveMethods()"
            class="btn-save"
            text="Save"
            mode="dark"
        />
      </div>
    </div>
  </div>
</template>
<script>
import BlockPlaceholder from "@/components/Offer/Accommodations/BlockPlaceholder";
import ButtonSecondary from "@/components/Buttons/ButtonSecondary";
import ButtonPrimary from "@/components/Buttons/ButtonPrimary";
import Checkbox from "@/components/Inputs/Checkbox";
import Divider from "@/components/Layout/Divider";
import SelectDropdown from "@/components/Inputs/SelectDropdown";
import SelectSearch from "@/components/Inputs/SelectSearch/SelectSearch";
import SummaryTile from "@/components/Offer/Accommodations/SummaryTile";
import {generateNewDateFromDuration} from "@/shared/dateHandler";

export default {
  name: "AccommodationBlock",
  components: {
    BlockPlaceholder,
    ButtonSecondary,
    ButtonPrimary,
    Checkbox,
    Divider,
    SelectDropdown,
    SelectSearch,
    SummaryTile,
  },
  data() {
    return {
      searchKeyword: "",
      accommodationDetails: {
        hotel: {
          name: "",
          address: "",
        },
        mealplan: {
          breakfast: false,
          lunch: false,
          supper: false,
        },
        numberOfNights: "",
      },
      mealplan: [
        {value: "Breakfast", name: "Breakfast"},
        {value: "Half board", name: "Half board"},
        {value: "Full board", name: "Full board"},
      ],
    };
  },
  props: {
    blockOpacity: {
      type: String,
      default: "opacity-100",
    },
    night: {
      type: Object,
    },
    blockId: {
      type: Number,
      default: 0,
    },

    showPlaceholder: {
      type: Boolean,
      default: true,
    },
    showInputFields: {
      type: Boolean,
      default: false,
    },
    showBlock: {
      type: Boolean,
      default: true,
    },
    selectedNumberOfNights: {
      type: Number,
      default: 1,
    },
    showSummary: {
      type: Boolean,
      default: false,
    },
    showNightSelect: {
      type: Boolean,
      default: true,
    },
    numberOfNights: {
      type: Array,
    },
    hotels: {
      type: Array,
    },
    currentNight: {
      type: Number,
      default: 1,
    },
    amountOfNights: {
      type: Number,
      default: 9,
    },
    currentDate: {
      type: String,
    },
    text: {
      type: String,
      default: "Add an accommodation",
    },
    startDate: {
      type: String,
      default: "11 October, 2021",
    },
    endDate: {
      type: String,
      default: "25 October, 2021",
    },
    disablePlaceholderBtn: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitMethod() {
      this.$emit("openBlock");
    },
    emitSaveMethods() {
      this.night.accommodation.checkout = this.checkoutDate;
      this.night.accommodation.fullCheckoutDate = this.fullCheckoutDate;
      this.$emit("saveBlock", this.night);
    },
    getSelectValue(value) {
      //Emit value and event to parent component
      this.$emit("getSelectValue", value);
    },
    emitDelete() {
      this.$emit("delete");
      this.night.accommodation.numberOfNights = 0;
    },
  },
  computed: {
    checkoutDate() {
      return `${generateNewDateFromDuration(this.night.fullStartDate, this.night.accommodation.numberOfNights).format("DD MMMM")}`;
    },
    fullCheckoutDate() {
      return `${generateNewDateFromDuration(this.night.fullStartDate, this.night.accommodation.numberOfNights).toISOString()}`;
    },
  },
};
</script>
<style scoped>
.mealplan-label {
  @apply font-sans font-medium text-black-base;
  @apply text-sm sm:text-base;
}

.edit-container {
  @apply bg-white shadow-sm mb-3 rounded-md border border-grey-base;
}

.btn-container {
  @apply flex items-center justify-between w-full p-4 sm:p-6;
}

.btn-cancel {
  @apply sm:ml-auto mr-0 sm:mr-3 w-calc-1/2 sm:w-auto;
}

.btn-save {
  @apply block px-8 w-calc-1/2 sm:w-auto;
}

.checkbox-container {
  @apply flex w-full flex-wrap break-words mb-2;
}

.checkbox {
  @apply w-full sm:w-calc-1/3;
}

.block-title {
  @apply font-sans text-black-base font-bold text-xs sm:text-sm uppercase;
}

.block-subtitle {
  @apply w-full text-black-lightest text-xs uppercase;
}

.accommodation-block-content {
  @apply px-4 sm:px-6;
}
</style>