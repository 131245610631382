import api from "../../api";

export default {
    namespaced: true,
    state: {
        partners: [],
        selectedPartner: {},
        selectedPartnerOffers: [],
        isLoadingData: false,
        isSubmittingData: false,
        submissionStatusMessage: '',
        submissionStatus: '',
    },
    getters: {
        partners(state) {
            return state.partners
        },
        approvedPartners(state) {
            return state.partners.filter((partner) => {
                return partner.status === "approved";
            });
        },
        newPartners(state) {
            return state.partners.filter((partner) => {
                return partner.status !== "approved";
            });
        },
        selectedPartner(state) {
            return state.selectedPartner;
        },
        selectedPartnerOffers(state) {
            return state.selectedPartnerOffers
        },
        isLoadingData(state) {
            return state.isLoadingData
        },
        isSubmittingData(state) {
            return state.isSubmittingData
        },
        submissionStatus(state) {
            return state.submissionStatus;
        },
        submissionStatusMessage(state) {
            return state.submissionStatusMessage;
        },
    },
    mutations: {
        SET_PARTNERS(state, value) {
            state.partners = value;
        },
        SET_SELECTED_PARTNER(state, value) {
            state.selectedPartner = value;
        },
        SET_SELECTED_PARTNER_OFFERS(state, value) {
            state.selectedPartnerOffers = value;
        },
        SET_IS_LOADING_DATA(state, value) {
            state.isLoadingData = value
        },
        SET_IS_SUBMITTING_DATA(state, value) {
            state.isSubmittingData = value
        },
        SET_SUBMISSION_STATUS(state, value) {
            state.submissionStatus = value;
        },
        SET_SUBMISSION_STATUS_MESSAGE(state, value) {
            state.submissionStatusMessage = value;
        },
    },
    actions: {
        getPartners({commit}) {
            commit('SET_IS_LOADING_DATA', true);
            return api.get("/all-partners")
                .then(({data}) => {
                    commit('SET_PARTNERS', data.partners);
                    commit('SET_IS_LOADING_DATA', false);
                })
                .catch((error) => {
                    commit('SET_IS_LOADING_DATA', false);
                    throw(error);
                });
        },
        getSelectedPartnerAndOffers({commit}, partnerID) {
            commit('SET_IS_LOADING_DATA', true);
            api.getAll([
                `/single-partner/${partnerID}`,
                `/partner-offers/${partnerID}`,
            ])
                .then((response) => {
                    let partner = response[0].data.partner;
                    let address = partner.companyAddress;
                    let addressSplit = address.split(",");
                    partner.companyStreet = addressSplit[0];
                    partner.companyLocation = addressSplit[1];



                    commit('SET_SELECTED_PARTNER', partner);
                    commit('SET_SELECTED_PARTNER_OFFERS', response[1].data.offers);
                    commit('SET_IS_LOADING_DATA', false);
                })
                .catch((error) => {
                    commit('SET_IS_LOADING_DATA', false);
                    throw(error);
                });
        },
        getSelectedPartner({commit}, partnerID) {
            commit('SET_IS_LOADING_DATA', true);
            api.get(
                `/single-partner/${partnerID}`)
                .then((response) => {
                    let partner = response.data.partner;
                    let address = partner.companyAddress;
                    let addressSplit = address.split(",");
                    partner.companyStreet = addressSplit[0];
                    partner.companyLocation = addressSplit[1];
                    commit('SET_SELECTED_PARTNER', partner);
                    commit('SET_IS_LOADING_DATA', false);
                })
                .catch((error) => {
                    commit('SET_IS_LOADING_DATA', false);
                    throw(error);
                });
        },
        editPartner({commit}, formData) {
            api.post("/update-partner", formData, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
                .then(({data}) => {
                    commit('SET_IS_SUBMITTING_DATA', false);
                    commit('SET_SELECTED_PARTNER', data.data);
                    commit('SET_SUBMISSION_STATUS', data.response);
                    commit('SET_SUBMISSION_STATUS_MESSAGE', data.message);
                })
                .catch((error) => {
                    commit('SET_IS_SUBMITTING_DATA', false);
                    commit('SET_SUBMISSION_STATUS', 'failed');
                    commit('SET_SUBMISSION_STATUS_MESSAGE', error.response.data.message);
                });
        }
    }
}