<template>
  <div class="block-item-container">
    <div class="block-content">
      <i class="bx bxs-moon bx-sm block-content-icon"></i>
      <div>
        <h4 class="block-title">
          Night {{ currentNight }} of {{ amountOfNights }}
        </h4>
        <p class="block-subtitle">
          {{ currentDate }}
        </p>
      </div>
    </div>
    <ButtonPill
      @click.native="emitMethods"
      text="Edit"
      :disabled="disablePlaceholderBtn"
    />
  </div>
</template>
<script>
import ButtonPill from "@/components/Buttons/ButtonPill";
export default {
  name: "BlockPlaceholder",
  components: {
    ButtonPill,
  },
  props: {
    currentNight: {
      type: Number,
      default: 1,
    },
    amountOfNights: {
      type: Number,
      default: 9,
    },
    currentDate: {
      type: String,
      default: "11 Oct",
    },
    text: {
      type: String,
      default: "Add an accommodation",
    },
    startDate: {
      type: String,
      default: "11 October, 2021",
    },
    endDate: {
      type: String,
      default: "25 October, 2021",
    },
    disablePlaceholderBtn: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitMethods() {
      this.$emit("primaryAction");
    },
  },
};
</script>
<style scoped>
.block-item-container {
  @apply bg-white flex-wrap flex justify-between items-center rounded-md w-full;
  @apply p-4 sm:p-6 border border-grey-base;
}
.block-content {
  @apply flex items-center;
}
.block-content-icon {
  @apply text-violet-base mr-3;
}
.block-title {
  @apply font-sans text-black-base font-bold text-xs sm:text-sm uppercase;
}
.block-subtitle {
  @apply w-full text-black-lightest text-xs uppercase;
}
</style>