<template>
  <div :id="experienceId" class="card-horizontal">
    <figure class="card-img-container">
      <img :src="imagePath" v-if="imagePath" alt="" class="card-img" />
      <img
        v-else
        src="@/assets/img/placeholder.png"
        alt="Placeholder"
        class="card-img"
      />
    </figure>
    <div id="text-container" class="card-text-container">
      <h4 class="card-title">
        {{ title }}
      </h4>
      <div class="flex items-center">
        <i class="bx bxs-map-pin card-icon"></i>
        <p class="card-location">{{ location }}</p>
      </div>

      <div class="btn-container" v-if="showAddConfirmation">
        <div class="confirmation-tag">
          <i class="bx bx-check text-base"></i>
          <span>Added</span>
        </div>
        <button
          type="button"
          class="btn-remove"
          @click="
            removeObj({
              id: experienceId,
              title: title,
              location: location,
              photo: imgPath,
            })
          "
        >
          <i class="bx bx-trash"></i>
        </button>
      </div>
      <ButtonFrameless
        v-else
        :text="actionText"
        class="btn-add"
        @click.native="
          emitObj({
            type: 'experience',
            id: experienceId,
            title: title,
            location: location,
            photo: imgPath,
          })
        "
      />
    </div>
  </div>
</template>
<script>
import ButtonFrameless from "@/components/Buttons/ButtonFrameless";
export default {
  name: "CardHorizontal",
  components: { ButtonFrameless },
  props: {
    showNegativAction: {
      type: Boolean,
      default: false,
    },
    showPositiveAction: {
      type: Boolean,
      default: true,
    },
    showAddConfirmation: {
      type: Boolean,
      default: false,
    },
    experienceId: {
      type: Number,
      default: 0,
    },
    imgPath: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Hiking in Kilimanjaro",
    },
    location: {
      type: String,
      default: "Kilimanjaro, Kenya",
    },
    actionText: {
      type: String,
      default: "Add experience",
    },
  },
  methods: {
    emitObj(obj) {
      this.$emit("cardObj", obj);
    },
    removeObj(obj) {
      this.$emit("removeCardObj", obj);
    },
  },
  computed: {
    basePath() {
      return process.env.VUE_APP_FILE_BASE_PATH;
    },
    imagePath() {
      if (this.imgPath) {
        return `${this.basePath}${this.imgPath.split(",")[0]}`;
      } else {
        return "";
      }
    },
  },
};
</script>
<style scoped>
.card-horizontal {
  @apply bg-white flex flex-wrap xs:flex-nowrap rounded-md overflow-hidden;
  @apply border border-grey-base shadow-sm w-full;
}

.card-img-container {
  @apply h-44 xs:h-32 w-full xs:w-60 relative overflow-hidden;
}

.card-img {
  @apply absolute object-cover w-full h-full;
}

.card-text-container {
  @apply p-4  w-full;
}

.card-title {
  @apply font-sans text-black-base font-semibold;
  @apply text-base sm:text-lg;
}

.card-icon {
  @apply text-xs text-black-lightest mr-1;
}

.card-location {
  @apply font-sans text-black-lightest text-xs sm:text-sm;
}

.confirmation-tag {
  @apply flex w-max items-center bg-teal-lighter text-teal-dark  py-2 px-3 rounded-full uppercase;
  @apply ml-auto;
}

.confirmation-tag span {
  @apply text-xxs sm:text-xs font-medium;
}
.btn-add {
  @apply ml-auto block;
  @apply text-xs sm:text-sm;
}

.btn-container {
  @apply flex mt-2.5;
}

.btn-remove {
  @apply rounded-full ml-2 text-red-base bg-white w-10 h-8 py-2;
  @apply hover:bg-grey-light transition-all ease-out duration-300;
  @apply flex items-center justify-center;
  @apply border border-grey-base;
}
</style>