import Vue from "vue";
import VueRouter from "vue-router";
import store from '@/store/index';
import offer from "./offer.routes";
import PageNotFound from "@/views/404";

Vue.use(VueRouter);

const routes = [{
    path: "",
    name: "RequestsMain",
    component: () => import("@/views/Requests/Requests"),
    meta: {
        requiresAuth: true
    },
},
    {
        path: "/",
        name: "Requests",
        component: () => import("@/views/Requests/Requests"),
        meta: {
            requiresAuth: true
        },
    },

    {
        path: "/account",
        name: "account",
        component: () => import("@/views/Account.vue"),
        meta: {
            requiresAuth: true
        },
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/Login.vue"),
        meta: {
            requiresAuth: false
        },
    },
    {
        path: "/password-reset",
        name: "Reset password",
        component: () => import("@/views/PasswordReset.vue"),
        meta: {
            requiresAuth: false
        },
    }, {
        path: "/reset-password",
        name: "ResetPassword",
        component: () => import("@/views/ResetPassword.vue"),
        meta: {
            requiresAuth: false
        },
    },
    {
        path: "/complete-registration",
        name: "CompleteRegistration",
        component: () => import("@/views/CompleteRegistration.vue"),
        meta: {
            requiresAuth: false
        },
        props: true,
    },
    {
        path: "/request/:requestId",
        name: "Request",
        component: () => import("@/views/Requests/Request"),
        props: true,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: "/company",
        name: "Company",
        component: () => import("@/views/Company.vue"),
        meta: {
            requiresAuth: true
        },
    },
    ...offer,
    {
        path: '/:catchAll(.*)',
        component: PageNotFound,
        name: 'NotFound'
    }

];

const router = new VueRouter({
    mode: "history",
    routes,
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
});

router.beforeEach((to, from, next) => {
    if (from.matched.some(record => record.meta.needsConfirm)) {

        let showWarning = true;
        if (to.params.showWarning) {
            //converting the string to a boolean
            showWarning = JSON.parse(to.params.showWarning);
        }
        if (showWarning) {
            if (window.confirm("Are you sure you want to leave the page? \nIf you leave without saving, your changes will be lost.")) {
                next();
            } else {
                return
            }
        } else {
            next();
        }
    }
    const loginQuery = {
        path: "/login",
        query: {
            redirect: to.fullPath !== "/login?redirect=%2F" ? to.fullPath : ""
        }
    };
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.state.auth.authenticated) {
            next()
            return
        }
        next(loginQuery)
    }
    next();
});

export default router;