<template>
  <article class="message-item">
    <div class="content-container flex w-full">
      <div class="text w-full">
        <div class="upper-part flex justify-between w-full mb-1">
          <h5 class="font-sans text-black-base text-lg font-semibold">
            {{ customerName }}
          </h5>
          <span class="date-time"><timeago :datetime="dateTime" :auto-update="60"></timeago> </span>
        </div>
        <div class="lower-part w-full flex overflow-hidden">
          <p class="font-sans text-black-lightest inline-block">
            {{ message }}
          </p>
        </div>
      </div>
    </div>
  </article>
</template>
<script>
export default {
  props: {
    message: {
      type: String,
      default: "Text of message.",
    },
    customerName: {
      type: String,
      default: "Customer name",
    },
    dateTime: {
      type: String,
      default: "DD.MM.YYYY, HH:MM",
    },
    messageType:{
      type:String
    }
  },
};
</script>
<style scoped>
.message-item {
  @apply w-full border-b border-grey-base pt-12 pb-6 flex flex-wrap items-center;
}
.avatar {
  @apply min-h-16 min-w-16 rounded-full bg-yellow-light border border-yellow-dark mr-6;
}

.date-time {
  @apply text-sm font-medium text-black-lighter bg-grey-light block rounded p-1 px-1.5;
}
</style>