import api from "../../api/index"

export default {
    namespaced: true,
    state: {
        authenticate: false,
        partnerToken: localStorage.getItem('partner_token') || null,
        user: null,
        role: null,
        isTokenValid: null,
        registerPartnerSuccessful: false,
        isLoadingData: false,
    },

    getters: {
        authenticated(state) {
            return state.authenticated;
        },
        partnerToken(state) {
            return state.partnerToken;
        },
        user(state) {
            return state.user;
        },
        role(state) {
            return state.role;
        },
        isTokenValid(state) {
            return state.isTokenValid;
        },
        isLoadingData(state) {
            return state.isLoadingData;
        },
        registerPartnerSuccessful(state) {
            return state.registerPartnerSuccessful;
        }
    },

    mutations: {
        SET_AUTHENTICATED(state, value) {
            state.authenticated = value;
        },

        SET_TOKEN(state, value) {
            localStorage.setItem('partner_token', value);
        },

        SET_USER(state, value) {
            state.user = value;
        },
        SET_ROLE(state, value) {
            state.role = value;
        },
        SET_IS_TOKEN_VALID(state, value) {
            state.isTokenValid = value;
        },
        REGISTER_PARTNER_SUCCESSFUL(state, value) {
            state.registerPartnerSuccessful = value;
        },
        SET_IS_LOADING_DATA(state, value) {
            state.isLoadingData = value;
        },

    },

    actions: {
        login({commit}, credentials) {
            commit("SET_IS_LOADING_DATA", true);
            return api.post("login", credentials).then(response => {
                if (response.data.user.role === "partner") {
                    commit("SET_AUTHENTICATED", true);
                    commit("SET_TOKEN", response.data.token);
                    commit("SET_USER", response.data.user);
                }
                return response;

            }).catch((e) => {
                commit("SET_AUTHENTICATED", false);
                commit("SET_TOKEN", null);
                commit("SET_USER", null);
                throw(e);
            }).finally(() => {
                commit("SET_IS_LOADING_DATA", false);
            })
        },

        async logout({commit}) {
            return api.post(
                "/logout",
                {},
            ).finally(() => {
                localStorage.setItem('token', null);
                commit("SET_AUTHENTICATED", false);
                commit("SET_TOKEN", null);
                commit("SET_USER", null);
            })

        },


        async checkVerifyToken({commit}, token) {
            commit("SET_IS_LOADING_DATA", true);
            //checking the token
            let response = await api.get(`business/check-register-token?token=${token}`)

            try {
                if (response.data) {
                    commit('SET_IS_TOKEN_VALID', true);
                    commit("SET_IS_LOADING_DATA", false);
                    return response
                }
            } catch (error) {
                commit("SET_IS_LOADING_DATA", false);
                commit('SET_IS_TOKEN_VALID', false)
                return error
            }


        },

        async registerPartner({commit}, credentials) {
            commit("SET_IS_LOADING_DATA", true);
            try {
                let response = await api.post('/business/register-partner', credentials);
                if (response.data && response.status == 200) {
                    commit("REGISTER_PARTNER_SUCCESSFUL", true);
                    commit("SET_AUTHENTICATED", true);
                    commit("SET_TOKEN", response.data.token);
                    commit("SET_USER", response.data.user);
                    commit("SET_IS_LOADING_DATA", false);
                    return response
                } else {
                    commit("REGISTER_PARTNER_SUCCESSFUL", false);
                    commit("SET_IS_LOADING_DATA", false);
                    commit("SET_AUTHENTICATED", false);
                    commit("SET_TOKEN", null);
                    commit("SET_USER", null);
                    return response;
                }
            } catch (e) {
                commit("REGISTER_PARTNER_SUCCESSFUL", false);
                commit("SET_IS_LOADING_DATA", false);
                commit("SET_AUTHENTICATED", false);
                commit("SET_TOKEN", null);
                commit("SET_USER", null);
                return e;
            }

        },

        updateAccountDetails({commit}, data) {
            commit('SET_IS_LOADING_DATA', true);
            let response = api.post('/business/update-account-details', data);
            if (response) {
                commit('SET_IS_LOADING_DATA', false);
            }
            return response;
        },

        getAccountDetails() {
            return api.get('/business/get-account-details')
        },

        resetCredentials({commit}, email) {
            commit("SET_IS_LOADING_DATA", true)
            return api.post('/password/forgot-password', {email: email}).finally(() => {
                commit("SET_IS_LOADING_DATA", false)
            })
        },

        updateCompanyDetails({commit}, data) {

            commit('SET_IS_LOADING_DATA', true);
            let response = api.post('/business/update-company-details', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response) {
                commit('SET_IS_LOADING_DATA', false);
            }
            return response;

        },

        deleteProfilePhoto() {
            return api.post('/business/delete-profile-photo')
        },

        getCompanyDetails() {

            return api.get('/business/get-company-details')
        }


    },
};
