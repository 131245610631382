import Vue from "vue";
import App from "./App.vue";
import VueTelInput from "vue-tel-input";
import router from "./router";
import "@/assets/css/inter.css";
import "@/assets/css/tailwind.css";
import "@/assets/icons/fontawesome/css/all.min.css";
import "vue-tel-input/dist/vue-tel-input.css";
import VueTimeago from "vue-timeago";
import Vuex from "vuex";
import store from "./store";
import Notifications from "vue-notification";
import Destinations from "./extras/destinations";
import 'boxicons';
import "@/assets/icons/boxicons/css/boxicons.min.css";



Vue.use(Notifications);
Vue.use(VueTelInput);
Vue.use(VueTimeago, {
  name: "Timeago", // Component name, `Timeago` by default
  locale: "en", // Default locale
});
Vue.config.productionTip = false;
Vue.use(Vuex);
Vue.prototype.$africanDestinations = Destinations;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
