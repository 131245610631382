<template>
  <div class="block-container">
    <div id="left-side" class="flex items-center">
      <i class="bx bxs-sun bx-sm text-violet-base mr-3"></i>
      <div class="w-full">
        <h4 class="block-title">Day {{ currentDay }} of {{ amountOfDays }}</h4>
        <p class="block-subtitle">
          {{ currentDate }}
        </p>
        <p
          v-show="showActivities"
          class="font-sans text-black-lightest text-sm"
        >
          {{ experiences }}
        </p>
      </div>
    </div>
    <ButtonPill
      @click.native="$emit('blockAction')"
      text="Edit"
      :disabled="disAttribute"
    />
  </div>
</template>
<script>
import ButtonPill from "@/components/Buttons/ButtonPill";
export default {
  components: {
    ButtonPill,
  },
  name: "BlockPlaceholder",
  data() {
    return {};
  },
  props: {
    showActivities: {
      type: Boolean,
      default: false,
    },
    disAttribute: {
      type: Boolean,
      default: false,
    },
    currentDay: {
      type: Number,
      default: 1,
    },
    amountOfDays: {
      type: Number,
      default: 9,
    },
    currentDate: {
      type: String,
      default: "11 Oct",
    },
    text: {
      type: String,
      default: "Add an accommodation",
    },
    experiences: {
      type: String,
      default: "Hiking, Beach, Canoeing",
    },
  },
};
</script>
<style scoped>
.block-container {
  @apply bg-white flex-wrap flex justify-between items-center rounded-md w-full;
  @apply px-4 py-3 sm:p-6 border border-grey-base;
}
.block-title {
  @apply font-sans text-black-base font-bold text-xs sm:text-sm uppercase;
}
.block-subtitle {
  @apply w-full text-black-lightest text-xs uppercase;
}
</style>