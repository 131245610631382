//this file contains all the travel affrique destinations 
export default [
    {
        value: "Algeria",
        name: "Algeria",
    },
    {
        value: "Angola",
        name: "Angola",
    },
    {
        value: "Benin",
        name: "Benin",
    },
    {
        value: "Botswana",
        name: "Botswana",
    },
    {
        value: "Burkina Faso",
        name: "Burkina Faso",
    },
    {
        value: "Burundi",
        name: "Burundi",
    },
    {
        value: "Cameroon",
        name: "Cameroon",
    },
    {
        value: "Cape Verde",
        name: "Cape Verde",
    },
    {
        value: "Central African Republic",
        name: "Central African Republic",
    },
    {
        value: "Chad",
        name: "Chad",
    },
    {
        value: "Comoros",
        name: "Comoros",
    },
    {
        value: "Côte d'Ivoire",
        name: "Côte d'Ivoire",
    },
    {
        value: "Democratic Republic of the Congo",
        name: "Democratic Republic of the Congo",
    },
    {
        value: "Djibouti",
        name: "Djibouti",
    },
    {
        value: "Egypt",
        name: "Egypt",
    },
    {
        value: "Equatorial Guinea",
        name: "Equatorial Guinea",
    },
    {
        value: "Eritrea",
        name: "Eritrea",
    },
    {
        value: "Eswatini",
        name: "Eswatini",
    },
    {
        value: "Ethiopia",
        name: "Ethiopia",
    },
    {
        value: "Gabon",
        name: "Gabon",
    },
    {
        value: "Gambia",
        name: "Gambia",
    },
    {
        value: "Ghana",
        name: "Ghana",
    },
    {
        value: "Guinea",
        name: "Guinea",
    },
    {
        value: "Guinea-Bissau",
        name: "Guinea-Bissau",
    },
    {
        value: "Kenya",
        name: "Kenya",
    },
    {
        value: "Lesotho",
        name: "Lesotho",
    },
    {
        value: "Liberia",
        name: "Liberia",
    },
    {
        value: "Libya",
        name: "Libya",
    },
    {
        value: "Madagascar",
        name: "Madagascar",
    },
    {
        value: "Malawi",
        name: "Malawi",
    },
    {
        value: "Mali",
        name: "Mali",
    },
    {
        value: "Mauritania",
        name: "Mauritania",
    },
    {
        value: "Mauritius",
        name: "Mauritius",
    },
    {
        value: "Morocco",
        name: "Morocco",
    },
    {
        value: "Mozambique",
        name: "Mozambique",
    },
    {
        value: "Namibia",
        name: "Namibia",
    },
    {
        value: "Niger",
        name: "Niger",
    },
    {
        value: "Nigeria",
        name: "Nigeria",
    },
    {
        value: "Republic of the Congo",
        name: "Republic of the Congo",
    },
    {
        value: "Rwanda",
        name: "Rwanda",
    },
    {
        value: "Sao Tome and Principe",
        name: "Sao Tome and Principe",
    },
    {
        value: "Senegal",
        name: "Senegal",
    },
    {
        value: "Seychelles",
        name: "Seychelles",
    },
    {
        value: "Sierra Leone",
        name: "Sierra Leone",
    },
    {
        value: "Somalia",
        name: "Somalia",
    },
    {
        value: "South Africa",
        name: "South Africa",
    },
    {
        value: "Sudan",
        name: "Sudan",
    },
    {
        value: "Tanzania",
        name: "Tanzania",
    },
    {
        value: "Togo",
        name: "Togo",
    },
    {
        value: "Tunisia",
        name: "Tunisia",
    },
    {
        value: "Uganda",
        name: "Uganda",
    },
    {
        value: "Zambia",
        name: "Zambia",
    },
    {
        value: "Zimbabwe",
        name: "Zimbabwe",
    },
]