<template>
  <div id="app">
    <notifications/>
    <router-view/>
    <GalleryLightbox/>
  </div>
</template>
<script>
import GalleryLightbox from "@/components/Gallery/GalleryLightbox";
export default {
  components: { GalleryLightbox},
}
</script>