<template>
  <div class="w-full relative" v-if="showField">
    <InputText
      :showLabel="false"
      :placeholder="placeholder"
      :name="name"
      class="w-full"
      @blur="$emit('input', textInput, index)"
      v-model="textInput"
    />
    <button @click="$emit('removeItem')" type="button" class="button">
      <i class="bx bx-trash"></i>
    </button>
  </div>
</template>
<script>
import InputText from "@/components/Inputs/InputText";
export default {
  name: "ButtonInputText",
  components: {
    InputText,
  },
  props: {
    showField: {
      type: Boolean,
      default: true,
    },
    index: {
      type: Number,
    },
    placeholder: {
      type: String,
      default: "Placeholder text",
    },
    name: {
      type: String,
      default: "name",
    },

    text: {
      type: String,
    },
  },
  data() {
    return {
      textInput: this.text,
    };
  },
};
</script>
<style scoped>
.button {
  @apply bg-red-light focus:outline-none hover:bg-red-lighter;
  @apply duration-300 transition-all px-4 py-1 sm:py-2 rounded text-red-text;
  @apply ml-4 absolute top-1/2 right-1.5 transform -translate-y-1/2;
  @apply text-base;
}
</style>