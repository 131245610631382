var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-horizontal",attrs:{"id":_vm.experienceId}},[_c('figure',{staticClass:"card-img-container"},[(_vm.imagePath)?_c('img',{staticClass:"card-img",attrs:{"src":_vm.imagePath,"alt":""}}):_c('img',{staticClass:"card-img",attrs:{"src":require("@/assets/img/placeholder.png"),"alt":"Placeholder"}})]),_c('div',{staticClass:"card-text-container",attrs:{"id":"text-container"}},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"flex items-center"},[_c('i',{staticClass:"bx bxs-map-pin card-icon"}),_c('p',{staticClass:"card-location"},[_vm._v(_vm._s(_vm.location))])]),(_vm.showAddConfirmation)?_c('div',{staticClass:"btn-container"},[_vm._m(0),_c('button',{staticClass:"btn-remove",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeObj({
            id: _vm.experienceId,
            title: _vm.title,
            location: _vm.location,
            photo: _vm.imgPath,
          })}}},[_c('i',{staticClass:"bx bx-trash"})])]):_c('ButtonFrameless',{staticClass:"btn-add",attrs:{"text":_vm.actionText},nativeOn:{"click":function($event){return _vm.emitObj({
          type: 'experience',
          id: _vm.experienceId,
          title: _vm.title,
          location: _vm.location,
          photo: _vm.imgPath,
        })}}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"confirmation-tag"},[_c('i',{staticClass:"bx bx-check text-base"}),_c('span',[_vm._v("Added")])])}]

export { render, staticRenderFns }