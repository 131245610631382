<template>
  <div id="configurator">
    <div class="h-full flex justify-between w-full">
      <div id="days" class="itinerary-block">
        <div class="w-full">
          <ExperienceBlock
              :class="day.class"
              v-for="(day, index) in days"
              :key="index"
              :name="'day-' + (index + 1)"
              :active="day.active"
              :day="index + 1"
              :currentDate="day.date"
              :currentFullDate="day.fullDate"
              :currentDay="index + 1"
              :amountOfDays="days.length"
              :showExperiences="day.showExperiences"
              :showEditor="day.showEditor"
              :showSummary="day.showSummary"
              :showTitle="day.showTitle"
              :savedTitle="day.title"
              :showPlaceholder="day.showPlaceholder"
              :showEmptyState="day.showEmptyState"
              :disAttribute="day.disAttribute"
              :experiences="day.experiences"
              :previousExperiencesState="day.previousExperiencesState"
              :showPlaceHolderExperiences="day.showPlaceHolderExperiences"
              :placeholderExperiences="day.placeholderExperiences"
              :index="index"
              @blockAction="openBlock(index)"
              @saveBlock="saveBlock"
              @cancelBlock="cancelBlock"
              @removeExperience="removeExperience"
              @createText="addTextExperience"
              @editSummary="openBlock(index)"
              @removeSummary="removeBlock(index)"
              @openSheet="emitOpenSheet(day.id)"
          />
        </div>
      </div>
    </div>
    <div v-show="false" class="fixed z-40 bg-white w-full h-full">
      <ExperienceList
          @createExperience="$emit('createExperience')"
          @cardObj="addTextExperience"
          @createText="addTextExperience"
          :showEmptyState="false"
          :experiences="experiences"
      />
    </div>
  </div>
</template>
<script>
import ExperienceBlock from "@/components/Offer/Itinerary/ExperienceBlock";
import ExperienceList from "@/components/Offer/Itinerary/ExperienceList";
import {mapActions, mapGetters} from "vuex";
import {getNumDaysFromString, generateDays} from "@/shared/dateHandler";

export default {
  name: "ExperiencesConfigurator",
  components: {
    ExperienceBlock,
    ExperienceList,
  },
  props: {
    alreadyHasItinerary: {
      type: Boolean,
      default: false,
    },
    dates: {
      type: String,
      required: true
    },
    destination: {
      type: String,
      required: true
    },
    selectedItinerary: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      //Dummy data
      /*Active property to be set as property value,
      to later know which day block is currently active*/
      showListEmptyState: true,
      days: [],
      textId: 9999999,
    };
  },

  methods: {
    ...mapActions({
      addExperienceToSelectedDayID: 'experiences/addExperienceToSelectedDayID',
      removeExperienceFromSelectedDayIDUsingExperienceID: 'experiences/removeExperienceFromSelectedDayIDUsingExperienceID',
      removeAllUnsavedItemsFromSelectedItineraryDayID: 'experiences/removeAllUnsavedItemsFromSelectedItineraryDayID',
      removeAllExperienceFromSelectedItineraryDayID: 'experiences/removeAllExperienceFromSelectedItineraryDayID',
      addSavedToAllItemsForSelectedItineraryDayID: 'experiences/addSavedToAllItemsForSelectedItineraryDayID',
      addRemovedToExperienceItemForSelectedItineraryDayID: 'experiences/addRemovedToExperienceItemForSelectedItineraryDayID',
      mountSavedExperienceItems: 'experiences/mountSavedExperienceItems',
      getExperiences: 'experiences/getExperiences',
      removeAllExperiences: 'experiences/removeAllExperiences'
    }),
    getTotalDays() {
      return getNumDaysFromString(this.dates);
    },

    generateDays() {
      this.days = generateDays(this.dates);
    },

    checkIfExperienceIsAdded(experienceArr, currentExperience) {
      //Check if experience already is added
      let check = experienceArr.some(
          (experience) => experience.id == currentExperience.id
      );
      return check;
    },
    toggleExperienceCheckmark(experienceArr, currentExperience) {
      for (let experience of experienceArr) {
        if (experience.id == currentExperience.id) {
          experience.showAddConfirmation = !experience.showAddConfirmation;
          experience.showPositiveAction = !experience.showPositiveAction;
        }
      }
    },

    toggleExperienceCardButtons(day) {
      for (let experience of this.experiences) {
        //Check if experience already is added
        let check = this.checkIfExperienceIsAdded(day.experiences, experience);

        if (check == false) {
          experience.showAddConfirmation = false;
          experience.showPositiveAction = true;
        } else {
          experience.showAddConfirmation = true;
          experience.showPositiveAction = false;
        }
      }
    },

    //Get experience to be removed
    removeExperience(experienceID) {
      this.addRemovedToExperienceItemForSelectedItineraryDayID(experienceID);
    },
    addTextExperience() {
      this.textId = this.textId + 1;
      let id = this.textId;
      let obj = {
        type: "text",
        id: id,
        title: "",
        location: "",
        photo: "",
        value: "",
      };

      this.addExperienceToSelectedDayID(obj)

      //Toggle checkmark and call to action after adding or removing experience
      this.toggleExperienceCheckmark(this.experiences, obj);
    },
    toggleBlockEmptyState(day) {
      day.experiences.length > 0
          ? ((day.showEmptyState = false), (day.showExperiences = true)) //Don't show empty state on relevant block, if experiences array isn't empty
          : ((day.showEmptyState = true), (day.showExperiences = false)); //Show empty state on relevant block, if experiences is empty
    },

    //Open current block and disable all other blocks
    openBlock(index) {
      this.showListEmptyState = false; //Hide experience list's empty state
      for (let [i, day] of this.days.entries()) {
        if (i != index) {
          day.class = "mb-3 opacity-50"; //Diminuish opacity on not relevant blocks
          day.disAttribute = true; //Disable call to action on not relevant blocks
        } else {
          day.showPlaceholder = false; //Hide block placeholder
          day.showSummary = false; //Hide summary block
          day.showEditor = true; //Reveal contents of block
          day.showTitle = true; //Show title
          this.toggleBlockEmptyState(day); //Toggle empty state and experiences
          day.active = true; //Flag block as active
          this.toggleExperienceCardButtons(day);
        }
      }
    },

    //Save current block and enable all other blocks again
    saveBlock(title, experiences, currentFullDate, index) {
      this.showListEmptyState = !this.showListEmptyState; //Reveal experience list's empty state
      this.addSavedToAllItemsForSelectedItineraryDayID();
      for (let [i, day] of this.days.entries()) {
        day.disAttribute = false;
        day.active = false;
        day.showEmptyState = false;
        day.showEditor = false;
        day.showExperiences = false;
        day.showTitle = false; //Show title
        if (i == index) {
          //Check if block being closed has experiences
          if (day.experiences.length > 0) {
            let experiences = "";
            //Add experiences as text in placholder
            for (let [a, experience] of day.experiences.entries()) {
              let comma = a + 1 == day.experiences.length ? "" : ", ";
              experiences += `${experience.title}${comma}`;
              day.placeholderExperiences = experiences;
            }
            day.showPlaceholder = false; //Deactivate placeholder tile
            day.showSummary = true; //Showsummary tile
          } else {
            //Activate block placeholder
            day.showPlaceholder = true;
          }
        }
        day.class = "mb-3";
      }
      this.$emit("addItinerary", {
        id: index,
        fullDate: currentFullDate,
        title: title,
        experiences: experiences,
      });

    },
    cancelBlock() {
      this.showListEmptyState = !this.showListEmptyState; //Reveal experience list's empty state

      //clear all selected experiences
      this.removeAllUnsavedItemsFromSelectedItineraryDayID();

      for (let day of this.days) {
        day.class = "mb-3";
        day.disAttribute = false;
        day.active = false;
        day.showEmptyState = false;
        day.showEditor = false;
        day.showExperiences = false;
        day.showTitle = false; //Show title
        if (day.experiences.length > 0) {
          day.showSummary = true;
        } else {
          day.showPlaceholder = true;
        }
      }
    },
    removeBlock(index) {
      let day = this.days[index];
      day.dayTitle = "";
      day.title = "";
      day.experiences = []; //Remove all experiences
      day.previousExperiencesState = []; //Remove all previous experiences
      day.showSummary = false;
      day.showPlaceholder = true;
      this.$emit("removeItinerary", index);
      this.removeAllExperienceFromSelectedItineraryDayID();
    },
    mountExistingBlock() {
      try {
        if ((typeof this.selectedItinerary) === 'string') {
          this.selectedItinerary = JSON.parse(this.selectedItinerary);
        }
        this.selectedItinerary.forEach((tripItinerary) => {
          let getIndex = this.days.findIndex((x) => x.id === tripItinerary.id);
          let day = this.days[getIndex];
          day.class = "mb-6";
          day.disAttribute = false;
          day.showPlaceholder = true;
          day.showEmptyState = false;
          day.showEditor = false;
          day.showExperiences = false;
          day.showTitle = false;
          day.title = tripItinerary.title;
          day.experiences = [];

          //this is to change all trip itinerary to experiences
          if (tripItinerary.itinerary && tripItinerary.itinerary.length > 0) {
            tripItinerary.experiences = tripItinerary.itinerary;
          } else {
            day.showPlaceholder = true;
          }
          let experienceTitles = []
          tripItinerary.experiences.forEach((experience, i) => {

            let data = {}, exp = {};
            if (experience.photo == "" && experience.location == "") {
              exp = {
                id: experience.id || i,
                title: experience.name,
                location: experience.location,
                photo: experience.photo,
                extras: experience.extras,
                saved: true,
                type: "text",
              }
              // day.experiences.push();
            } else {
              exp = {
                id: experience.id || i,
                title: experience.name,
                location: experience.location,
                photo: experience.photo,
                extras: experience.extras,
                type: "experience",
                saved: true
              };
            }
            data = {
              experience: exp,
              dayID: day.id
            }

            this.mountSavedExperienceItems(data)
            this.toggleExperienceCheckmark(this.experiences, exp);
            day.experiences.push(exp);
            experienceTitles.push(experience.title)
            day.showPlaceholder = false; //Deactivate placeholder tile
            day.showSummary = true; //Show summary tile

          });
          if (experienceTitles.length > 0) {
            day.placeholderExperiences = experienceTitles.toString();
          }

        });
      } catch (error) {
        this.$emit("reset");
      }
    },
    emitOpenSheet(selectedExperienceId) {
      this.$emit("openSheet", selectedExperienceId);
    },
  },
  mounted() {
    this.generateDays();
    this.getExperiences(this.destination);
    this.mountExistingBlock();
  },

  computed: {
    ...mapGetters({
      selectedExperiences: 'experiences/selectedExperiences',
      experiences: 'experiences/experiences'
    }),
  },
  watch: {
    selectedExperiences: {
      handler: function (newValue) {
        for (const [key, value] of Object.entries(newValue)) {
          // let getIndex = this.days.findIndex(day => day.id === key);
          this.days[key].showExperiences = true; //Activate experiences block
          this.days[key].showEmptyState = false; //Hide empty state
          this.days[key].experiences = value
        }
      },
      deep: true
    },
    dates() {
      //on date change recreate the nights
      this.$emit("reset");
      this.generateDays();
    },
    destination(newVal) {
      //this is tpo handle changes in experience
      this.getExperiences(newVal);
    },
  },
  activated() {
    this.getExperiences(this.destination);
  },
  destroyed() {
    this.removeAllExperiences();
  }
};
</script>
<style scoped>
#configurator {
  @apply w-full;
}

.itinerary-block {
  @apply w-full h-full;
}
</style>