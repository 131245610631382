import api from "../../api"

export default {
    namespaced: true,
    state: {
        experiences: [],
        showExperienceSheet: false,
        showExperienceListSheet: false,
        selectedItineraryDayId: 0,
        selectedExperiences: {},
        isSubmittingData: false,
        submissionStatusMessage: '',
        submissionStatus: '',
        newExperience: {}
    },
    getters: {
        experiences(state) {
            return state.experiences
        },
        showExperienceSheetState(state) {
            return state.showExperienceSheet
        },
        showExperienceListSheetState(state) {
            return state.showExperienceSheet
        },

        selectedItineraryDayId(state) {
            return state.selectedItineraryDayId;
        },
        selectedExperiences(state) {

            return state.selectedExperiences;
        },
        selectedExperienceIDsForSelectedDay(state) {
            let selectedExperiencesForSelectedDay = []
            if (state.selectedExperiences[state.selectedItineraryDayId]) {
                //creating an array with only the ids
                selectedExperiencesForSelectedDay = state.selectedExperiences[state.selectedItineraryDayId].map(el => el.id);
            }
            return selectedExperiencesForSelectedDay;
        },
        isSubmittingData(state) {
            return state.isSubmittingData;
        },
        submissionStatus(state) {
            return state.submissionStatus;
        },
        submissionStatusMessage(state) {
            return state.submissionStatusMessage;
        },
        newExperience(state){
            return state.newExperience;
        }


    },
    mutations: {
        ADD_EXPERIENCE_ITEM_TO_SELECTED_ITINERARY_DAY_ID(state, value) {
            let selectedStateData = {...state.selectedExperiences}
            if (state.selectedItineraryDayId > -1) {
                if (selectedStateData[state.selectedItineraryDayId] === undefined) {
                    selectedStateData[state.selectedItineraryDayId] = [];
                }

                let indexExisting = selectedStateData[state.selectedItineraryDayId].findIndex(e => e.id === value.id);
                if (indexExisting < 0) {
                    selectedStateData[state.selectedItineraryDayId].push(value)
                }
            }
            state.selectedExperiences = selectedStateData
        },
        ADD_SAVED_TO_ALL_EXPERIENCE_ITEMS_FOR_SELECTED_ITINERARY_DAY_ID(state) {
            let selectedStateData = {...state.selectedExperiences}
            if (state.selectedItineraryDayId > -1) {
                if (selectedStateData[state.selectedItineraryDayId] !== undefined) {
                    selectedStateData[state.selectedItineraryDayId] = selectedStateData[state.selectedItineraryDayId].map(e => {
                        e.saved = true;
                        return e
                    });
                }
            }
            state.selectedExperiences = selectedStateData

        },
        ADD_REMOVED_TO_EXPERIENCE_ITEM_FOR_SELECTED_ITINERARY_DAY_ID(state, value) {
            let selectedStateData = {...state.selectedExperiences}
            if (state.selectedItineraryDayId > -1) {
                let indexExisting = selectedStateData[state.selectedItineraryDayId].findIndex(e => e.id === value);
                if (indexExisting > -1) {
                    selectedStateData[state.selectedItineraryDayId][indexExisting].removed = true;
                }
            }
            state.selectedExperiences = selectedStateData
        },
        MOUNT_SAVED_EXPERIENCE_ITEMS_TO_SELECTED_ITINERARY(state, data) {

            let dayID = data.dayID;
            let experience = data.experience;
            let selectedStateData = {...state.selectedExperiences}
            if (dayID > -1) {
                if (selectedStateData[dayID] === undefined) {
                    selectedStateData[dayID] = [];
                }
                let indexExisting = selectedStateData[dayID].findIndex(e => e.id === experience.id);
                if (indexExisting < 0) {
                    selectedStateData[dayID].push(experience)
                }
            }

            state.selectedExperiences = selectedStateData
        },
        REMOVE_ALL_UNSAVED_FROM_EXPERIENCE_ITEMS_FOR_SELECTED_ITINERARY_DAY_ID(state) {
            let selectedStateData = {...state.selectedExperiences}
            if (state.selectedItineraryDayId > -1) {

                let validItems = [];


                if (selectedStateData[state.selectedItineraryDayId]) {
                    selectedStateData[state.selectedItineraryDayId].forEach(item => {
                        if (item.saved) {
                            validItems.push(item)
                        }
                    })
                }


                selectedStateData[state.selectedItineraryDayId] = validItems;
            }
            state.selectedExperiences = selectedStateData

        },
        REMOVE_ALL_REMOVED_ITEMS_FROM_EXPERIENCE_ITEMS_FOR_SELECTED_ITINERARY_DAY_ID(state) {
            let selectedStateData = {...state.selectedExperiences}
            if (state.selectedItineraryDayId > -1) {
                let validItems = [];
                if (selectedStateData[state.selectedItineraryDayId]) {
                    selectedStateData[state.selectedItineraryDayId].forEach(item => {
                        if (!item.removed) {
                            validItems.push(item)
                        }
                    })
                }
                selectedStateData[state.selectedItineraryDayId] = validItems;
            }
            state.selectedExperiences = selectedStateData

        },
        SET_EXPERIENCES(state, value) {
            state.experiences = value;
        },
        SET_SHOW_EXPERIENCE_SHEET(state, value) {
            state.showExperienceSheet = value;
        },
        SET_SHOW_EXPERIENCE_LIST_SHEET(state, value) {
            state.showExperienceSheet = value;
        },
        SET_SELECTED_ITINERARY_DAY_ID(state, value) {
            state.selectedItineraryDayId = value;
        },
        REMOVE_EXPERIENCE_ITEM_FROM_SELECTED_ITINERARY_ID_USING_EXPERIENCE_ID(state, value) {

            let selectedStateData = {...state.selectedExperiences}

            if (state.selectedItineraryDayId > -1) {
                let indexExisting = selectedStateData[state.selectedItineraryDayId].findIndex(e => e.id === value);
                if (indexExisting > -1) {
                    selectedStateData[state.selectedItineraryDayId].splice(indexExisting, 1)
                }
            }
            state.selectedExperiences = selectedStateData
        },
        REMOVE_ALL_SELECTED_EXPERIENCES_FROM_SELECTED_ITINERARY_DAY_ID(state) {
            let selectedStateData = {...state.selectedExperiences}
            if (state.selectedItineraryDayId > -1) {
                if (selectedStateData[state.selectedItineraryDayId]) {
                    selectedStateData[state.selectedItineraryDayId] = []
                }
            }
            state.selectedExperiences = selectedStateData
        },
        REMOVE_ALL_SELECTED_EXPERIENCES(state) {

            state.selectedExperiences = {}
        },
        SET_IS_SUBMITTING_DATA(state, value) {
            state.isSubmittingData = value;
        },
        SET_SUBMISSION_STATUS(state, value) {
            state.submissionStatus = value;
        },
        SET_SUBMISSION_STATUS_MESSAGE(state, value) {
            state.submissionStatusMessage = value;
        },
        SET_NEW_EXPERIENCE(state,value){
            state.newExperience= value;
        }
    },
    actions: {
        async getExperiences({commit}, destination) {
            if (destination) {
                api.get(`/get-experiences/${destination.toLowerCase()}`).then(({data}) => {
                    let parsedExperienceData = [];
                    data.experiences.forEach((experience) => {
                        parsedExperienceData.push({
                            id: experience.id,
                            title: experience.title,
                            subtitle: experience.subtitle,
                            photo: experience.image,
                            location: experience.country,
                            showPositiveAction: true,
                            showAddConfirmation: false,
                        });
                    });
                    commit('SET_EXPERIENCES', parsedExperienceData)
                })
            }

        },

        setSelectedItineraryDayId({commit}, showSheet) {
            commit('SET_SELECTED_ITINERARY_DAY_ID', showSheet)
        },
        addExperienceToSelectedDayID({commit}, experience) {
            commit('ADD_EXPERIENCE_ITEM_TO_SELECTED_ITINERARY_DAY_ID', experience)
        },
        addSavedToAllItemsForSelectedItineraryDayID({commit}) {
            commit('REMOVE_ALL_REMOVED_ITEMS_FROM_EXPERIENCE_ITEMS_FOR_SELECTED_ITINERARY_DAY_ID')
            commit('ADD_SAVED_TO_ALL_EXPERIENCE_ITEMS_FOR_SELECTED_ITINERARY_DAY_ID')
        },
        addRemovedToExperienceItemForSelectedItineraryDayID({commit}, experienceID) {
            commit('ADD_REMOVED_TO_EXPERIENCE_ITEM_FOR_SELECTED_ITINERARY_DAY_ID', experienceID)
        },
        mountSavedExperienceItems({commit}, data) {
            commit('MOUNT_SAVED_EXPERIENCE_ITEMS_TO_SELECTED_ITINERARY', data)
        },
        removeAllUnsavedItemsFromSelectedItineraryDayID({commit}) {
            commit('REMOVE_ALL_UNSAVED_FROM_EXPERIENCE_ITEMS_FOR_SELECTED_ITINERARY_DAY_ID')
        },
        removeExperienceFromSelectedDayID({commit}, experience) {
            commit('REMOVE_EXPERIENCE_ITEM_FROM_SELECTED_ITINERARY_ID_USING_EXPERIENCE_ID', experience.id)
        },
        removeAllExperienceFromSelectedItineraryDayID({commit}) {
            commit('REMOVE_ALL_SELECTED_EXPERIENCES_FROM_SELECTED_ITINERARY_DAY_ID')
        },
        removeExperienceFromSelectedDayIDUsingExperienceID({commit}, experienceID) {
            commit('REMOVE_EXPERIENCE_ITEM_FROM_SELECTED_ITINERARY_ID_USING_EXPERIENCE_ID', experienceID)
        },
        setShowExperienceSheet({commit}, showSheet) {
            commit('SET_SHOW_EXPERIENCE_SHEET', showSheet)
        },
        setShowExperienceListSheet({commit}, showSheet) {
            commit('SET_SHOW_EXPERIENCE_LIST_SHEET', showSheet)
        },
        removeAllExperiences({commit}) {
            commit('REMOVE_ALL_SELECTED_EXPERIENCES')
        },
        addNewExperience({commit}, formData) {

           api.post("add-new-experience", formData, {
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                })
                .then(({ data }) => {
                    commit('SET_IS_SUBMITTING_DATA', false);
                    commit('SET_NEW_EXPERIENCE', data.experience);
                    commit('SET_SUBMISSION_STATUS', data.response);
                    commit('SET_SUBMISSION_STATUS_MESSAGE', data.message);

                })
                .catch((error) => {
                    commit('SET_IS_SUBMITTING_DATA', false);
                    commit('SET_SUBMISSION_STATUS', 'failed');
                    commit('SET_SUBMISSION_STATUS_MESSAGE', error.response.data.message);
                });
        }
    }
}