<template>
  <label class="w-full mb-6" :for="name">
    <span class="area-label">{{ label }}</span>
    <textarea
      class="area"
      :class="[showErrMsg ? 'border-red-base' : 'border-grey-dark']"
      :name="name"
      id="name"
      :placeholder="placeholder"
      @blur="$emit('blur')"
      @input="emitMethods"
      v-model="textInput"
    ></textarea>
    <span
      v-if="showErrMsg"
      class="mt-2 font-sans text-sm font-medium error-msg text-red-base"
    >
      {{ errorMsg }}
    </span>
  </label>
</template>
<script>
export default {
  name: "TextArea",
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
    },
    name: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    showErrMsg: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      textInput: "",
    };
  },
  methods: {
    emitMethods() {
      this.$emit("input", this.textInput);
    },
  },
  watch: {
    value(newVal) {
      this.textInput = newVal;
    },
  },
};
</script>
<style scoped>
.area {
  @apply border block w-full resize-none rounded h-32;
  @apply focus:border-teal-base focus:ring-teal-lighter focus:ring-2;
  @apply border-grey-dark text-black-base placeholder-black-lightest;
  @apply text-base;
}
.area-label {
  @apply block mb-1.5 sm:mb-3 text-sm sm:text-base font-medium;
}
</style>
