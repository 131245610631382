<template>
  <div>
    <div class="flex items-center">
      <textarea
          v-model="block.title"
          class="input"
          placeholder="e.g. afternoon at your leisure"
          type="text"
      ></textarea>
      <button type="button" @click="$emit('deleteBlock', block.id)">
        <i class="bx bx-trash text-lg text-red-base"></i>
      </button>
    </div>
    <Divider/>
  </div>
</template>
<script>
import Divider from "@/components/Layout/Divider";

export default {
  name: "TextBlock",
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  components: {
    Divider,
  },
};
</script>
<style scoped>
.input {
  @apply w-full h-32 border-none focus:outline-none outline-none focus:ring-0 text-base placeholder-black-lightest;
  @apply pl-0 pr-4 mb-8;
}
</style>
