<template>
  <div class="block">
    <div class="flex w-full">
      <div class="card">
        <figure class="thumbnail">
          <img class="thumbnail-img" :src="imagePath" alt="" />
        </figure>
        <div class="experience-details">
          <h3 class="experience-title">
            {{ block.title }}
          </h3>
          <div class="experience-location">
            <i class="bx bxs-map-pin mr-1"></i>
            <span class="text-black-lightest text-sm">{{
              block.location
            }}</span>
          </div>
        </div>
      </div>

      <button
        class="btn-delete"
        type="button"
        @click="$emit('deleteBlock', block.id)"
      >
        <i class="bx bx-trash text-lg text-red-base"></i>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "Experience",
  props: {
    block: {
      type: Object,
    },
  },
  computed: {
    basePath() {
      return process.env.VUE_APP_FILE_BASE_PATH;
    },
    imagePath() {
      if (this.block.photo) {
        return `${this.basePath}${this.block.photo.split(",")[0]}`;
      } else {
        return "";
      }
    },
  },
};
</script>
<style scoped>
.block {
  @apply flex w-full items-center my-8 border-b border-grey-base pb-8;
}

.card {
  @apply flex items-center w-full;
}

.thumbnail {
  @apply h-10 min-w-10 sm:h-16 sm:min-w-16 rounded overflow-hidden relative mr-2;
}

.thumbnail-img {
  @apply absolute w-full h-full top-0 right-0 object-cover;
}

.experience-details {
  @apply font-medium text-sm sm:text-base;
}
.experience-title {
  @apply truncate flex;
}

.btn-delete {
  @apply ml-auto;
}

.experience-location {
  @apply text-black-lightest sm:text-sm font-normal;
}

.experience-location span {
  @apply truncate;
}
</style>