<template>
  <div class="tag">
    <div class="text-xs">{{ number }} · {{ title }}</div>
  </div>
</template>
<script>
export default {
  name: "ExperienceTag",
  props: {
    number: {
      type: Number,
      default: 1,
    },
    title: {
      type: String,
      default: "Experience",
    },
  },
};
</script>
<style scoped>
.tag {
  @apply px-2.5 py-1.5 rounded-xl bg-yellow-light text-yellow-text font-medium inline-flex items-center max-w-full;
}

.tag div{
  @apply break-words max-w-full;
  -ms-hyphens:auto;
  -webkit-hyphens:auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
</style>