<template>
  <div class="empty-state">
    <div v-if="showIcon" class="empty-state-wrapper">
      <i :class="['bx', iconClass]"></i>
    </div>
    <h2 class="empty-state-title">
      {{ title }}
    </h2>
    <p class="empty-state-text">
      {{ text }}
    </p>
    <ButtonFrameless
      class="btn-fl"
      v-if="useSecondaryAction"
      :text="secondaryAction"
    />
    <ButtonPrimary
      :class="['btn-pm', useSecondaryAction == false ? 'mt-6' : 'mt-2']"
      @click.native="$emit('primaryAction')"
      v-if="usePrimaryAction"
      :text="primaryAction"
    />
  </div>
</template>
<script>
import ButtonFrameless from "@/components/Buttons/ButtonFrameless";
import ButtonPrimary from "@/components/Buttons/ButtonPrimary";
export default {
  name: "EmptyState",
  components: {
    ButtonFrameless,
    ButtonPrimary,
  },
  props: {
    iconClass: {
      type: String,
      default: "bxs-hourglass",
    },
    title: {
      type: String,
      default: "Title",
    },
    text: {
      type: String,
      default: "Body text",
    },
    primaryAction: {
      type: String,
      default: "Primary Action",
    },
    secondaryAction: {
      type: String,
      default: "Secondary Action",
    },
    usePrimaryAction: {
      type: Boolean,
      default: false,
    },
    useSecondaryAction: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style scoped>
.empty-state {
  @apply border border-grey-base rounded-lg p-6 sm:p-8 bg-white;
}
.empty-state-wrapper {
  @apply p-8 h-32 w-32 text-teal-base text-3xl bg-teal-lighter rounded-full;
  @apply relative left-1/2 transform -translate-x-1/2;
  @apply flex items-center justify-center;
}
.empty-state-title {
  @apply font-sans text-center font-bold text-black-base text-base sm:text-lg mt-4;
}

.empty-state-text {
  @apply text-black-light text-center block;
  @apply text-sm sm:text-base;
}

.btn-fl {
  @apply mt-2 relative transform left-1/2 -translate-x-1/2;
}
.btn-pm {
  @apply px-8 relative transform left-1/2 -translate-x-1/2 block;
}
</style>