import DeviceDetector from "device-detector-js";
import api from "@/api"
import dayjs from "dayjs";

const deviceDetector = new DeviceDetector();
const userAgent = navigator.userAgent;
const device = deviceDetector.parse(userAgent);

const defaultState = () => ({
    client: {
        type: device.client.type,
        name: device.client.name,
        engine: device.client.engine,
        engineVersion: device.client.engineVersion,
        version: device.client.version,
    },
    device: {
        type: device.device.type,
        brand: device.device.brand,
        model: device.device.model,
    },
    os: {
        name: device.os.name,
        version: device.os.version,
        platform: device.os.platform,
    },
    time: {
        offerCreation: {
            start: "",
            end: "",
            duration: "",
            set: false,
            requestType:'create',
        },
        booking: {
            start: "",
            end: "",
        },
        inquiry: {
            start: "",
            end: ""
        }
    },
    page: {
        name: "",
        path: "",
    },
});

export default {
    namespaced: true,
    state: defaultState(),
    getters: {
        client: (state) => state.client,
        device: (state) => state.device,
        os: (state) => state.os,
        time: (state) => state.time,
        page: (state) => state.page,
        state: (state) => state,
    },
    mutations: {
        SET_PAGE: (state, payload) => {
            state.page.name = payload.name;
            state.page.path = payload.path;
        },
        SET_BOOKING_START: (state, payload) => {
            state.time.booking.start = payload;
        },
        SET_BOOKING_END: (state, payload) => {
            state.time.booking.end = payload;
        },
        SET_OFFER_REQUEST_START: (state, payload) => {
            state.time.offerCreation.start = payload;
        },
        SET_OFFER_REQUEST: (state, payload) => {
            state.time.offerCreation.set = payload;
        },
        SET_OFFER_REQUEST_END: (state, payload) => {
            state.time.offerCreation.end = payload;
        },
        SET_OFFER_REQUEST_DURATION: (state, payload) => {
            state.time.offerCreation.duration = payload;
        }
    },
    actions: {
        async recordPageView(getters) {
            const state = getters.state;
            let userId = await api.getUserProfile();
            userId = userId === "Unauthorized" ? "Unknown" : userId.data.user.id;

            let data = {
                page: state.page.name,
                path: state.page.path,
                client_type: state.client.type,
                client_name: state.client.name,
                client_engine: state.client.engine,
                client_engine_version: state.client.engineVersion,
                client_version: state.client.version,
                device_type: state.device.type,
                device_brand: state.device.brand,
                device_model: state.device.model,
                os_name: state.os.name,
                os_version: state.os.version,
                os_platform: state.os.platform,
                user_id: userId
            }
            await api.recordPageView(data);
        },
        async setPage({commit}, payload) {
            await commit("SET_PAGE", payload);
        },
        async startOfferCreationTracking({commit}) {
            let start = new Date(); //.format("hh:mm:ss");
            await commit("SET_OFFER_REQUEST_START", start);
            await commit("SET_OFFER_REQUEST", true);
        },
        async endOfferCreationTracking({commit, state}) {
            let end = new Date();
            commit("SET_OFFER_REQUEST_END", end);
            let duration = dayjs(state.time.offerCreation.end).diff(dayjs(state.time.offerCreation.start));
            await commit("SET_OFFER_REQUEST_DURATION",duration);

        },
        async resetOfferCreationTracking({commit}) {
            await commit("SET_OFFER_REQUEST_START", "");
            await commit("SET_OFFER_REQUEST_END", "");
            await commit("SET_OFFER_REQUEST_DURATION", "");
            await commit("SET_OFFER_REQUEST", false);
        },
    },
};
