<template>
  <div class="h-full">

    <div v-if="showEmptyState" class="empty-state w-full p-6 rounded-md">
      <figure class="relative w-full overflow-hidden h-40 block">
        <img
          class="absolute object-contain h-full w-full"
          src="@/assets/img/empty-states/no-activities.svg"
          alt="Empty state illustration"
        />
      </figure>
      <h3
        class="font-sans text-black-base text-xl font-semibold text-center mt-6"
      >
        Start adding experiences
      </h3>
      <p class="font-sans text-black-lighter text-center">
        Click on a day in the left column to start adding experiences.
      </p>
    </div>
    <div v-if="!showEmptyState">
      <div class="mb-6 h-24 pr-1">
        <InputText
          placeholder="e.g. Hiking in Kilimandjaro"
          :showLabel="false"
          class="search-field"
          v-model="searchWord"
        />
        <div class="btn-container">
          <ButtonSecondary
            @click.native="emitText"
            class="px-3 h-13 w-calc-1/6 ml-auto mr-2"
            text="Add text"
          />
          <ButtonPrimary
            @click.native="$emit('createExperience')"
            class="px-3 h-13"
            text="Create an experience"
          />
        </div>
      </div>
      <div v-if="false" id="filter" class="mb-3">
        <Chip text="Experiences" class="mr-3" />
        <Chip text="Meals" class="mr-3" />
        <Chip text="Transportation" />
      </div>
    </div>
    <div v-if="!showEmptyState" class="overflow-auto h-full mt-3 pb-32">
      <div v-if="searchResult">
        <CardHorizontal
          v-for="(experience, i) in searchResult"
          :key="i"
          class="mb-6"
          :experienceId="experience.id"
          :title="experience.title"
          :imgPath="experience.photo"
          :location="experience.location"
          :showAddConfirmation="experience.showAddConfirmation"
          :showPositiveAction="experience.showPositiveAction"
          @cardObj="showObj"
        />
      </div>

      <div
        v-if="searchResult.length < 1"
        class="empty-state w-full p-6 rounded-md"
      >
        <figure class="relative w-full overflow-hidden h-40 block">
          <img
            class="absolute object-contain h-full w-full"
            src="@/assets/img/empty-states/no-activities.svg"
            alt="Empty state illustration"
          />
        </figure>
        <h3
          class="
            font-sans
            text-black-base text-xl
            font-semibold
            text-center
            mt-6
          "
        >
          There is no experience with the name {{ searchWord }}
        </h3>
        <p class="font-sans text-black-lighter text-center">
          Click on create an experience to add one
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import ButtonPrimary from "@/components/Buttons/ButtonPrimary";
import ButtonSecondary from "@/components/Buttons/ButtonSecondary";
import CardHorizontal from "@/components/Cards/CardHorizontal";
import InputText from "@/components/Inputs/InputText";
export default {
  name: "ExperienceList",
  components: {
    ButtonPrimary,
    ButtonSecondary,
    CardHorizontal,
    InputText,
  },
  data() {
    return {
      searchWord: "",
      searchResult: [],
      textId: 9999999999,
    };
  },
  props: {
    showEmptyState: {
      type: Boolean,
      default: true,
    },
    experiences: {
      type: Array,
    },
  },
  methods: {
    showObj(obj) {
      this.$emit("cardObj", obj);
    },
    emitText() {
      this.textId = this.textId + 1;
      let id = this.textId;
      this.$emit("createText", {
        type: "text",
        id: id,
        title: "",
        location: "",
        photo: "",
      });
    },
    search() {},
  },
  watch: {
    searchWord(newValue) {
      this.searchResult = [];

      if (newValue == "") {
        this.searchResult = this.experiences;
      }

      this.searchResult = this.experiences.filter((element) => {
        return element.title.toLowerCase().includes(newValue.toLowerCase());
      });
    },
    experiences() {
      this.searchResult = this.experiences;
    },
  },
};
</script>
<style scoped>
.search-field {
  @apply w-full;
}

.btn-container {
  @apply flex items-center h-13 mt-2;
}
</style>