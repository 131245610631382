<template>
  <div class="block-container">
    <div class="block-content-container">
      <div class="block-upper-part">
        <div class="button-container">
          <ButtonPill
            @click.native="$emit('delete')"
            iconClass="bx bx-trash"
            text="Delete"
          />
          <ButtonPill @click.native="$emit('edit')" text="Edit" />
        </div>
        <div class="header">
          <div class="w-full">
            <h4 class="title">
              {{ hotelName }}
              <div class="stars text-sm ml-3" v-if="false">
                <i
                  v-for="(star, index) in stars"
                  :key="index"
                  :class="star"
                ></i>
              </div>
            </h4>

            <p class="location">
              <i class="bx bx-map-pin bx-xs mr-1.5"></i>
              <span>{{ location }}</span>
            </p>
          </div>
        </div>
      </div>
      <Divider class="my-6" />
      <div class="block-lower-part">
        <div class="info-block">
          <div class="info-block-icon-container">
            <i class="bx bx-calendar info-block-icon"></i>
          </div>
          <div class="container flex items-center">
            <div class="mr-3 sm:mr-6">
              <h5 class="info-block-title">Check in</h5>
              <div class="text-black-base">
                <div class="info-block-value">
                  {{ checkInDate }}
                </div>
              </div>
            </div>
            <i class="bx bx-right-arrow-alt arrow"></i>
            <div class="ml-3 sm:ml-6">
              <h5 class="info-block-title">Check out</h5>
              <div class="text-black-base">
                <div class="info-block-value">
                  {{ checkOutDate }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="info-block">
          <div class="info-block-icon-container">
            <i class="bx bx-moon info-block-icon"></i>
          </div>
          <div class="container">
            <h5 class="info-block-title">Nights</h5>
            <div class="text-black-base">
              <div class="info-block-value">
                {{ numberOfNights }}
                {{
                  numberOfNights > 1 || numberOfNights == "0"
                    ? "nights"
                    : "night"
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="info-block">
          <div class="info-block-icon-container">
            <i class="bx bx-dish info-block-icon"></i>
          </div>

          <div class="container">
            <h5 class="info-block-title">Meal plan</h5>
            <div class="text-black-base">
              <div class="info-block-value">
                {{ mealplan }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ButtonPill from "@/components/Buttons/ButtonPill";
import Divider from "@/components/Layout/Divider";
export default {
  name: "SummaryTile",
  components: {
    ButtonPill,
    Divider,
  },
  props: {
    accommodationDetails: {
      type: Object,
    },
    title: {
      type: String,
      default: "Menlyn Boutique Hotel",
    },
    hotelStars: {
      type: Number,
      default: 4,
    },
  },
  computed: {
    hotelName() {
      return this.accommodationDetails.hotel.name;
    },
    breakfast() {
      return this.accommodationDetails.mealplan.breakfast;
    },
    lunch() {
      return this.accommodationDetails.mealplan.lunch;
    },
    dinner() {
      return this.accommodationDetails.mealplan.dinner;
    },
    mealplan() {
      if (this.breakfast && this.lunch && this.dinner)
        return "Breakfast, lunch, dinner";
      if (this.breakfast && this.lunch) return "Breakfast, lunch";
      if (this.breakfast && this.dinner) return "Breakfast, dinner";
      if (this.dinner && this.lunch) return "Lunch, dinner";
      if (this.breakfast) return "Breakfast";
      if (this.dinner) return "Dinner";
      if (this.lunch) return "Lunch";

      return "No meal plan selected";
    },
    location() {
      return this.accommodationDetails.hotel.address;
    },
    checkInDate() {
      return this.accommodationDetails.checkin;
    },
    checkOutDate() {
      return this.accommodationDetails.checkout;
    },
    numberOfNights() {
      return this.accommodationDetails.numberOfNights;
    },
    stars() {
      let stars = [];
      let amountOfStars = this.hotelStars;

      //Loop 5 times
      for (let i = 0; i < 5; i++) {
        //Case if amount of stars of the hotel is smaller than 5
        if (amountOfStars < 5) {
          //Check if current index + 1 is equal to the amount of stars
          if (i + 1 <= amountOfStars) {
            stars.push("fas fa-star-of-life text-teal-base mr-1"); //Push filled stars class to the array
          } else {
            //Check if current index + 1 is  not equal to the amount of stars
            if (i + 1 != 5) {
              stars.push("fas fa-star-of-life text-black-lightest mr-1");
            } else {
              //Check if current index + 1 is   equal to the amount of stars then push without mr-1
              stars.push("fas fa-star-of-life text-black-lightest");
            }
          }
        }
        //Case if amount of stars of the hotel is equal to 5
        if (amountOfStars == 5) {
          //Check if current index + 1 is equal to the amount of stars
          if (i + 1 <= amountOfStars) {
            stars.push("fas fa-star-of-life text-teal-base mr-1"); //Push filled stars class to the array
          } else {
            stars.push("fas fa-star-of-life text-teal-base"); //Push filled stars class to the array without mr-1
          }
        }
      }
      return stars;
    },
  },
};
</script>
<style scoped>
.block-container {
  @apply rounded-md shadow-sm border border-grey-base bg-white;
  @apply flex justify-between items-center w-full;
  @apply py-6;
}
.block-content-container {
  @apply w-full;
}

.block-upper-part,
.block-lower-part {
  @apply px-4 sm:px-6;
}

.button-container {
  @apply inline-flex w-full justify-end mb-6;
}
.button-container button:first-child {
  @apply mr-3;
}
.header {
  @apply flex w-full;
}

.title {
  @apply font-sans font-semibold text-black-base text-base sm:text-xl flex items-center leading-none;
}

.location {
  @apply font-sans text-black-lightest flex items-center text-xs sm:text-base mt-1.5;
}

.location span {
  @apply truncate;
}
.block-lower-part {
  @apply flex items-center w-full flex-wrap md:flex-nowrap;
}

.info-block {
  @apply flex items-center w-full md:w-auto;
}

.info-block:nth-child(2n),
.info-block.info-block:nth-child(3n) {
  @apply mt-6 md:mt-auto  md:ml-24;
}

.info-block-title {
  @apply text-sm text-black-lightest;
}

.info-block-icon-container {
  @apply h-8 min-w-8 xs:h-12  xs:min-w-12 rounded-full bg-violet-lighter;
  @apply flex items-center justify-center mr-3;
}

.info-block-icon {
  @apply text-violet-base text-xl sm:text-2xl;
}

.info-block-value {
  @apply text-sm sm:text-base;
}

.arrow {
  @apply text-black-lightest text-xl xs:text-2xl;
}
</style>