<template>
  <button
    type="button"
    :disabled="disAttribute"
    @click="$emit('btn-secondary')"
    class="btn-secondary"
  >
    {{ text }}
  </button>
</template>
<script>
export default {
  name: "ButtonSecondary",
  props: {
    text: {
      type: String,
      default: "Button",
    },
    disAttribute: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
.btn-secondary {
  @apply duration-300 ease-in-out transition;
  @apply focus:scale-95 font-medium focus:outline-none focus:ring-2 focus:border-transparent focus:ring-black-light focus:ring-offset-2 rounded h-11;
  @apply px-3 text-black-base text-xs xs:text-base bg-white transform shadow-sm border-grey-dark border;
}
</style>