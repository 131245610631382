<template>
  <nav class="nav">
    <div class="btn-wrapper">
      <ButtonIcon
        iconClass="bx bxs-save"
        class="mr-3 text-white"
        @click.native="$emit('saveToDraft','draft')"
      />
      <ButtonIconText
        @click.native="exitOffer"
        iconClass="bx bx-exit"
        class="mb-4 ml-auto bg-grey-light hover:bg-grey-base uppercase"
        text="Exit"
      />
    </div>
    <MultiStepBar
      @currentMultiStepItem="emitCurrentMultiStepItem"
      :multiStepItems="multiStepItems"
      :currentStepItemIndex="currentStepItemIndex"
    />
  </nav>
</template>
<script>
import ButtonIcon from "@/components/Buttons/ButtonIcon";
import ButtonIconText from "@/components/Buttons/ButtonIconText";
import MultiStepBar from "@/components/Offer/Navbar/MultiStepBar";
export default {
  name: "FormNavbar",
  components: {
    ButtonIcon,
    ButtonIconText,
    MultiStepBar,
  },
  props: {
    multiStepItems: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
    },
    currentStepItemIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  methods: {
    emitCurrentMultiStepItem(value) {
      this.$emit("currentMultiStepItem", value);
    },
    exitOffer() {
    this.$emit('exit')
    },
  },
};
</script>
<style scoped>
.nav {
  @apply flex justify-between items-center flex-wrap pt-2;
}

.btn-wrapper {
  @apply flex justify-between items-start ml-auto;
}
</style>