import { render, staticRenderFns } from "./BlockPlaceholder.vue?vue&type=template&id=a3a1c676&scoped=true&"
import script from "./BlockPlaceholder.vue?vue&type=script&lang=js&"
export * from "./BlockPlaceholder.vue?vue&type=script&lang=js&"
import style0 from "./BlockPlaceholder.vue?vue&type=style&index=0&id=a3a1c676&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3a1c676",
  null
  
)

export default component.exports