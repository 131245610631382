<template>
  <div :class="['banner', customStyle]">
    <i :class="[faIconClass, iconColor, 'text-xl']"></i>
    <div class="text-container">
      <h6 class="title">{{ title }}</h6>
      <p class="text">{{ text }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Banner",
  props: {
    faIconClass: {
      type: String,
      default: "fas fa-info-circle",
    },
    iconColor: {
      type: String,
      default: "text-black-base",
    },
    title: {
      type: String,
      default: "Title",
    },
    text: {
      type: String,
      default: "Text",
    },
    customStyle: {
      type: String,
      default: "border border-grey-dark text-black-light",
    },
  },
};
</script>
<style scoped>
.banner {
  @apply rounded-md p-4 flex;
}

.text-container {
  @apply ml-4 text-black-base;
}

.title {
  @apply text-base font-medium;
}

.text {
  @apply text-sm font-light;
}
</style>