<template>
  <label :for="selectName" class="block mb-6">
    <span v-if="showLabel" class="select-label">{{ label }} </span>
    <select
      class="select"
      :class="[isError ? 'border-red-base' : 'border-grey-dark']"
      :name="selectName"
      :id="selectName"
      @change="$emit('change', $event.target.value)"
      @input="
        $emit('input', $event.target.value),
          $emit('getSelectValue', $event.target.value)
      "
    >
      <option :selected="!selected" disabled>{{ description }}</option>
      <option
        v-for="(option, index) in optionsList"
        :key="index"
        :value="option.value"
        :selected="selected === option.value"
      >
        {{ option.name }}
      </option>
    </select>

    <span
      v-if="isError"
      class="mt-2 font-sans text-sm font-medium error-msg text-red-base"
    >
      {{ errorMsg }}
    </span>
  </label>
</template>
<script>
export default {
  name: "SelectDropdown",
  inheritAttrs: false,
  props: {
    showLabel: {
      type: Boolean,
      default: false,
    },
    selectName: {
      type: String,
    },
    selected: {
      type: [String, Number],
    },
    description: {
      type: String,
    },
    label: {
      type: String,
      default: "Label",
    },
    optionsList: {
      type: Array,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    errorMsg: {
      type: String,
      default: "",
    },
  },
};
</script>
<style scoped>
.select-label {
  @apply text-black-base block font-sans font-medium;
  @apply text-base;
}
.select {
  @apply rounded border focus:border-teal-base focus:ring-teal-lighter;
  @apply focus:ring-2 mt-1.5 sm:mt-3 p-2 sm:py-3 w-full;
}
</style>