<template>
  <button
    type="button"
    class="text-xl focus:outline-none focus:ring-2 focus:ring-black-lighter focus:scale-95 focus:ring-offset-2 hover:bg-black-lightest hover:text-white transitionease-in-out duration-300 close-btn bg-grey-light h-11 w-11 rounded-full text-black-lightest"
  >
    <i class="fal fa-times"></i>
  </button>
</template>
<script>
export default {
  name: "ButtonClose",
};
</script>