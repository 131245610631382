<template>
  <div class="divider"></div>
</template>
<script>
export default {
  name: "Divider",
};
</script>
<style scoped>
.divider {
  @apply w-full border border-l-0 border-t-0 border-r-0 border-grey-base;
}
</style>