<template>
  <div class="input-wrapper">
    <label class="input-label" :for="name">
      {{ label }}
    </label>
    <div class="input-btn-wrapper flex w-full">
      <div class="input-wrapper flex w-full">
        <input
          type="number"
          :id="name"
          class="input"
          v-model="inputValue"
          @input="emitMethods"
        />
        <span v-if="showMsg" class="error-msg">
          {{ errorMsg }}
        </span>
        <span v-if="help != undefined" class="help-msg">
          {{ help }}
        </span>
      </div>
      <div class="btn-container">
        <button
          type="button"
          @click="decrementValue()"
          :class="['btn', btnOpacity]"
        >
          -
        </button>
        <button type="button" @click="incrementValue()" class="btn">+</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InputNumberSpinner",
  data() {
    return {
      inputValue: this.value,
      btnOpacity: "opacity-50",
    };
  },
  props: {
    value: {
      type: [String, Number],
      default: "0",
    },
    label: {
      type: String,
      default: "Label",
    },
    help: {
      type: String,
    },
    errorMsg: {
      type: String,
      default: "A value is required",
    },
    showMsg: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "input",
    },
    placeholder: {
      type: String,
      default: "Input name",
    },
  },
  watch: {
    inputValue: function (value) {
      value >= 1
        ? (this.btnOpacity = "opacity-100")
        : (this.btnOpacity = "opacity-50");
    },

    value(newVal) {
      this.inputValue = parseInt(newVal);
    },
  },

  methods: {
    setValue(value) {
      this.inputValue = value;
    },
    decrementValue() {
      if (this.inputValue >= 1) {
        this.inputValue--;
      }
      this.$emit("input", this.inputValue);
    },
    incrementValue() {
      this.inputValue++;
      this.$emit("input", this.inputValue);
    },

    emitMethods() {
      this.$emit("input", this.inputValue);
      //this.setValue($event.target.value)
    },
  },
};
</script>
<style scoped>
/* Chrome, Safari, Edge, Opera */
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input[type="number"] {
  -moz-appearance: textfield;
}

.input-wrapper {
  @apply flex flex-wrap border-none p-0;
}

.input-label {
  @apply flex w-full flex-wrap font-sans text-black-base;
  @apply mb-1.5 sm:mb-3 text-base;
}

.input {
  @apply rounded w-full p-2 sm:p-3 border border-grey-dark;
  @apply focus:border-teal-base focus:ring-teal-lighter focus:ring-2;
  @apply text-black-base placeholder-black-lightest;
  @apply text-base;
}

.error-msg {
  @apply font-sans text-sm mt-2 text-red-base;
}

.help-msg {
  @apply font-sans text-xs mt-2 text-black-light;
}

.btn-container {
  @apply min-w-32 sm:min-w-64 flex justify-between;
}

.btn {
  @apply rounded bg-black-base sm:h-13 w-calc-1/2 ml-2 focus:outline-none;
  @apply text-white text-xl;
}
</style>