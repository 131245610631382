<template>
  <div class="input-wrapper">
    <label for="start-date" class="date mb-6 sm:mb-0">
      <span class="input-label">Start date</span>
      <input
          :data-dd-opt-min-date="getCurrentDate"
          id="start-date"
          name="start-date"
          placeholder="Start date"
          ref="startDate"
          class="input-datedropper"
          type="text"
          @input="updateDate"
          @change="updateDate"
          v-model="startDate"
      />
    </label>
    <label for="return-date" class="date">
      <span class="input-label">Return date</span>
      <input
          :data-dd-opt-min-date="getCurrentDate"
          id="return-date"
          name="return-date"
          placeholder="Return date"
          ref="returnDate"
          class="input-datedropper"
          type="text"
          @blur="updateDate"
          v-model="endDate"
      />
    </label>
  </div>
</template>
<script>
import "../../assets/datedropper/datedropper-javascript";
import dayjs from "dayjs";
import {getEndDate, getStartDate} from "../../shared/dateHandler";

export default {
  name: "DateRangePicker",
  props: ["value"],
  mounted() {
    new window.dateDropper({
      selector: ".input-datedropper",
      startFromMonday: true,
      minYear: new Date().getFullYear(),
      format: "W, dd M y",
      showArrowsOnHover: false,
      expandedOnly: true,
      doubleView: true,
      overlay: true,
      range: true,
      onChange: (res) => {
        this.startDate = res.output.a.string;
        this.endDate = res.output.b.string;
        let unixTimeStampStart = res.output.a.U * 1000;
        let unixTimeStampEnd = res.output.b.U * 1000;
        let output = res.output;
        this.startDate = dayjs(unixTimeStampStart).format('ddd, D MMM YYYY');
        this.endDate = dayjs(unixTimeStampEnd).format('ddd, D MMM YYYY');
        this.startDateIso = `${output.a.y}-${output.a.mm}-${output.a.dd}T00:00:00.000Z`;
        this.endDateIso = `${output.b.y}-${output.b.mm}-${output.b.dd}T00:00:00.000Z`;
        this.$emit("input", `${this.startDateIso}|${this.endDateIso}`);
      },
    });
    if (this.value) {
      this.startDate = getStartDate(this.value).format('ddd, D MMM YYYY');
      this.endDate = getEndDate(this.value).format('ddd, D MMM YYYY');
      this.startDateIso = getStartDate(this.value).startOf('day').toISOString();
      this.endDateIso = getEndDate(this.value).startOf('day').toISOString();
    }
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      startDateIso: "",
      endDateIso: ""
    };
  },
  computed: {
    splitDate() {
      const splitValueString = this.value.split("|");
      return {
        startDate: splitValueString[0],
        returnDate: splitValueString[1],
      };
    },
    getCurrentDate() {
      return dayjs().format('ddd, D MMM YYYY');
    },
  },
  methods: {
    updateDate() {
      this.$emit("input", `${this.startDateIso}|${this.endDateIso}`);
    },
  },
  watch: {
    value(newVal) {
      if (newVal) {
        this.startDate = getStartDate(newVal).format('ddd, D MMM YYYY');
        this.endDate = getEndDate(newVal).format('ddd, D MMM YYYY');
        this.startDateIso = getStartDate(newVal).toISOString();
        this.endDateIso = getEndDate(newVal).toISOString();
      }
    }
  }
};
</script>
<style src="@/assets/datedropper/datedropper.css"></style>
<style scoped>
.input-datedropper {
  @apply rounded border placeholder-black-lightest text-black-base border-grey-dark;
  @apply w-full focus:border-teal-base focus:ring-teal-lighter focus:ring-2;
  @apply p-2 sm:p-3 text-sm sm:text-base;
}

.input-wrapper {
  @apply flex flex-wrap justify-between;
}

.date {
  @apply w-full sm:w-calc-1/2;
}

.input-label {
  @apply mb-1.5 sm:mb-3 font-sans font-medium text-black-base block;
  @apply text-sm sm:text-base;
}
</style>