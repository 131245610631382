<template>
  <div class="summary-tile-container">
    <div class="summary-tile-wrapper w-full">
      <div class="flex justify-between w-full">
        <h4 class="tile-title">Day {{ day }} · {{ title }}</h4>
        <div class="button-container text-black-lightest flex">
          <button
            @click="$emit('delete')"
            type="button"
            class="fn-btn mr-4"
            :disabled="disAttribute"
          >
            <i class="far fa-trash-alt mr-2"></i> Remove
          </button>
          <button
            @click="$emit('edit')"
            type="button"
            class="fn-btn"
            :disabled="disAttribute"
          >
            <i class="far fa-edit mr-2"></i> Edit
          </button>
        </div>
      </div>
      <div id="divider" class="w-full border-b border-white mt-6"></div>
      <div class="flex items-center">
        <div class="summary-tile-wrapper w-full">
          <h5 class="tile-subtitle">Experiences</h5>
          <div class="mt-2 w-full">

            <ExperienceTag
              v-for="(experience, i) in experiences"
              :number="i + 1"
              :key="i"
              :title="experience.title.length === 0 ? (experience.name ||experience.extras?experience.extras.join(', '):'' ): experience.title"
              :class="[i + 1 === experiences.length ? '' : 'mr-1.5', 'mb-1.5']"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ExperienceTag from "@/components/Offer/Itinerary/ExperienceTag";
export default {
  name: "SummaryTile",
  components: {
    ExperienceTag,
  },
  props: {
    disAttribute: {
      type: Boolean,
      default: false,
    },
    experiences: {
      type: Array,
      default: () => [
        { title: "Visiting National Park" },
        { title: "Balloon trip over Pretoria" },
        { title: "Visiting National Park" },
        { title: "Visiting National Park" },
        { title: "Visiting National Park" },
        { title: "Balloon trip over Pretoria" },
        { title: "Visiting National Park" },
        { title: "Balloon trip over Pretoria" },
        { title: "Visiting National Park" },
        { title: "Balloon trip over Pretoria" },
        { title: "Visiting National Park" },
      ],
    },
    day: {
      type: Number,
      default: 1,
    },
    title: {
      type: String,
      default: "Arrival in Cape Town",
    },
  },
};
</script>
<style scoped>
.fn-btn {
  @apply focus:outline-none ring-offset-white focus:scale-95 focus:ring-offset-2 focus:ring-2 focus:ring-black-darker text-white bg-black-darker  transition duration-300 ease-in-out flex items-center justify-center h-9 px-3 rounded-full;
  @apply text-sm sm:text-base;
}

.summary-tile-container {
  @apply flex rounded-md shadow-sm border border-grey-base w-full;
  @apply p-6 justify-between bg-white items-center;
}

.tile-title {
  @apply font-sans font-semibold text-black-base text-xl flex items-center leading-none;
}

.tile-subtitle {
  @apply text-black-lightest uppercase text-xs font-medium;
}
</style>