<template>
  <div id="requestsheet" class="sheet">
    <nav class="navbar">
      <ButtonClose v-if="showCloseButton" class="btn-close" @click.native="$emit('closeSheet')"/>
      <TabButtons
          v-if="isTabItems"
          :buttons="tabItems"
          class="tab"
          isBorder="border-grey-base"
      />
    </nav>
    <template v-if="request">
      <div class="wrapper mt-20 flex justify-end">
        <template v-if="tabItems[0].isActive">
          <div
              v-if="request.tripRequestStatus === 'completed'"
              class="label-booked"
          >
            <i class="fas fa-check-circle"></i>
            <div>Booked</div>
          </div>
          <div
              v-if="request.tripRequestStatus === 'cancelled'"
              class="label-cancelled"
          >
            <i class="fas fa-ban"></i>
            <div>Cancelled</div>
          </div>
        </template>
      </div>

      <div v-show="tabItems[0].isActive" id="requestDetails" class="wrapper">
        <div class="flex justify-between w-full">
          <div class="request-value">
            <div class="font-sans text-teal-base">
              <i class="fas fa-usd-square"></i>
              Request value
            </div>

            <span
                v-if="request.tripTotalPrice"
                class="font-sans text-black-base font-semibold text-xl"
            >
              {{ request.tripCurrency }}
              {{ request.tripTotalPrice.toLocaleString() }}
            </span>

            <span
                v-else
                class="font-sans text-black-base font-semibold text-xl"
            >
              {{ request.tripCurrency }} {{ totalTripBudget }}
            </span>
          </div>

          <template v-if="!hideOfferButton">
            <ButtonLink
                v-if="
                request.tripRequestStatus === 'pending' ||
                request.tripRequestStatus === 'open'
              "
                :route="`/request/offer/?customer=${request.user_id}&request_id=${request.id}`"
                class="px-3"
                text="Create offer"
            />
          </template>
        </div>
        <Divider class="my-4"/>
        <div class="flex justify-between w-calc-full">
          <h6 class="row-title">Reference nr.</h6>
          <p class="row-value">{{ referenceNumber }}</p>
        </div>

        <template v-if="request.language">
          <Divider class="my-4"/>
          <div class="flex justify-between w-calc-full">
            <h6 class="row-title">Language of communication</h6>
            <p class="row-value">{{ formatLanguage(request.language) }}</p>
          </div>
        </template>

        <Divider class="my-4"/>
        <div class="row-container">
          <h6 class="row-title">Salutation</h6>
          <p class="row-value">{{ request.tripSalutation }}</p>
        </div>
        <Divider class="my-4"/>
        <div class="row-container">
          <h6 class="row-title">First name</h6>
          <p class="row-value">{{ request.tripFirstname }}</p>
        </div>
        <Divider class="my-4"/>
        <div class="row-container">
          <h6 class="row-title">Last name</h6>
          <p class="row-value">{{ request.tripLastname }}</p>
        </div>

        <template v-if="request.createdAt">
          <Divider class="my-4"/>
          <div class="row-container">
            <h6 class="row-title">Request date</h6>
            <p class="row-value">
              <timeago
                  :auto-update="60"
                  :datetime="request.createdAt"
              ></timeago>
            </p>
          </div>
        </template>

        <Divider class="my-4"/>
        <div class="row-container">
          <h6 class="row-title">Destination</h6>
          <p class="row-value">{{ request.tripDestination }}</p>
        </div>
        <Divider class="my-4"/>
        <div class="row-container">
          <h6 class="row-title">Trip date</h6>
          <p class="row-value">
            {{ request.tripDates }}
          </p>
        </div>

        <template v-if="request.tripReason">
          <Divider class="my-4"/>
          <div class="row-container">
            <h6 class="row-title">Reason</h6>
            <p class="row-value">{{ request.tripReason }}</p>
          </div>
        </template>

        <template v-if="request.tripGroupType">
          <Divider class="my-4"/>
          <div class="row-container">
            <h6 class="row-title">Group</h6>
            <p class="row-value">{{ request.tripGroupType }}</p>
          </div>
        </template>

        <template v-if="request.tripBudget">
          <Divider class="my-4"/>
          <div class="row-container">
            <h6 class="row-title">Budget per traveler</h6>
            <p class="row-value">
              {{ request.tripCurrency }}
              {{ request.tripBudget.toLocaleString() }}
            </p>
          </div>
        </template>

        <template v-if="request.tripType">
          <Divider class="my-4"/>
          <div class="row-container">
            <h6 class="row-title">Trip type</h6>
            <p class="row-value">{{ request.tripType }}</p>
          </div>
        </template>

        <Divider class="my-4"/>
        <div class="row-container">
          <h6 class="row-title mt-4">Number of travelers</h6>
        </div>
        <div class="row-container flex-wrap">
          <div class="travelers-details w-full">
            <p class="text-black-base font-sans py-2">
              {{ request.tripAdults }} × Adults
            </p>
            <div class="container flex justify-between flex-wrap">
              <p class="text-black-base font-sans py-1">
                {{ request.tripChildren }} × Children
              </p>
              <div class="text-sm tex-grey-base text-black-lightest">
                <template v-if="request.tripChildrenAge">
                  <span
                      v-for="(age, index) in request.tripChildrenAge.split(',')"
                      :key="index"
                      class="age-label"
                  >
                    {{ age }} {{ age != 1 ? "years" : "year" }}
                  </span>
                </template>
              </div>
            </div>
          </div>
        </div>


        <template v-if="hasExtras">
          <Divider class="my-4"/>
          <div
              v-for="(tripDetails, index) in tripAccommodation"
              :key="index"
              class=""
          >
            <p class="my-8">
              Accommodation details for {{ tripDetails.checkin }} -
              {{ tripDetails.checkout }}
            </p>

            <Divider class="my-4"/>
            <div class="row-container">
              <h6 class="row-title">Name</h6>
              <div class="text-right">
                <p class="row-value">{{ tripDetails.name }}</p>
                <span class="text-sm text-black-base font-sans">
                  {{ tripDetails.address }}
                </span>
              </div>
            </div>
            <Divider class="my-4"/>
            <div class="row-container">
              <h6 class="row-title">Duration</h6>
              <p class="row-value">
                {{ tripDetails.checkin }} - {{ tripDetails.checkout }}
              </p>
            </div>

            <Divider class="my-4"/>

            <div class="row-container">
              <h6 class="row-title">Meal Plan</h6>
              <p class="row-value">{{ tripDetails.mealplan }}</p>
            </div>
            <Divider class="my-4"/>
          </div>

          <div
              v-for="(itineraryDetails, index) in tripItinerary"
              :key="index + 'itinerary'"
              class="mb-8"
          >
            <p class="my-8">
              Experience: <span>{{ itineraryDetails.title }}</span>
            </p>

            <Divider class="my-4"/>
            <div class="row-container">
              <h6 class="row-title mt-4">Name</h6>

              <div class="text-right">
                <p class="row-value">{{ itineraryDetails.title }}</p>
                <span class="text-sm text-black-base font-sans">
                  {{ itineraryDetails.location }}
                </span>
              </div>
            </div>
            <template
                v-if="
                itineraryDetails.experiences &&
                itineraryDetails.experiences.length > 0
              "
            >
              <Divider class="my-4"/>
              <div class="row-container">
                <h6 class="row-title">Itinerary</h6>
              </div>

              <div
                  v-for="(itinerary, index) in itineraryDetails.experiences"
                  :key="itinerary.id"
              >
                <Divider class="my-4"/>
                <div class="row-container">
                  <h6 class="row-title mt-4">Destination {{ index + 1 }}</h6>

                  <div class="text-right">
                    <p class="row-value">
                      {{ itinerary.name }}, {{ itinerary.location }}
                    </p>

                    <span class="text-sm text-black-base font-sans">
                    {{ itinerary.extras.join(", ") }}
                    </span>
                  </div>
                </div>
              </div>
            </template>

            <template v-if="itineraryDetails.extras">
              <Divider class="my-4"/>
              <div class="row-container">
                <h6 class="row-title">Extras</h6>
                <p class="row-value">
                  {{ itineraryDetails.extras }}
                </p>
              </div>
            </template>
          </div>
        </template>


        <template v-if="request.original_experiences">
          <Divider class="my-4"/>

          <div class="row-container-noflex">
            <h6 class="row-title">Experience</h6>
            <p class="row-value">{{ request.originalExperiences }}</p>
          </div>
        </template>

        <template v-if="(request.originalAccommodation && request.originalAccommodation !=='null' ) && !hasExtras ">
          <Divider class="my-4"/>

          <div class="row-container-noflex">
            <h6 class="row-title">Accommodation</h6>

            <p class="row-value">{{ request.originalAccommodation }}</p>
          </div>
        </template>

        <template v-if="request.tripMealPlan">
          <Divider class="my-4"/>
          <div class="row-container">
            <h6 class="row-title">Meal Plan</h6>
            <p class="row-value">{{ request.tripMealPlan }}</p>
          </div>
        </template>

        <template v-if="request.tripAccompany">
          <Divider class="my-4"/>

          <div class="row-container">
            <h6 class="row-title">Accompany</h6>
            <p class="row-value">{{ request.tripAccompany === 1 ? 'Yes' : 'No' }}</p>
          </div>
        </template>

        <template v-if="request.tripConsultation">
          <Divider class="my-4"/>

          <div class="row-container">
            <h6 class="row-title">Consulting</h6>
            <p class="row-value">
              {{ request.tripConsultation ? "Yes" : "No" }}
            </p>
          </div>
        </template>

        <template v-if="request.tripDetails && request.tripDetails!=='null' ">
          <Divider class="my-4"/>
          <div class="w-calc-full mt-3">
            <h6 class="row-title mt-4">Additional details</h6>
            <p class="row-value">
              {{ request.tripDetails }}
            </p>
          </div>
        </template>
      </div>
      <div v-show="tabItems[1].isActive" id="requested-changes" class="wrapper">
        <div v-if="showEmptyState" class="empty-state w-full">
          <figure class="max-w-lg relative left-1/2 transform -translate-x-1/2">
            <img
                alt="Empty State"
                class="w-full h-full object-cover"
                src="@/assets/img/empty-states/no-change-requests.svg"
            />
          </figure>
          <h3
              class="
              font-sans
              text-black-base text-xl
              font-semibold
              text-center
              mt-6
            "
          >
            No change requests yet
          </h3>
          <p class="font-sans text-black-lighter text-center">
            The customer hasn't requested any changes yet.
          </p>
        </div>
        <MessageItem
            v-for="(changeRequest, i) in changeRequests"
            :key="i"
            :customerName="`${request.tripFirstname} ${request.tripLastname}`"
            :dateTime="changeRequest.createdAt"
            :message="changeRequest.message"
        />
      </div>
    </template>
  </div>
</template>
<script>
import ButtonClose from "@/components/Buttons/ButtonClose";
import ButtonLink from "@/components/Buttons/ButtonLink";
import Divider from "@/components/Layout/Divider";
import MessageItem from "@/components/Offer/MessageItem";
import TabButtons from "@/components/Tabs/TabButtons";

export default {
  name: "RequestSheet",
  components: {
    ButtonClose,
    ButtonLink,
    Divider,
    MessageItem,
    TabButtons,
  },
  data() {
    return {
      tabItems: [
        {name: "Request details", isActive: true},
        {name: "Requested changes", isActive: false},
      ],
    };
  },
  props: {
    request: {
      required: true,
      type: Object,
    },
    isTabItems: {
      type: Boolean,
      default: true,
    },
    changeRequests: {
      type: Array,
      default: () => [],
    },
    hasExtras: {
      required: false,
      type: Boolean,
    },

    hideOfferButton: {
      required: false,
      type: Boolean,
    },
    showCloseButton: {
      required: false,
      type: Boolean,
      default: true
    },

    travelers: {
      type: Object,
      default: () => {
        return {
          adults: 1,
          children: {
            number: 3,
            ages: [0, 1, 3],
          },
        };
      },
    },
  },
  methods: {
    formatLanguage(lang) {
      if (lang === 'en') return 'English';
      if (lang === 'fr') return 'French';
      return lang;
    }
  },
  computed: {
    referenceNumber() {
      if (
          ["pending", "changes", "draft"].includes(this.request.tripRequestStatus)
      ) {
        return this.request.tripRequestId;
      } else {
        return this.request.id;
      }
    },
    showEmptyState() {
      if (this.changeRequests.length < 1) {
        return true;
      }
      return false;
    },
    totalTripBudget() {
      return (
          (this.request.tripAdults + this.request.tripChildren) *
          this.request.tripBudget
      ).toLocaleString();
    },

    tripItinerary() {
      if (this.request.tripItinerary && this.hasExtras) {
        return JSON.parse(this.request.tripItinerary);
      }
      return {};
    },

    tripAccommodation() {
      if (this.request.tripAccommodation && this.hasExtras) {
        return JSON.parse(this.request.tripAccommodation);
      }
      return {};
    },
  },
};
</script>
<style scoped>
.sheet {
  @apply bg-white overflow-y-auto pb-20  px-4 sm:px-12;
}

.navbar {
  @apply max-w-screen-sm fixed z-50 left-1/2 transform -translate-x-1/2 w-full py-4;
}

.btn-close {
  @apply absolute right-0;
}

.tab {
  @apply left-1/2 transform -translate-x-1/2 relative;
}

.wrapper {
  @apply max-w-screen-sm relative left-1/2 transform -translate-x-1/2 mt-20;
}

.label-booked {
  @apply p-2.5 bg-teal-lighter rounded-md text-teal-text inline-block;
}

.label-cancelled {
  @apply p-2.5 bg-red-lighter rounded-md text-red-text inline-block;
}

.label-cancelled div,
.label-booked div {
  @apply ml-1.5 inline-block;
}

.row-container {
  @apply flex justify-between w-full mt-3 items-center;
}

.row-container-noflex {
  @apply w-full mt-3 items-center;
}

.row-title {
  @apply font-sans text-black-lightest;
}

.row-value {
  @apply font-sans text-black-base mt-1;
}

.age-label {
  @apply inline-block p-1.5 px-3 bg-black-darker text-white rounded-md mr-1 mb-1;
}
</style>