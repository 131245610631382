<template>
  <div class="requests bg-grey-light h-100vh overflow-hidden">
    <div class="px-4 md:px-12">
      <FormNavbar
          class="w-full max-w-5xl relative left-1/2 transform -translate-x-1/2"
          @exit="exitOfferForm"
          @saveToDraft="submitOffer"
          @currentMultiStepItem="getCurrentMultiStepItem"
          :currentStepItemIndex="currentStepItemIndex"
          :isLoading="isSubmittingOffer"
      />
    </div>
    <template v-if="isLoadingData">
      <Loading/>
    </template>
    <template v-else>
      <Dialog
          v-show="showDialog"
          class="dialog"
          @cancelDialog="closeSheets"
          title="Do you really want to remove this Item?"
          text=""
      />
      <BackgroundLayer
          @click.native="closeSheets()"
          v-show="showOverlay"
          class="fixed z-10 top-0"
      />
      <div class="self-start w-full relative h-full overflow-y-auto">
        <section class="px-4 md:px-12 mt-5 pb-12">
          <div class="max-w-5xl relative left-1/2 transform -translate-x-1/2">
            <router-view/>
          </div>
        </section>
        <button
            v-if="isPricing"
            type="button"
            class="btn-price"
            @click="showPricingSheet"
        >
          <i class="bx bxs-dollar-circle bx-xs"></i>
          <span>Show price summary</span>
        </button>
        <PricingTableSheet
            :class="['sheet', pricingSheetPosition]"
            @closeSheet="closeSheets"
            :isTabItems="false"
            :request="tripDetails"
            :changeRequests="changeRequests"
            :hideOfferButton="true"
        />
        <HotelSheet
            :class="['sheet', hotelSheetPosition]"
            @closeSheet="closeSheets"
            @newAccommodationAdded="newAccommodationAdded"
        />
        <RequestSheet
            @closeSheet="closeSheets"
            :isTabItems="false"
            :request="tripDetails"
            :changeRequests="changeRequests"
            :hideOfferButton="true"
            :class="['sheet', requestSheetPosition]"
        />
        <OfferSummarySheet
            @closeSheet="closeSheets"
            @submitOffer="submitOffer"
            :class="['sheet', summarySheetPosition]"
            :offer="offer"
            :isLastStep="isLastStep"
            :dates="tripDetails.tripDates"
            :isLoading="isSubmittingOffer"
        />
        <ExperienceSheet
            @closeSheet="closeSheets"
            :class="['sheet', experienceSheetPosition]"
            :experiences="[]"
        />
        <ExperienceListSheet
            @closeSheet="closeSheets"
            :class="['sheet', experienceSheetListPosition]"
            :experiences="experiences"
            @createExperience="showExperienceSheet"
        />
        <BottomBar
            @primaryClick="moveToNextTabLink"
            @summaryClick="showSummarySheet"
            @requestClick="showRequestSheet"
            :btnPrimaryText="buttonText"
            :isLoading="isSubmittingOffer"
            :loadingMessage="loadingMessage"
        />
      </div>
    </template>
  </div>
</template>
<script>
import BackgroundLayer from "@/components/Layout/BackgroundLayer";
import BottomBar from "@/components/Offer/BottomBar";
import Dialog from "@/components/Dialogs/Dialog";
import ExperienceListSheet from "@/components/Sheets/ExperienceListSheet";
import PricingTableSheet from "@/components/Sheets/PricingTableSheet";
import Loading from "@/components/Preloaders/Loading";
import HotelSheet from "@/components/Sheets/HotelSheet";
import FormNavbar from "@/components/Offer/Navbar/FormNavbar";
import RequestSheet from "@/components/Sheets/RequestSheet";
import OfferSummarySheet from "@/components/Sheets/OfferSummarySheet";
import ExperienceSheet from "@/components/Sheets/ExperienceSheet";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Offer",
  components: {
    BackgroundLayer,
    BottomBar,
    ExperienceSheet,
    ExperienceListSheet,
    Dialog,
    FormNavbar,
    HotelSheet,
    OfferSummarySheet,
    PricingTableSheet,
    RequestSheet,
    Loading,
  },
  props: {
    requestId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      currentStepItemIndex: 0,
      isLastStep: false,
      pageIsLoading: true,
      customers: {},
      customerId: 0,
      newAccommodation: "",
      customerDetails: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
      },
      accommodationDetails: {},
      itineraryDetails: {},
      pricingDetails: {},
      cancellationDetails: {},
      request: {},
      formSections: [
        {name: "customer-details", show: true},
        {name: "trip-details", show: false},
        {name: "accommodations", show: false},
        {name: "itinerary", show: false},
        {name: "pricing", show: false},
        {name: "cancellation", show: false},
      ],
      showDialog: false,
      showOverlay: false,
      showSheet: false,
      requestSheetPosition: "-bottom-full h-0 overflow-hidden",
      hotelSheetPosition: "-bottom-full h-0 overflow-hidden",
      summarySheetPosition: "-bottom-full h-0 overflow-hidden",
      experienceSheetPosition: "-bottom-full h-0 overflow-hidden",
      experienceSheetListPosition: "-bottom-full h-0 overflow-hidden",
      pricingSheetPosition: "-bottom-full h-0 overflow-hidden",
      changeRequests: [],
    };
  },
  methods: {
    ...mapActions({
      getTripRequestDetails: "offer/getTripRequestDetails",
      setShowExperienceListSheet: "experiences/setShowExperienceListSheet",
      setShowAccommodationSheet: "accommodations/setShowAccommodationSheet",
      submitOffer: "offer/submitOffer",
      startOfferCreationTracking:"tracker/startOfferCreationTracking"
    }),
    closeSheets() {
      this.requestSheetPosition = "-bottom-full h-0 overflow-hidden";
      this.summarySheetPosition = "-bottom-full h-0 overflow-hidden";
      this.hotelSheetPosition = "-bottom-full h-0 overflow-hidden";
      this.experienceSheetPosition = "-bottom-full h-0 overflow-hidden";
      this.experienceSheetListPosition = "-bottom-full h-0 overflow-hidden";
      this.pricingSheetPosition = "-bottom-full h-0 overflow-hidden";
      this.setShowAccommodationSheet(false);
      this.setShowExperienceListSheet(false);
      // this.isLastStep = false; //Hides hint and CTA in preview
    },
    newAccommodationAdded(value) {
      this.closeSheets();
      this.newAccommodation = value;
    },
    exitOfferForm() {

      if (this.showUnsavedChangesWarning) {
        if (
            window.confirm(
                "Are you sure you want to leave the page? \nIf you leave without saving, your changes will be lost."
            )
        ) {

          this.$router.go(-1);
        }
      } else {
        this.$router.go(-1);
      }

    },
    getCurrentMultiStepItem(value) {
      this.currentStepItemIndex = this.formSections.findIndex((x) => x.name === value.name);
      for (let section of this.formSections) {
        section.show = false;
        if (section.name === value.name) {
          section.show = value.show;
        }
      }
    },
    showSummarySheet() {
      this.closeSheets();
      this.summarySheetPosition = "bottom-0 overflow-auto h-full";
      //Scroll content of summary sheet to the top
      let container = this.$el.querySelector("#summarysheet");
      container.scrollTo(0, 0);
    },
    showPricingSheet() {
      this.closeSheets();
      this.pricingSheetPosition = "bottom-0 overflow-auto h-full";
      //Scroll content of hotel sheet to the top
      let container = this.$el.querySelector("#pricingsheet");
      container.scrollTo(0, 0);
    },
    showHotelSheet() {
      this.closeSheets();
      this.hotelSheetPosition = "bottom-0 overflow-auto h-full";
      //Scroll content of hotel sheet to the top
      let container = this.$el.querySelector("#hotelsheet");
      container.scrollTo(0, 0);
    },
    showRequestSheet() {
      this.closeSheets();
      this.requestSheetPosition = "bottom-0 overflow-auto h-full";
      //Scroll content of request sheet to the top
      let container = this.$el.querySelector("#requestsheet");
      container.scrollTo(0, 0);
    },
    showExperienceSheet() {
      this.closeSheets();
      this.experienceSheetPosition = "bottom-0 overflow-auto h-full";
      //Scroll content of request sheet to the top
      let container = this.$el.querySelector("#experiencesheet");
      container.scrollTo(0, 0);
    },
    showExperienceListSheet() {
      this.closeSheets();
      this.experienceSheetListPosition = "bottom-0 overflow-auto h-full";
      //Scroll content of request sheet to the top
      let container = this.$el.querySelector("#experience-list-sheet");
      if (container) {
        container.scrollTo(0, 0);
      }
    },
    moveToNextTabLink() {
      switch (this.$route.name) {
        case "CustomerDetails":
          this.$router.replace({ name: "TripDetails" });
          break;
        case('TripDetails'):
          this.$router.replace({name: 'Itinerary'});
          break;
        case('Itinerary'):
          this.$router.replace({name: 'Accommodations'});
          break;
        case('Accommodations'):
          this.$router.replace({name: 'Pricing'});
          break;
        case "Pricing":
          this.$router.replace({ name: "Cancellation" });
          break;
        case "Cancellation":
          this.showSummarySheet();
          break;
      }
    },
  },
  beforeMount() {
    this.getTripRequestDetails(this.requestId).then(() => {
      this.pageIsLoading = false;
    });
    if (!this.$route.query.customer) {
      this.customerId = this.request.userId;
    } else {
      this.customerId = this.$route.query.customer;
    }
    if (this.$route.meta.isLastStep) {
      this.isLastStep = this.$route.meta.isLastStep;
    }

    this.startOfferCreationTracking();
  },

  computed: {
    ...mapGetters({
      offerDetails: 'offer/offerDetails',
      tripDetails: 'offer/tripDetails',
      experiences: 'experiences/experiences',
      showExperienceListSheetState: 'experiences/showExperienceListSheetState',
      showAccommodationSheet: 'accommodations/showAccommodationSheet',
      showExperienceSheetState: 'experiences/showExperienceSheetState',
      isSubmittingOffer: 'offer/isSubmittingOffer',
      loadingMessage: 'offer/loadingMessage',
      isLoadingData: 'offer/isLoadingData',
      savingResponseStatus: 'offer/savingResponseStatus',
      savingResponseMessage: 'offer/savingResponseMessage',
      shouldRedirectAfterOfferSave: 'offer/shouldRedirectAfterOfferSave',
      showUnsavedChangesWarning: 'offer/showUnsavedChangesWarning',
      offerRequestType: 'offer/offerRequestType',
    }),
    offer() {
      function compare(a, b) {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        return 0;
      }

      //sort itinerary list
      let sortedItineraryDetails =
          this.offerDetails.tripDetails.tripItineraryDetails;
      sortedItineraryDetails = sortedItineraryDetails.sort(compare);
      //sort accommodation list
      let sortedAccommodationDetails =
          this.offerDetails.tripDetails.tripAccommodationDetails;
      sortedAccommodationDetails = sortedAccommodationDetails.sort(compare);
      return {
        tripDates: this.offerDetails.tripDetails.tripDates,
        tripEmail: this.offerDetails.tripDetails.tripEmail,
        tripAdults: this.offerDetails.tripDetails.tripAdults,
        tripChildren: this.offerDetails.tripDetails.tripChildren,
        tripFirstname: this.offerDetails.tripDetails.tripFirstname,
        tripLastname: this.offerDetails.tripDetails.tripLastname,
        tripAgencies: this.offerDetails.tripDetails.tripAgencies,
        tripPhone: this.offerDetails.tripDetails.tripPhone,
        tripCurrency: this.offerDetails.tripDetails.tripCurrency,
        tripRequestId: this.offerDetails.tripDetails.tripRequestId,
        tripSalutation: this.offerDetails.tripDetails.tripSalutation,
        tripSingleRooms: this.offerDetails.tripDetails.tripSingleRooms,
        tripDestination: this.offerDetails.tripDetails.tripDestination,
        transactionId: 0,
        tripAccommodation: JSON.stringify(sortedAccommodationDetails),
        tripAccommodationDetails: sortedAccommodationDetails,
        tripEarningFeePercentage:
        this.offerDetails.tripDetails.tripEarningFeePercentage,
        tripEarningFees:
        this.offerDetails.tripDetails.tripEarningFeePercentage,
        tripExclusions: this.offerDetails.tripDetails.tripExclusions,
        tripFullRefundDays:
        this.offerDetails.tripDetails.tripFullRefundDays,
        tripInclusions: this.offerDetails.tripDetails.tripInclusions,
        tripItinerary: JSON.stringify(sortedItineraryDetails),
        tripItineraryDetails: sortedItineraryDetails,
        tripNoRefundDays: this.offerDetails.tripDetails.tripNoRefundDays,
        tripPartialRefund:
        this.offerDetails.tripDetails.tripPartialRefundPercentage,
        tripPartialRefundDays:
        this.offerDetails.tripDetails.tripPartialRefundDays,
        tripPartialRefundPercentage:
        this.offerDetails.tripDetails.tripPartialRefundPercentage,
        tripPricePerAdult:
        this.offerDetails.tripDetails.tripPricePerAdult,
        tripPricePerChild:
        this.offerDetails.tripDetails.tripPricePerChild,
        tripSingleRoomPrice:
        this.offerDetails.tripDetails.tripSingleRoomPrice,
        tripTitle: this.offerDetails.tripDetails.tripTitle,
        tripTotalPrice: this.offerDetails.tripDetails.tripTotalPrice,
        updatedAt: "2021-10-13T22:34:38.000000Z",
      };
    },
    buttonText() {
      //check if the form is on the last index
      let getIndex = this.formSections.findIndex((x) => x.show === true);
      if (getIndex + 1 < this.formSections.length) {
        return "Continue";
      } else {
        return "Submit";
      }
    },
    isPricing() {
      let isPricing = this.$route.path.includes("pricing");
      return isPricing;
    },
  },
  watch: {
    showExperienceListSheetState(newVal) {
      if (newVal === true) {
        this.showExperienceListSheet();
      }
    },
    showAccommodationSheet(newVal) {
      if (newVal === true) {
        this.showHotelSheet();
      }
    },
    $route(to) {
      this.isLastStep = to.meta.isLastStep;
    },
    savingResponseStatus(newVal) {
      if (newVal === "success") {
        this.$notify({
          type: "success",
          title: "Success",
          text: `${this.savingResponseMessage}`,
        });
      } else if (newVal === 'failed') {
        this.$notify({
          type: "error",
          title: "Something went wrong",
          text: `${this.savingResponseMessage}`,
        });
      }
      this.closeSheets();
    },
    shouldRedirectAfterOfferSave(newVal) {
      if (newVal === true) {
        this.$router.go(-1);
      }
    },
    tripDetails(newVal){
      if(newVal.tripRequestStatus ==='pending'){
        this.$notify({
          type: "error",
          title: "There was an issue",
          text: "You are not allowed to modify requests in pending status",
        });
        //redirect back
        this.$router.replace('/');
      }
    },
  }
};
</script>
<style scoped>
.sheet {
  @apply h-full fixed w-full z-50 transition-all duration-700 ease-in-out bg-white;
}

.dialog {
  @apply fixed z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
}

.btn-price {
  @apply bg-black-base p-2 w-full text-white text-sm ;
  @apply fixed bottom-17 sm:bottom-20 z-40 hover:bg-black-light;
  @apply flex justify-center items-center;
  @apply duration-300 transition-all ease-out sm:hidden;
  @apply focus:ring-black-darker focus:border-black-base;
  @apply focus:outline-none focus:scale-95 focus:ring-offset-2 focus:ring-2;
}

.btn-price span {
  @apply ml-1;
}
</style>