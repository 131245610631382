<template>
  <div class="day">
    <p class="date">
      {{ displayDate }}
    </p>
    <h4 class="day-title">
      {{ title }}
    </h4>
    <div class="accommodation" v-if="'accommodation' in itinerary">
      <div class="text-container">
        <h5 class="sub-title">Accommodation</h5>
        <p class="font-sans text-black-base">
          {{ itinerary.accommodation.name }}
        </p>
      </div>
      <ButtonIconText
        v-if="
          'accommodation' in itinerary &&
          itinerary.accommodation.image.length > 0
        "
        @click.native="$emit('showPhotos', itinerary.accommodation.image)"
        text="Accommodation"
        btnStyle="btn-style"
      />
    </div>

    <template v-if="itinerary.accommodation">
      <h5 class="mt-4 sub-title">Meal plan</h5>
      <div class="meal-plan">
        <i class="fas fa-utensils icon"></i>
        {{
          itinerary.accommodation.mealplan.replace(/,[s]*/g, ", ") ||
          "No meal plan"
        }}
      </div>
    </template>

    <div class="mt-6 agenda" v-if="itinerary.experiences">
      <div class="text-container">
        <h5 class="sub-title" v-if="itinerary.experiences.length > 0">Plan</h5>
        <div class="block mb-4">
          <div v-for="item in itinerary.experiences" :key="item.id">
            <div class="experience" v-if="item.type == 'experience' && item.name.length">
              <i class="fas fa-map-pin icon"></i> {{ item.name }}
            </div>
            <ul class="pl-5" v-else-if="item.type == 'text'">
              <li class="list-item">
                {{ item.name || item.extras.join(", ") }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <Divider class="w-full mt-8" />
  </div>
</template>

<script>
import ButtonIconText from "@/components/Buttons/ButtonIconText";
import Divider from "@/components/Layout/Divider";
import {generateNewDateFromDuration} from "@/shared/dateHandler";
export default {
  name: "DayBlock",
  components: {
    ButtonIconText,
    Divider,
  },
  props: {
    itinerary: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: "Title",
    },
  },
  computed:{
    displayDate(){
      return generateNewDateFromDuration(this.itinerary.date).format('ddd, D MMM YYYY')
    }
  }
};
</script>

<style scoped>
.date {
  @apply mt-8 font-sans text-sm text-black-lighter;
}

.day-title {
  @apply font-sans font-medium text-black-base;
}

.sub-title {
  @apply font-sans text-sm text-black-lighter;
}

.meal-plan {
  @apply inline-block px-2 py-1 mt-3 text-xs rounded-full bg-teal-lighter text-teal-text font-semibold;
}

.experience {
  @apply inline-block px-2 py-1 mt-3 mb-1 text-xs rounded-full bg-violet-lighter text-violet-text font-semibold;
}

.icon {
  @apply mr-0.5;
}

.accommodation {
  @apply flex items-start justify-between mt-6;
}

.btn-style {
  @apply border border-grey-dark shadow-sm;
}

.list-item {
  @apply font-sans list-disc text-black-base;
}
</style>
