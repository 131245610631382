<template>
  <div :class="['tab-wrap', isBorder]">
    <button
      v-for="(button, i) in buttons"
      :key="i"
      type="button"
      :class="['tab-btn', button.isActive == true ? 'active-btn' : '']"
      @click="toggleActiveBtn(i)"
    >
      {{ button.name }}
    </button>
  </div>
</template>
<script>
export default {
  name: "TabButtons",
  props: {
    isBorder: {
      type: String,
      default: "border-grey-dark",
    },
    buttons: {
      type: Array,
      default: () => {
        return [
          { name: "Details", isActive: true },
          { name: "Requests", isActive: false },
        ];
      },
    },
  },
  methods: {
    toggleActiveBtn(index) {
      for (let [i, button] of this.buttons.entries()) {
        //Set all buttons that don't correspond to the index to false
        if (i != index) {
          button.isActive = false;
        } else {
          //Set button that corresponds to the index to true
          button.isActive = true;
        }
      }
    },
  },
};
</script>
<style scoped>
.tab-wrap {
  @apply bg-white rounded-lg inline-flex justify-between align-middle p-1 space-x-2 shadow-sm border;
}

.tab-btn {
  @apply px-3 inline-flex font-sans rounded transition duration-300 ease-in-out items-center justify-center;
  @apply focus:ring-offset-2 focus:ring-2 focus:outline-none focus:scale-95 focus:ring-black-base h-11;
}

.active-btn {
  @apply bg-black-base border-black-lighter text-white border shadow hover:bg-black-lighter;
}
</style>