<template>
  <div id="experiencesheet" class="sheet">
    <nav class="navbar">
      <ButtonClose @click.native="$emit('closeSheet')" class="btn-close"/>
    </nav>
    <div class="wrapper">
      <h2 class="sheet-title">Add a new experience</h2>
      <form class="w-full">
        <InputText
            label="Experience title"
            placeholder="e.g. Hiking in Kilimanjaro"
            name="experience-name"
            class="mb-6"
            v-model="experience.title"
        />
        <InputText
            label="Experience subtitle"
            placeholder="e.g. Legenndary mountain, highest in Africa"
            name="experience-name"
            class="mb-6"
            v-model="experience.subtitle"
        />

        <SelectDropdown
            description="Select a country"
            :showLabel="true"
            label="Country"
            class="mb-6"
            selectName="country"
            :optionsList="$africanDestinations"
            v-model="experience.country"
        />
        <InputText
            label="Location"
            placeholder="e.g. Kilimanjaro"
            name="location"
            class="mb-6"
            v-model="experience.location"
        />

        <TextArea
            label="Experience description"
            placeholder="Describe the experience"
            name="experience-description"
            v-model="experience.description"
        />

        <InputUpload
            name="experience-photos"
            faIconClass="far fa-camera-retro"
            label="Experience photos"
            class="mt-6"
            title="Upload photos here"
            text="Only .jpg, .jpeg and .png allowed"
            allowedFileTypes="image/x-png,image/jpg,image/jpeg"
            v-model="experience.photos"
        />

        <template v-if="isSubmittingData">
          <div class="s:w-64 block ml-auto py-2">
            <Loading margin="my-0" :showLabel="true" label="Adding Experience"/>
          </div>
        </template>

        <template v-else>
          <ButtonPrimary
              text="Submit"
              class="mt-6 w-full block px-12"
              @click.native="submitExperience"
          />
        </template>

      </form>
    </div>
  </div>
</template>
<script>
import ButtonClose from "@/components/Buttons/ButtonClose";
import ButtonPrimary from "@/components/Buttons/ButtonPrimary";
import InputText from "@/components/Inputs/InputText";
import InputUpload from "@/components/Inputs/InputUpload";
import SelectDropdown from "@/components/Inputs/SelectDropdown";
import TextArea from "@/components/Inputs/TextArea";
import {mapActions, mapGetters} from "vuex";
import Loading from "../Preloaders/Loading";

export default {
  name: "ExperienceSheet",
  components: {
    Loading,
    ButtonClose,
    ButtonPrimary,
    InputText,
    InputUpload,
    SelectDropdown,
    TextArea,
  },
  data() {
    return {
      experience: {
        title: "",
        subtitle: "",
        description: "",
        country: "",
        location: "",
        photos: [],
      },
    };
  },
  methods: {
    ...mapActions({
      addNewExperience: 'experiences/addNewExperience'
    }),
    submitExperience() {
      let formData = new FormData();

      for (let key in this.experience) {
        //some filtering is going on here to reduce the request size
        if (key === "photos") {
          for (let i = 0; i < this.experience.photos.length; i++) {
            formData.append("photos[" + i + "]", this.experience.photos[i]);
          }
        } else {
          formData.append(key, this.experience[key]);
        }
      }

      this.addNewExperience(formData);
    },

  },

  computed: {
    ...mapGetters({
      isSubmittingData: 'experiences/isSubmittingData',
      submissionStatusMessage: 'experiences/submissionStatusMessage',
      submissionStatus: 'experiences/submissionStatus',
      newExperience: 'experiences/newExperience'
    })
  },

  watch: {
    newExperience(newVal) {
      console.log(newVal);
    },
    submissionStatus(newVal) {
      if (newVal === 'success') {


        this.experience = {
          title: "",
          subtitle: "",
          description: "",
          country: "",
          location: "",
          photos: [],
        };
        this.$emit("hideExperienceSheet");
        this.$emit("closeSheet");


        this.$notify({
          type: "success",
          title: "Success",
          text: this.submissionStatusMessage,
        });


      } else {
        this.$notify({
          type: "error",
          title: "OOPS! Something went wrong",
          text: this.submissionStatusMessage,
        });
      }
    }
  },
};
</script>
<style scoped>
.sheet {
  @apply bg-white overflow-y-auto pb-20 px-3;
}

.sheet-title {
  @apply font-semibold text-black-base text-xl sm:text-2xl mt-6 mb-6;
}

.navbar {
  @apply max-w-screen-sm fixed z-30 left-1/2 transform -translate-x-1/2 w-full py-4;
}

.btn-close {
  @apply absolute right-4 sm:right-0;
}

.wrapper {
  @apply max-w-screen-sm relative left-1/2 transform -translate-x-1/2 mt-20;
}
</style>
