import api from "@/api";

export default {
    namespaced: true,
    state: {
        isLoadingData: false,
        tripRequests: [],
        selectedTripRequestRequestedChanges: []
    },
    getters: {
        tripRequests(state) {
            return state.tripRequests
        },
        completedRequests(state) {
            return state.tripRequests.filter((request) => {
                return request.tripRequestStatus === "completed";
            })
        },
        pendingRequests(state) {
            return state.tripRequests.filter((request) => {
                return ['pending','review'].includes(request.tripRequestStatus);
            })
        },
        draftRequests(state) {
            return state.tripRequests.filter((request) => {
                return request.tripRequestStatus === "draft";
            })
        },
        cancelledRequests(state) {
            return state.tripRequests.filter((request) => {
                return request.tripRequestStatus === "cancelled";
            })
        },
        openRequests(state) {
            return state.tripRequests.filter((request) => {
                return request.tripRequestStatus === "open";
            })
        },
        changeRequests(state) {
            return state.tripRequests.filter((request) => {
                return request.tripRequestStatus === "changes";
            })
        },

        isLoadingData(state) {
            return state.isLoadingData
        },

        selectedTripRequestRequestedChanges(state) {
            return state.selectedTripRequestRequestedChanges
        }

    },
    mutations: {

        SET_TRIP_REQUESTS(state, value) {
            state.tripRequests = value;
        },
        SET_IS_LOADING_DATA(state, value) {
            state.isLoadingData = value;
        },

        SET_SELECTED_TRIP_REQUEST_REQUESTED_CHANGES(state, value) {
            state.selectedTripRequestRequestedChanges = value;
        }

    },
    actions: {
        getTripRequests({commit}) {
            commit('SET_IS_LOADING_DATA', true)
            api.get(`/business/get-my-trip-requests`)
                .then(({data}) => {
                    if (data.tripRequests){
                        commit('SET_TRIP_REQUESTS', data.tripRequests)
                    }else{
                        commit('SET_TRIP_REQUESTS', [])
                    }

                    commit('SET_IS_LOADING_DATA', false)
                })
                .catch((error) => {
                    commit('SET_IS_LOADING_DATA', false)
                    throw(error);
                });
        },
        getSelectedTripRequestRequestedChanges({commit}, tripRequestID) {
            api.get(`/get-trip-requested-changes/${tripRequestID}`)
                .then(({data}) => {
                    if (data.requestedChanges) {
                        commit('SET_SELECTED_TRIP_REQUEST_REQUESTED_CHANGES', data.requestedChanges)
                    }
                });
        },
    }
}