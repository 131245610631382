<template>
  <div>
    <div v-if="showEditor" class="block-container">
      <div class="experience-block">
        <i class="bx bxs-sun bx-sm text-violet-base mr-3"></i>
        <div>
          <span class="block-title">
            Day {{ currentDay }} of {{ amountOfDays }}
          </span>
          <p class="block-subtitle">
            {{ currentDate }}
          </p>
        </div>
      </div>
      <Divider class="my-2"/>
      <div class="experience-block-content">
        <ValidationObserver ref="experienceBlockValidator">
          <InputText
              rules="required"
              v-show="showTitle"
              :name="`${name}-title`"
              class="mb-6"
              label="Day title"
              placeholder="e.g. Arrival in Cape Town"
              v-model="title"
              ref="title"
          />
        </ValidationObserver>
        <EmptyStateFrameless
            v-if="showEmptyState"
            title="No experiences yet"
            text="Add some experiences or leave it empty if you don't want to add any."
            imgName="no-activities.svg"
            :showImg="false"
        />

        <div ref="contentBlock" v-show="showExperiences">
          <template v-for="(block, index) in blocks">
            <div :key="index">
              <template v-if="block.type === 'experience'">
                <Experience :block="block" @deleteBlock="deleteBlock"/>
              </template>

              <template v-if="block.type === 'text'">
                <TextBlock :block="block" @deleteBlock="deleteBlock"/>
              </template>
            </div>
          </template>
        </div>
        <div class="btn-frl-container">
          <button
              type="button"
              class="btn-frameless btn-frl-secondary"
              @click="emitText"
          >
            <i class="bx bx-list-plus btn-icon"></i>
            <span>Text</span>
          </button>
          <button
              type="button"
              class="btn-frameless btn-frl-primary"
              @click="emitOpenSheet"
          >
            <i class="bx bx-add-to-queue btn-icon"></i>
            <span>Experience</span>
          </button>
        </div>
      </div>
      <div v-if="showEditor">
        <Divider class="w-full mt-12"/>
        <div class="btn-container">
          <ButtonSecondary
              text="Cancel"
              class="btn-cancel"
              @click.native="emitCancel"
          />
          <ButtonPrimary
              mode="dark"
              class="btn-save"
              text="Save"
              @click.native="emitSave"
          />
        </div>
      </div>
    </div>
    <BlockPlaceholder
        :currentDay="currentDay"
        :currentDate="currentDate"
        :amountOfDays="amountOfDays"
        @blockAction="clickedBlockPlaceholderEditButton"
        :disAttribute="disAttribute"
        v-show="showPlaceholder"
        :showActivities="showPlaceHolderExperiences"
        :experiences="placeholderExperiences"
        :index="index"
    />

    <SummaryTile
        v-if="showSummary"
        :experiences="experiences"
        :disAttribute="disAttribute"
        :title="title"
        :day="currentDay"
        @edit="emitEdit"
        @delete="emitDelete"
    />
  </div>
</template>
<script>
import BlockPlaceholder from "@/components/Offer/Itinerary/BlockPlaceholder";
import ButtonSecondary from "@/components/Buttons/ButtonSecondary";
import ButtonPrimary from "@/components/Buttons/ButtonPrimary";
import Divider from "@/components/Layout/Divider";
import EmptyStateFrameless from "@/components/EmptyStates/EmptyStateFrameless";
import Experience from "@/components/Offer/Itinerary/Experience";
import InputText from "@/components/Inputs/InputText";
import SummaryTile from "@/components/Offer/Itinerary/SummaryTile";
import TextBlock from "@/components/Offer/Itinerary/TextBlock";
import {ValidationObserver} from "vee-validate";
import {mapActions} from "vuex";

export default {
  name: "ExperienceBlock",
  components: {
    BlockPlaceholder,
    ButtonSecondary,
    ButtonPrimary,
    ValidationObserver,
    Divider,
    EmptyStateFrameless,
    Experience,
    InputText,
    SummaryTile,
    TextBlock,
  },
  data() {
    return {
      title: this.savedTitle,
      fieldsData: [],
      blocks: [],
    };
  },
  props: {
    currentDay: {
      type: Number,
    },
    savedTitle: {
      type: String,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    showExperience: {
      type: Boolean,
      default: true,
    },
    amountOfDays: {
      type: Number,
    },
    currentDate: {
      type: String,
    },
    currentFullDate: {
      type: String,
    },
    disAttribute: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    showExperiences: {
      type: Boolean,
      default: false,
    },
    showEditor: {
      type: Boolean,
      default: false,
    },
    showPlaceholder: {
      type: Boolean,
      default: true,
    },
    showPlaceHolderExperiences: {
      type: Boolean,
      default: false,
    },
    placeholderExperiences: {
      type: String,
    },
    text: {
      type: String,
      default: "Add an accommodation",
    },
    showSummary: {
      type: Boolean,
      default: false,
    },
    index: {
      type: [Number, String],
    },
    experiences: {
      type: Array,
      default: () => [
        {
          type: "experience",
          id: 0,
          title: "title",
          location: "location",
          photo: require("@/assets/img/placeholder.png"),
          extras: [],
        },
      ],
    },
    previousExperiencesState: {
      type: Array,
      default: () => [
        {
          type: "experience",
          id: 0,
          title: "title",
          location: "location",
          photo: require("@/assets/img/placeholder.png"),
          extras: [],
        },
      ],
    },
  },
  methods: {
    ...mapActions({
      setSelectedItineraryDayID: "experiences/setSelectedItineraryDayId",
    }),
    clickedBlockPlaceholderEditButton() {
      this.setSelectedItineraryDayID(this.index);
      this.$emit('blockAction');
    },
    getExtras(fields, experienceId) {
      this.$emit("getExtras", fields, experienceId);
      let fieldsStringArray = [];
      //take the values out of the array
      for (let i = 0; i < fields.length; i++) {
        fieldsStringArray.push(fields[i].value);
      }

      let getIndex = this.fieldsData.findIndex((x) => x.id === experienceId);
      if (getIndex > -1) {
        this.fieldsData.splice(getIndex, 1);
      }

      this.fieldsData.push({
        experienceId: experienceId,
        extras: fieldsStringArray,
      });
    },
    emitCancel() {
      this.$emit("cancelBlock");
    },
    async emitSave() {
      const isValid = await this.$refs.experienceBlockValidator.validate();
      if (isValid) {
        const experiencesToSaveOnEmit = [];
        for (let i = 0; i < this.experiences.length; i++) {
          this.previousExperiencesState.push(this.experiences[0]);
          let getIndex = this.fieldsData.findIndex(
              (x) => x.experienceId == this.experiences[i].id
          );
          let extra = [];
          if (getIndex > -1) {
            extra = this.fieldsData[getIndex].extras;
          }

          //TODO: change name property to title in below code. Make sure you trace all items it affects
          experiencesToSaveOnEmit.push({
            type: this.experiences[i].type,
            id: this.experiences[i].id,
            name: this.experiences[i].title,
            location: this.experiences[i].location,
            photo: this.experiences[i].photo,
            extras: extra,
          });
        }
        this.$emit(
            "saveBlock",
            this.title,
            experiencesToSaveOnEmit,
            this.currentFullDate,
            this.index
        );
      }

    },
    emitEdit() {
      this.setSelectedItineraryDayID(this.index);
      this.$emit("editSummary");
    },
    emitDelete() {
      this.setSelectedItineraryDayID(this.index);
      this.$emit("removeSummary");
    },
    emitText() {
      this.$emit("createText");
    },
    deleteBlock(experienceID) {
      let getIndex = this.blocks.findIndex(blockItem => blockItem.id === experienceID);
      this.blocks.splice(getIndex, 1);
      this.experiences.splice(getIndex, 1);
      this.$emit('removeExperience', experienceID);
    },
    emitOpenSheet() {
      this.$emit("openSheet");
    },
  },

  mounted() {
    if (this.experiences.length > 0) {
      this.experiences.forEach((experience) => {

        // this is a patch to help convert all old text experiences to match the current data format
        // the code can be removed after a while
        if (experience.title === "" && experience.extras && experience.type === "text") {
          experience.title = experience.extras.join(", ");
        }

        let indexExisting = this.blocks.indexOf(experience);
        if (indexExisting < 0) {
          this.blocks.push(experience);
        }
      });
    }
  },
  computed: {
    showEmptyState() {
      // this returns true or false
      return this.blocks.length <= 0;
    },
  },
  watch: {
    experiences(newVal) {
      this.blocks = [];
      newVal.forEach((value) => {
        let indexExisting = this.blocks.indexOf(value);
        if (indexExisting < 0) {
          this.blocks.push(value);
        }
      });
    },
  },
};
</script>
<style scoped>
.empty-state {
  @apply w-full p-6 rounded-md;
}

.empty-state-title {
  @apply font-sans text-black-base text-xl font-semibold text-center mt-6;
}

.empty-state-text {
  @apply font-sans text-black-lighter text-center;
}

.btn-container {
  @apply flex items-center justify-between w-full p-4 sm:p-6;
}

.btn-cancel {
  @apply sm:ml-auto px-8 mr-0 sm:mr-3 w-calc-1/2 sm:w-auto;
}

.btn-save {
  @apply block px-8 w-calc-1/2 sm:w-auto;
}

.experience-block {
  @apply font-sans rounded-md my-3 inline-flex items-center px-4 sm:px-6 pt-3;
}

.experience-block-content {
  @apply px-4 sm:px-6 pt-2.5;
}

.block-title {
  @apply font-sans text-black-base font-bold text-xs sm:text-sm uppercase;
}

.block-subtitle {
  @apply w-full text-black-lightest text-xs uppercase;
}

.block-container {
  @apply bg-white shadow-sm rounded-md border border-grey-base;
}

.btn-frl-container {
  @apply flex items-center justify-between w-full mt-6;
}

.btn-frameless {
  @apply rounded w-calc-1/2 p-3;
  @apply font-medium text-sm sm:text-base;
  @apply flex items-center justify-center;
  @apply transition-all duration-300 ease-in-out;
}

.btn-frl-primary {
  @apply bg-teal-lighter text-teal-dark hover:bg-teal-light;
}

.btn-frl-secondary {
  @apply bg-grey-light text-black-light hover:bg-grey-base;
}

.btn-icon {
  @apply text-xl sm:text-2xl;
}

.btn-frameless span {
  @apply ml-2;
}
</style>