<template>
  <div id="pricing">
    <h2 class="section-title">Price details</h2>
    <div class="mt-8 pricing">
      <div class="pricing-cards">
        <div class="price-travelers">
          <span class="price-per-traveler">{{
              formatToCurrency(offer.tripPricePerAdult)
            }}</span>
          <span class="price-label">/adult</span>
        </div>
        <div class="price-travelers" v-if="offer.tripChildren > 0">
          <span class="price-per-traveler">{{
              formatToCurrency(offer.tripPricePerChild)
            }}</span>
          <span class="price-label">/child</span>
        </div>
      </div>

      <Divider class="w-full my-4"/>

      <div class="pricing-table-row">
        <div class="calculation">
          {{ formatToCurrency(offer.tripPricePerAdult) }} ×
          {{ offer.tripAdults }}
          {{ offer.tripAdults != 1 ? "adults" : "adult" }}
        </div>
        <div class="result">{{ totalAdultsPrice }}</div>
      </div>

      <Divider class="w-full my-4"/>

      <div class="pricing-table-row" v-if="offer.tripChildren > 0">
        <div class="calculation">
          {{ formatToCurrency(offer.tripPricePerChild) }} ×
          {{ offer.tripChildren }}
          {{ offer.tripChildren != 1 ? "children" : "child" }}
        </div>
        <div class="result">{{ totalChildrenPrice }}</div>
      </div>

      <Divider class="w-full my-4" v-if="offer.tripChildren > 0"/>

      <div v-if="totalSingleRoom > 0" class="pricing-table-row">
        <div class="fee">
          {{ formatToCurrency(offer.tripSingleRoomPrice) }} ×
          {{ offer.tripSingleRooms }}
          {{ offer.tripSingleRooms != 1 ? "single rooms" : "single room" }}
        </div>
        <div class="result">
          {{ totalSingleRoomPrice }}
        </div>
      </div>

      <Divider class="w-full mt-4" v-if="totalSingleRoom > 0"/>

      <div class="py-3 font-bold pricing-table-row text-black-base">
        <div class="total">Total</div>
        <div class="result">{{ totalPrice }}</div>
      </div>

      <div class="flex justify-between mt-6">
        <div class="inclusions w-calc-1/2">
          <h3 class="font-sans text-lg font-semibold text-black-base">
            Inclusions
          </h3>
          <div class="mt-2 list">
            <div
                v-for="(inclusion, index) in tripInclusions"
                :key="index"
                class="flex items-start mb-3"
            >
              <i class="text-sm far fa-check mt-1.5 text-teal-base"></i>
              <div class="ml-2 font-sans text-black-base">
                {{ inclusion }}
              </div>
            </div>
          </div>
        </div>
        <div class="exclusions w-calc-1/2">
          <h3 class="font-sans text-lg font-semibold text-black-base">
            Exclusions
          </h3>
          <div class="mt-2 list">
            <div
                v-for="(exclusion, index) in tripExclusions"
                :key="index"
                class="flex items-start mb-3"
            >
              <i class="text-sm fal fa-times text-red-base mt-1.5"></i>
              <div class="ml-2 font-sans text-black-base">
                {{ exclusion }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Divider class="w-full mt-8"/>
  </div>
</template>
<script>
import Divider from "@/components/Layout/Divider";

export default {
  name: "PricingBlock",
  components: {
    Divider,
  },
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      region: "en-US",

    };
  },
  methods: {
    // formatToCurrency(value, currency, region) {
    formatToCurrency(value) {
      value = new Intl.NumberFormat(this.region, {
        style: "currency",
        currency: this.offer.tripCurrency || "USD",
      }).format(value);
      return value;
    },
  },
  computed: {
    tripInclusions() {
      if (typeof (this.offer.tripInclusions) === 'string') {
        return this.offer.tripInclusions.split(',');
      } else {
        return this.offer.tripInclusions
      }
    },
    tripExclusions() {
      if (typeof (this.offer.tripExclusions) === 'string') {
        return this.offer.tripExclusions.split(',');
      } else {
        return this.offer.tripExclusions
      }
    },
    totalAdult() {
      return (
          this.offer.tripPricePerAdult *
          this.offer.tripAdults
      );
    },
    totalChildren() {
      return (
          this.offer.tripPricePerChild *
          this.offer.tripChildren
      );
    },
    totalSingleRoom() {
      return this.offer.tripSingleRoomPrice * this.offer.tripSingleRooms;
    },

    totalChildrenPrice() {
      return this.formatToCurrency(this.totalChildren);
    },
    totalAdultsPrice() {
      return this.formatToCurrency(this.totalAdult);
    },
    totalSingleRoomPrice() {
      return this.formatToCurrency(this.totalSingleRoom);
    },

    totalPrice() {
      let total = this.totalAdult + this.totalChildren + this.totalSingleRoom;
      return this.formatToCurrency(total);
    },
  },
};
</script>

<style scoped>
.section-title {
  @apply mt-12 font-sans text-2xl font-semibold;
}

.price-travelers {
  @apply inline-block p-4 mr-3 font-sans rounded-md bg-white shadow-sm border border-grey-base;
}

.price-per-traveler {
  @apply font-semibold text-black-base text-xl;
}

.price-label {
  @apply text-black-lightest text-base;
}

.pricing-table-row {
  @apply flex justify-between w-full text-black-base;
}
</style>
