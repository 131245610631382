<template>
  <div class="experience-list-sheet">
    <nav class="navbar">
      <ButtonClose @click.native="$emit('closeSheet')" class="btn-close" />
    </nav>
    <div class="wrapper">
      <div class="experience-list-sheet">
        <h2 class="sheet-title">Add experiences</h2>
        <InputText
          placeholder="e.g. Hiking in Kilimandjaro"
          :showLabel="false"
          class="search-field"
          v-model="searchWord"
        />
      </div>

      <div v-if="showEmptyState" class="overflow-auto h-full mt-3 pb-32">
        <div v-if="searchResult">
          <template v-for="(experience, i) in searchResult">
            <CardHorizontal
              :key="i"
              class="mb-6"
              :experienceId="experience.id"
              :title="experience.title"
              :imgPath="experience.photo"
              :location="experience.location"
              :showAddConfirmation="
                selectedExperienceIDsForSelectedDay.includes(experience.id)
              "
              @cardObj="showObj"
              @removeCardObj="removeObj"
            />
          </template>
        </div>

        <EmptyStateFramed
            v-if="searchResult.length < 1"
            iconClass="bx bx-trip"
            title="Experience not found"
            text="The experience you are looking for doesn't exist. Create one by clicking the button below."
            :usePrimaryAction="true"
            @primaryAction="emitShowExperienceSheet"
            primaryAction="Create an experience"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ButtonClose from "@/components/Buttons/ButtonClose";
import CardHorizontal from "@/components/Cards/CardHorizontal";
import EmptyStateFramed from "@/components/EmptyStates/EmptyStateFramed";
import InputText from "@/components/Inputs/InputText";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ButtonClose,
    CardHorizontal,
    EmptyStateFramed,
    InputText,
  },
  data() {
    return {
      searchWord: "",
      searchResult: [],
      textId: 9999999999,
    };
  },
  props: {
    showEmptyState: {
      type: Boolean,
      default: true,
    },
    experiences: {
      type: Array,
    },
  },
  methods: {
    ...mapActions({
      addExperienceToSelectedDayID: "experiences/addExperienceToSelectedDayID",
      removeExperienceFromSelectedDayIDUsingExperienceID:
        "experiences/removeExperienceFromSelectedDayIDUsingExperienceID",
    }),
    showObj(obj) {
      this.$emit("cardObj", obj);
      this.addExperienceToSelectedDayID(obj);
    },
    removeObj(obj) {
      this.$emit("removeObj", obj);
      this.removeExperienceFromSelectedDayIDUsingExperienceID(obj.id);
    },
    emitText() {
      this.textId = this.textId + 1;
      let id = this.textId;
      this.$emit("createText", {
        type: "text",
        id: id,
        title: "",
        location: "",
        photo: "",
      });
    },
    search() {
    },
    emitShowExperienceSheet(){
      this.$emit('createExperience')
    }
  },
  mounted() {
    this.searchResult = this.experiences;
  },
  computed: {
    ...mapGetters({
      selectedExperienceIDsForSelectedDay:
        "experiences/selectedExperienceIDsForSelectedDay",
    }),
  },
  watch: {
    searchWord(newValue) {
      this.searchResult = [];

      if (newValue == "") {
        this.searchResult = this.experiences;
      }

      this.searchResult = this.experiences.filter((element) => {
        return element.title.toLowerCase().includes(newValue.toLowerCase());
      });
    },
    experiences() {
      this.searchResult = this.experiences;
    },
  },
};
</script>
<style scoped>
.sheet-title {
  @apply font-semibold text-black-base text-lg sm:text-2xl mt-6 mb-6;
}
.experience-list-sheet {
  @apply bg-white overflow-y-auto;
}

.navbar {
  @apply max-w-screen-sm fixed z-30 left-1/2 transform -translate-x-1/2 w-full py-4;
}

.btn-close {
  @apply absolute right-4 sm:right-0;
}

.wrapper {
  @apply max-w-screen-sm relative left-1/2 transform -translate-x-1/2 mt-20 px-3;
}
</style>
