<template>
  <div class="result-item">
    <div v-if="showItemIcon" class="result-item-icon">
      <i :class="['bx text-lg', iconClass]"></i>
    </div>
    <div class="result-text">
      <div class="result-item-title">
        {{ itemName }}
      </div>
      <div v-if="showItemDetails" class="result-item-details">
        {{ itemDetails }}
      </div>
    </div>
    <div
      v-if="showHotelStars"
      class="additional-details ml-auto font-sans font-medium text-black-base"
    >
      {{ numberOfStars }} <i class="fas fa-star text-yellow-base"></i>
    </div>
  </div>
</template>
<script>
export default {
  name: "ResultItem",
  props: {
    itemName: {
      type: String,
      default: "One&Only Cape Town",
    },
    itemDetails: {
      type: String,
      default: "Cape Town, South Africa",
    },
    showItemDetails: {
      type: Boolean,
      default: true,
    },
    showItemIcon: {
      type: Boolean,
      default: true,
    },
    iconClass: {
      type: String,
      default: "bxs-building-house",
    },
    showHotelStars: {
      type: Boolean,
      default: true,
    },
    numberOfStars: {
      type: String,
      default: "5",
    },
  },
};
</script>
<style scoped>
.result-item {
  @apply cursor-pointer w-full hover:bg-grey-lighter py-2 px-3 flex items-center border-b border-grey-base;
}
.result-item-title {
  @apply text-sm sm:text-base;
}
.result-item-icon {
  @apply hidden xs:flex text-teal-base bg-teal-lighter rounded-full h-8 min-w-8 items-center justify-center text-sm mr-3;
}

.result-text {
  @apply font-sans text-black-base w-full;
}

.result-item-details {
  @apply font-sans text-black-lightest text-xs w-full truncate;
}
</style>